@font-face {
  font-family: 'sf-ui-display';
  src: url('../fonts/Inter-VariableFont_slnt\,wght.ttf');
  font-style: normal;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #FFFFFF;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.homePageS1Bg {
  max-width: 83%;
  /* padding-left: 2rem; */
  background: linear-gradient(to bottom, transparent, rgba(255, 255, 255, 0.962));
  filter: blur(5px);
}

.homepageS1MainHeadingDiv h3 {
  font-family: 'sf-ui-display';
  font-size: 40px;
  font-weight: 300;
  line-height: 48px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(0, 0, 0, 1);
}
.homepageS1MainHeadingDiv h4 {
  font-family: 'sf-ui-display';
  font-size: 40px;
  font-weight: 300;
  line-height: 48px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(0, 0, 0, 1);
}

.homePageS1SubHeading {
  font-family: 'sf-ui-display';
  font-size: 24px;
  font-weight: 300;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(38, 69, 224, 1);
  padding: 2rem 16rem 2rem 0;
}
.s2HeadingBold{
  font-family: 'sf-ui-display';
font-size: 40px;
font-weight: 500;
line-height: 48px;
letter-spacing: 0em;
text-align: left;
}
.homePageS2SubHeading{
  font-family: 'sf-ui-display';
  font-size: 20px;
  font-weight: 300;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(0, 5, 26, 1);
  padding: 1rem 6rem 0 0;
}
.rightSideHrS2{
  position: relative;
  top: 0rem;
}
.homePageS3SubHeading{
  font-family: 'sf-ui-display';
  font-size: 20px;
  font-weight: 300;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(0, 5, 26, 1);
  padding: 2rem 4rem 0 0;
}
.S3MainImg{
  padding-top: 5rem;
  padding-left: 0.3rem;
}


.s4Banner {
  padding: 4rem 0 0 0 !important;
}


.homepageS3MainHeadingDiv h3{
  font-family: 'sf-ui-display';
  font-size: 40px;
  font-weight: 300;
  line-height: 48px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(255, 255, 255, 1);
  
}

.homePageS4SubHeading{
  font-family: 'sf-ui-display';
  font-size: 20px;
  font-weight: 300;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;  
  color: rgba(255, 255, 255, 1);
  padding: 1rem 9rem 2rem 0rem ;

}

.s4BottomHeadingAbove{
  font-family: 'sf-ui-display';
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(255, 226, 0, 1);
}


.s4BottomHeading{
  font-family: 'sf-ui-display';
  font-size: 24px;
  font-weight: 500;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(255, 255, 255, 1);
}

.s4BottomSubHeading{
  font-family: 'sf-ui-display';
  font-size: 16px;
  font-weight: 300;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(255, 255, 255, 1);
}

.S4BtnDiv{
  padding-top: 2rem;
}

.s4Btn{
  width: 170px;
  height: 52px;
  top: 1151px;
  left: 163px;
  border-radius: 13.5px;
  border: none;
}


.S5BtnDiv{
  padding-top: 2.5rem;
}

.s5Btn{
  width: 294px;
  height: 67px;
  border-radius: 18px;
  border: 1px;
  border: none;
  background: rgba(0, 0, 0, 1)
}
.formBTn{
  font-size: 18px;
  color: #FFE200; /* Hide the original text */
  font-family: 'sf-ui-display';
  font-size: 22px;
  font-weight: 500;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: center;
}
.s5Btntxt {
  font-size: 24px;
  background-image:  linear-gradient(0deg, #FFE200, #FFE200),linear-gradient(0deg, #000000, #000000);
  -webkit-background-clip: text; /* Apply gradient to text */
  color: transparent; /* Hide the original text */
  font-family: 'sf-ui-display';
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: center;
}

.s5PopUpDiv {
  padding-top: 10rem;
  padding-bottom: 24rem;
}



.S5PopUpBtnDiv{
  padding-top: 3.5rem;
}

.s5PopUpBtn{
  width: 346px;
  height: 162px;
  border-radius: 24px;
  border: 2px solid rgba(0, 0, 0, 1)
}

.s5PopUpBtntxt{
  font-family: 'sf-ui-display';
  font-size: 24px;
  font-weight: 500;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(7, 30, 237, 1);

}

.S3BottomHeading{
  font-family:'sf-ui-display';
  font-size: 24px;
  font-weight: 600;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(255, 255, 255, 1);
}
.S7BottomColumns{
  padding-top: 15rem;
}

.columnBorder{
  border-right: 2px solid rgba(255, 255, 255, 1) ;
}

.columnSettingsS7{
  display: flex;
  align-items:flex-start;
  flex-direction: column;
  padding: 0 2.7rem;
}

.s10Text {
  font-family:'sf-ui-display';
  font-size: 24px;
  font-weight: 500;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(38, 69, 224, 1);
  padding: 0 2.5rem;
}

.s10ImgsSize1{
  width: 65px;
  height: 65px;
}

.s10ImgsSize2{
  width: 86px;
  height: 86px;
}

.s10ImgsSize3{
  width:65px;
  height: 65px;
}

.s10ImgsSize4{
  width: 72px;
  height: 72px;
}