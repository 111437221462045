/*  80% and Above in Laptop Screen */
@media only screen and (max-width: 1720px) {
  .homepageS1MainHeadingDiv h4 {
    font-size: 37px;
    line-height: 40px;
  }
  .s2HeadingBold05 {
    font-size: 37px;
    line-height: 40px;
  }
  .s5PopUpDiv1,
  .AUp6s5PopUpDiv {
    padding: 12rem 0;
  }

  .loginTitle {
    padding: 2rem 0 .5rem 0;
  }
  .wrapperInsideSecondDiv h1 {
    font-size: 48px !important;
    line-height: 58px !important;
  }

  .AUp6s5PopUpDiv {
    padding-top: 15rem;
    padding-bottom: 26rem;
  }
  .BAS5PopUpBtnDiv {
    padding-top: 4rem;
    padding-bottom: 7rem;
  }
  .S5PopUpBtnDivP3 {
    padding: 11rem 0 19rem 0;
  }
  .HPs5PopUpDiv {
    padding-top: 8rem;
    padding-bottom: 16rem;
  }
  .BottomText {
    padding: 1rem 0;
  }
  .S1Heading {
    font-size: 40px;
    line-height: 48px;
  }
  .S1SubHeading {
    font-size: 40px !important;
    line-height: 48px;
  }
  .S1Para {
    font-size: 20px;
    line-height: 26px;
    padding: 2.5rem 3rem 2.5rem 0;
  }
  .S4Para {
    font-size: 20px;
    line-height: 26px;
  }

  .p7s2Heading {
    font-size: 40px;
    line-height: 48px;
  }
  .p7s2Para {
    font-size: 20px;
    line-height: 26px;
  }
  .s5PopUpBtnP10 {
    padding: 3.5rem;
  }
  .p7S1Img {
    padding: 3.5rem 0 1.5rem 0;
  }

  .s7P2RightsideColumnImgP3 {
    padding: 0 2rem;
    margin-bottom: 1rem;
  }
  .p5S7Heading {
    font-size: 24px;
    line-height: 30px;
  }
  .S3BottomCenterHeading {
    font-size: 30px;
    line-height: 35px;
  }

  .s5PopUpBtn {
    width: 100% !important;
    height: 100% !important;
    padding: 3rem 1rem;
  }
  .s5PopUpBtnP3 {
    width: 100%;
    height: fit-content;
    padding: 8rem 0;
  }
  .s5PopUpDiv {
    padding-top: 4rem;
    padding-bottom: 18rem;
  }
  .navbar_titles {
    padding: 0rem 1.5rem;
  }
  .homePageS4SubHeading {
    font-size: 32px;
    line-height: 42px;
  }
  .homePageS4SubHeading {
    font-size: 20px;
    line-height: 26px;
  }
  .homepageS1MainHeadingDiv h3 {
    font-size: 40px;
    line-height: 48px;
  }
  .homePageS1SubHeading {
    font-size: 30px;
    line-height: 35px;
    padding: 1rem 0;
  }
  .s2HeadingBold {
    font-size: 40px;
    line-height: 48px;
  }
  .homePageS2SubHeading {
    font-size: 20px;
    line-height: 26px;
    padding: 1rem 6rem 0 0;
  }
  .homePageS3SubHeading {
    font-size: 20px;
    line-height: 26px;
  }
  .HMS3Bg {
    padding-top: 30rem;
    padding-bottom: 4rem;
  }
  .s7P2RightsideColumnp3 {
    padding: 0 2.5rem;
  }
}

@media only screen and (max-width: 1480px) {
  .s5PopUpDiv1,
  .AUp6s5PopUpDiv {
    padding: 12rem 0;
  }
  .AUp6s5PopUpDiv {
    padding-top: 15rem;
    padding-bottom: 20rem;
  }
  .cardLeftSide label {
    font-size: 16px;
    line-height: 20px;
  }
  .walletInsideMainContainer {
    padding: 3rem 1rem;
  }
  .WCDUpperRow {
    padding: 0rem 1rem 1rem 1rem !important;
  }
  .wrapperInsideSecondDiv h1 {
    font-size: 48px !important;
    line-height: 58px !important;
  }

  .notifyIconDiv {
    padding: 2rem 5rem 0 0;
    position: absolute;
    top: 0;
    right: 0;
  }
  .AUS1Img {
    width: inherit;
  }
  .S1Heading {
    font-size: 40px;
    line-height: 48px;
  }
  .S1SubHeading {
    font-size: 40px !important;
    line-height: 48px;
  }
  .S1Para {
    font-size: 20px;
    line-height: 26px;
  }

  .p5S7Heading {
    font-size: 24px;
    line-height: 30px;
  }
  .S3BottomCenterHeading {
    font-size: 28px;
    line-height: 34px;
  }
  .p6s5PopUpDiv {
    padding-top: 1rem;
    padding-bottom: 23rem;
  }
  .p7s3Heading {
    font-size: 18px;
    line-height: 29px;
  }
  .s5PopUpDiv {
    padding-top: 4rem;
    padding-bottom: 8rem;
  }

  .p7s2Heading {
    font-size: 40px;
    line-height: 48px;
  }
  .p7s2Para {
    font-size: 20px;
    line-height: 26px;
  }

  .s10Text {
    padding: 0 0.5rem;
  }

  .S4Para {
    font-size: 20px;
    line-height: 26px;
    padding: 1rem 5rem 2.5rem 0;
  }
  .S4LeftSideImg {
    width: 100%;
    height: fit-content;
  }
}
/* 100% and Above in Laptop Screen */
@media only screen and (max-width: 1370px) {
  .acc_type_h{
    font-family: 'Inter';
    font-size: 20px;
    font-weight: 500;
    line-height: 29.05px;
    text-align: left;
    color: rgba(38, 69, 224, 1);
  }
  .button {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 7px 10px;
    font-size: 12px;
    text-align: center;
    cursor: pointer;
    text-decoration: none;
    background-color: white;
    border-radius: 7.5px;
    z-index: 1;
    color: transparent;
    width: 100%;
    transition: all 0.3s ease-in-out;
    height: 40px;
  }
  
  .button::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
      to right, 
      rgba(255, 226, 0, 1), 
      rgba(38, 69, 224, 1), 
      rgba(7, 30, 237, 1)
    );
    border-radius: inherit;
    z-index: -1;
  }
  
  .button::after {
    content: "";
    position: absolute;
    top: 1.5px;
    left: 1.5px;
    right: 1.5px;
    bottom: 1.5px;
    background: white;
    border-radius: inherit;
    z-index: -1;
  }
  
  .button span {
    display: inline-block;
    background: var(--logo-gradient, linear-gradient(117deg, #FFE200 2.71%, #2645E0 39.73%, #071EED 76.47%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-fill-color: transparent;
    position: relative;
    z-index: 1;
    line-height: 1; /* Ensure line height does not affect vertical centering */
    text-align: center;
    font-family: 'Inter';
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  .hide {
    display: none;
    margin: auto;
  }
  
  .loading {
    width: 50px;
    height: 50px;
    padding: 0;
    border-radius: 50%;
    margin: auto;
    margin-top: 30px;
  }
  .acc_type_h2{
    font-family: 'Inter';
    font-size: 20px;
    font-weight: 300;
    line-height: 29.05px;
    text-align: left;
    color: rgba(0, 5, 26, 1);
  }
  .new_acc_type_card{
    background: rgba(255, 255, 255, 1);
    box-shadow: 0px 0px 10.7px 0px rgba(38, 69, 224, 0.1);
    padding: 1rem;
    border-radius: 18px;
  }

  .new_acc_type_card h1{
    font-family: Inter;
    font-size: 18px;
    font-weight: 500;
    line-height: 24.2px;
    text-align: left;
    color: rgba(38, 69, 224, 1);
  }

  .new_acc_type_card p{
    font-family: Inter;
    font-size: 14px;
    font-weight: 300;
    line-height: 21.12px;
    text-align: left;
    color: rgba(0, 5, 26, 1);
    height: 80px;
  }
  .playLogos {
    max-width: 155.25px !important;
    height: 46.81px !important;
  }
  .s5PopUpDiv1,
  .AUp6s5PopUpDiv {
    padding: 12rem 0;
  }
  .AUp6s5PopUpDiv {
    padding-top: 12rem;
    padding-bottom: 26rem;
  }
  .navbarLogo {
    width: 150px;
    height: 67px;
    max-width: 100%;
    padding-left: 4rem;
  }

  .CWS5PopUpBtnDiv {
    padding-top: 4rem;
    padding-bottom: 6rem;
  }
  .S5PopUpBtnDivP3 {
    padding: 9rem 0 16rem 0;
  }
  .BAS5PopUpBtnDiv {
    padding-top: 3rem;
    padding-bottom: 6rem;
  }
  .S1Heading {
    font-size: 40px;
    line-height: 48px;
  }
  .HPs5PopUpDiv {
    padding-top: 8rem;
    padding-bottom: 13rem;
  }
  .S1SubHeading {
    font-size: 40px !important ;
    line-height: 48px;
  }
  .S1Para {
    font-size: 20px;
    line-height: 25px;
  }
  .homepageContainerP2 {
    padding-left: 5rem !important;
    padding-right: 2.75rem !important;
    padding-bottom: 5rem;
  }

  .p7s2Heading {
    font-size: 35px;
    line-height: 40px;
  }
  .p7s2Para {
    font-size: 20px;
    line-height: 26px;
  }

  .s5PopUpBtnP10 {
    padding: 4.5rem;
  }

  .registertypeCards {
    padding: 2rem 0.3rem 2rem 1rem;
  }
  .p5S7Heading {
    font-size: 24px;
    line-height: 30px;
  }
  .S3BottomCenterHeading {
    font-size: 25px;
    line-height: 30px;
  }

  .socialIcons img {
    padding-right: 0rem !important;
  }
  .S5Para {
    font-size: 20px;
    line-height: 26px;
    padding: 4.5rem 1rem 2.5rem 0;
  }

  .s5PopUpBtnP3 {
    padding: 4rem 0;
  }
  .S5PopUpBtnDivP3 {
    padding: 7rem 0 14rem 0;
  }
  .S1PATopPaddingP3 {
    padding-top: 12rem;
  }

  .homepageContainerP3 {
    padding-left: 5rem !important;
    padding-right: 2.75rem !important;
    padding-bottom: 6rem;
  }

  .s7P2RightsideColumn {
    padding: 0rem 2rem 0rem 7rem;
    margin-bottom: 7rem;
  }
  .s7P2LeftsideColumn {
    padding: 0rem 7rem 0rem 2rem;
    margin-bottom: 6rem;
  }
  .s7P2RightsideColumnImg {
    padding: 0rem 0rem 0rem 7rem;
    margin-bottom: 1rem;
  }
  .s7P2LeftsideColumnImg {
    padding: 0rem 17rem 0rem 2rem;
    margin-bottom: 1rem;
  }

  .PSS6RImg {
    position: relative;
    top: -1rem;
  }
  .PSS6RImg img {
    padding-left: 1rem;
  }
  .S4Para {
    font-size: 20px;
    line-height: 26px;
  }

  .rightSideS2ImgP2 {
    width: 100%;
    height: fit-content;
  }
  .S1Para {
    padding: 1.5rem 1rem 1.5rem 0;
  }

  .pr_rem10 {
    padding-right: 5rem !important;
  }

  .FotterBg {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }
  .HPS9RSPara {
    padding-right: 1rem;
  }
  .S3BottomHeading {
    font-size: 24px;
    line-height: 30px;
  }
  .s5PopUpDiv {
    padding-top: 19rem;
    padding-bottom: 3rem;
  }
  .s5PopUpBtn {
    width: 100% !important;
    height: 100% !important;
    padding: 3rem 1rem;
  }
  .homepageS3MainHeadingDiv h3 {
    font-size: 40px;
    line-height: 48px;
  }
  .homepageS3MainHeadingDiv {
    padding: 0px 32px !important;
  }
  .homePageS4SubHeading {
    font-size: 20px;
    line-height: 26px;
  }
  .s4BottomSubHeading {
    font-size: 16px;
    line-height: 21px;
  }
  .homepageContainer {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
    padding-bottom: 6rem;
  }
  .homepageS1MainHeadingDiv h3 {
    font-size: 40px;
    line-height: 48px;
  }
  .homePageS1SubHeading {
    font-size: 25px;
    line-height: 30px;
  }
  .s2HeadingBold {
    font-size: 40px;
    line-height: 48px;
  }
  .homePageS2SubHeading {
    font-size: 20px;
    line-height: 26px;
    padding: 1rem 3rem 0 0;
  }
  .homePageS3SubHeading {
    font-size: 20px;
    line-height: 26px;
  }
  .navbar_titles {
    padding: 0rem 1.5rem;
  }
  .navbarLogo {
    width: 150px;
    height: 67px;
    max-width: 100%;
    padding-left: 2rem;
  }

  .navbar_titles_rightSide {
    font-size: 16px !important;
    line-height: 20px !important;
  }
  .secondStephr {
    padding: 1.5rem 0;
  }
  .manageImmg01 {
    padding-right: 5rem !important;
  }
}

/* 110% and Above in Laptop Screen */

@media only screen and (max-width: 1240px) {
  .S1SubHeading05 {
    font-size: 40px;
  }
  .dateInput01 {
    font-size: 12.754px;
    padding: 11px 14px;
  }
  .downloadBtn01 {
    font-size: 14px;
    padding: 12px 18px;
  }
  .statementDiv01 {
    margin-top: 25px;
  }
  .takeControlP {
    font-size: 36px;
    padding-bottom: 95px;
  }
  /* .playLogos {
    max-width: 175.25px !important;
    height: 40.81px !important;
  } */
  .s5PopUpDiv1,
  .AUp6s5PopUpDiv {
    padding: 10rem 0;
  }
  .AUp6s5PopUpDiv {
    padding-top: 12rem;
    padding-bottom: 15rem;
  }
  .DWS1HeadingBold {
    font-size: 40px;
    line-height: 53px;
  }
  .DWS1Heading {
    font-size: 40px;
    line-height: 53px;
  }
  .DWS1Img {
    position: relative;
    top: -1rem;
  }
  .cryptoMainRow h1 {
    font-size: 44px;
    line-height: 56px;
  }
  .FS1Carddiv {
    padding: 2rem;
  }
  .FS1Carddiv h1 {
    font-size: 40px;
    line-height: 46px;
  }
  .WS1BtnDiv {
    display: flex;
    gap: 1rem;
  }
  .FS1Carddiv h5 {
    font-size: 15px;
    line-height: 18px;
  }
  .FDetailsDiv p {
    font-size: 12px;
    line-height: 14px;
  }
  .wrapperInsideSecondDiv h1 {
    font-size: 40px !important;
    line-height: 40px !important;
  }
  .mainContainerSignups {
    padding: 6.75rem 3rem 0rem 3rem;
  }
  .S1Heading {
    font-size: 32px;
    line-height: 40px;
  }
  .S1SubHeading {
    font-size: 32px !important;
    line-height: 40px;
  }
  .S1Para {
    font-size: 19px;
    line-height: 23px;
  }

  .p6s5PopUpDiv {
    padding-top: 1rem;
    padding-bottom: 19rem;
  }
  .p7s2Heading {
    font-size: 32px;
    line-height: 40px;
  }
  .p7s2Para {
    font-size: 19px;
    line-height: 23px;
  }

  .rightSideHrS6P4 {
    width: 100%;
    height: auto;
  }

  .s5PopUpBtnP10 {
    padding: 3.5rem;
  }
  .p5S7Heading {
    font-size: 22px;
    line-height: 30px;
  }
  .S3BottomCenterHeading {
    font-size: 19px;
    line-height: 23px;
  }

  .s7P2RightsideColumnImgP3 {
    padding: 0 1rem;
    margin-bottom: 1rem;
  }

  .CryptoS7Bg {
    background-image: url("../../../public/images/S&MBBgCrupto.svg");
  }
  .leftSideS2ImgP4 {
    width: 100%;
    height: fit-content;
  }

  .s5PopUpBtnP3 {
    padding: 3rem 0;
  }
  .s6P2heading {
    padding-top: 2rem;
  }

  .S3Para {
    padding: 0.5rem 0rem 4rem 0;
  }

  .S1Para {
    padding: 1rem 1rem 1rem 0;
  }
  .s10Text {
    padding: 0 3rem;
    font-size: 28.27px;
    line-height: 35px;
  }
  .columnSettingsS7 {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    padding: 0 1.7rem;
  }
  .s5PopUpBtn {
    width: 100% !important;
    height: 100% !important;
    padding: 2rem 1rem;
  }
  .s5PopUpDiv {
    padding-top: 0rem;
    padding-bottom: 12rem;
  }
  .S3BottomHeading {
    font-size: 24px;
    line-height: 30px;
  }
  .s7P2RightsideColumnp3 {
    padding: 0 1.5rem;
  }
  .HMS3Bg {
    padding-top: 25rem;
    padding-bottom: 4rem;
  }

  .homePageS1SubHeading {
    font-size: 19px;
    line-height: 23px;
  }
  .homePageS3SubHeading {
    font-size: 19px;
    line-height: 23px;
  }
  .navbar_titles {
    padding: 0rem 0.3rem;
  }
  .navbarLogo {
    width: 130px;
    height: 67px;
    max-width: 100%;
    padding-left: 1rem;
  }

  .navbar_titles_rightSide {
    font-size: 16px !important;
    line-height: 20px !important;
  }
  .secondStephr {
    padding: 1.5rem 0;
  }
}
@media only screen and (max-width: 1200px) {
  .FotterBg {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }
  .ftrTitle {
    font-size: 16px !important;
    margin-bottom: 25px !important;
  }
  .ftrSubTitle {
    font-size: 14px;
    margin-bottom: 25px;
  }
  .footerTxtDiv h4 {
    font-size: 17px;
  }
  .footerTxtDiv p {
    font-size: 14px;
  }
}
@media only screen and (max-width: 1070px) {
  .p5S7Heading01 {
    font-size: 26px;
  }
  .dateInput01 {
    font-size: 11.754px;
    padding: 10px 12px;
  }
  .downloadBtn01 {
    font-size: 12px;
    padding: 12px 16px;
  }
  .takeControlP {
    font-size: 30px;
    padding-bottom: 90px;
  }
  .s5PopUpDiv1,
  .AUp6s5PopUpDiv {
    padding: 8rem 0;
  }
  .DWS1HeadingBold {
    font-size: 35px;
    line-height: 43px;
  }
  .DWS1Heading {
    font-size: 35px;
    line-height: 43px;
  }
  .DWS1Img {
    position: relative;
    top: -0.5rem;
  }
  .FDetailsDiv p {
    font-size: 10px;
    line-height: 10px;
  }
  .FS1Carddiv h6 {
    font-size: 10px;
    line-height: 12px;
  }
  .FS1Carddiv h1 {
    font-size: 36px;
    line-height: 46px;
  }
  .CWS5PopUpBtnDiv {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
  .HPS1Imfg {
    position: relative;
    left: 2%;
  }
  .S1Heading {
    font-size: 26px;
    line-height: 35px;
  }
  .S1SubHeading {
    font-size: 26px !important;
    line-height: 35px;
  }
  .S1Para {
    font-size: 17px;
    line-height: 20px;
  }

  .p6s5PopUpDiv {
    padding-top: 1rem;
    padding-bottom: 16rem;
  }
  .p5S7Heading {
    font-size: 24px;
    line-height: 30px;
  }
  .S3BottomCenterHeading {
    font-size: 17px;
    line-height: 20px;
  }

  .p7s2Heading {
    font-size: 26px;
    line-height: 35px;
  }
  .p7s2Para {
    font-size: 17px;
    line-height: 20px;
  }

  .S5PopUpBtnDivP3 {
    padding: 7rem 0 8rem 0;
  }
  .s5BannerBg {
    padding: 0 0rem 0 1rem;
  }

  .S4Para {
    font-size: 17px;
    line-height: 20px;
    padding: 1rem 4rem 0.5rem 0;
  }
  .s5Btn {
    width: 210px;
    height: 51px;
  }
  .s5Btntxt {
    font-size: 20px;
  }
  .IndexS8 {
    padding-bottom: 1rem !important;
  }
  .s5PopUpBtn {
    width: 100% !important;
    height: 100% !important;
    padding: 1rem 1rem;
  }
  .s5PopUpDiv {
    padding-top: 13rem;
    padding-bottom: 3rem;
  }
  .s7P2RightsideColumnp3 {
    padding: 0 1.5rem;
  }
  .HMS3Bg {
    padding-top: 20rem;
    padding-bottom: 3rem;
  }
  .s4BottomSubHeading {
    font-size: 16px;
    line-height: 20px;
  }

  .homepageS1MainHeadingDiv h3 {
    font-size: 26px;
    line-height: 35px;
  }
  .homepageS1MainHeadingDiv h4 {
    font-size: 26px;
    line-height: 35px;
  }
  .homePageS1SubHeading {
    font-size: 17px;
    line-height: 20px;
  }
  .s2HeadingBold {
    font-size: 26px;
    line-height: 35px;
  }
  .s2HeadingBold05 {
    font-size: 26px;
    line-height: 35px;
  }
  .homePageS2SubHeading {
    font-size: 17px;
    line-height: 20px;
  }
  .homePageS3SubHeading {
    font-size: 17px;
    line-height: 20px;
  }
}

/* Below 92px Screens */
@media only screen and (max-width: 992px) {
  .exDetailsCardBg{
    margin-bottom: 8px;
  }
  .footerMain {
    padding-bottom: 50px !important;
    padding-top: 50px !important;
  }
  .bottomIcon p {
    font-size: 14px !important;
  }
  .bottomFooterDiv {
    gap: 25px !important;
  }
  .bottomTxt p {
    margin-top: 30px !important;
    padding-bottom: 25px !important;
    font-size: 14px !important;
  }
  .bottomMoneyDiv h6 {
    font-size: 14px !important;
  }
  .bottomMoneyDiv a {
    font-size: 14px !important;
  }
  .bottomMoneyDiv {
    gap: 3px !important;
    margin-top: 16px !important;
  }
  .bottomTxt p {
    padding-bottom: 20px !important;
  }
  .ftrLogo {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .footerLinkCol {
    width: 100%;
    max-width: 200px;
    margin-bottom: 1.5rem;
  }
  .ftrLinkHide {
    flex-wrap: wrap;
  }
  .paraWidth {
    max-width: 100%;
  }
  .paraWidth01 {
    max-width: 100%;
  }
  .firstDiv {
    min-height: auto;
  }
  .AcCurrencyddDivApply {
    padding: 0.55rem !important;
  }
  /* .playLogos {
    max-width: 144.25px !important;
    height: 29.81px !important;
  } */
  .s5PopUpDiv1,
  .AUp6s5PopUpDiv {
    padding: 7rem 0;
  }
  .DWS1HeadingBold {
    font-size: 25px;
    text-align: center;
    line-height: 33px;
  }
  .DWS1Heading {
    font-size: 25px;
    text-align: center;
    line-height: 33px;
  }
  .DWS1Img {
    position: relative;
  }
  .imgResponsivesWidth {
    max-width: 100%;
    height: auto;
  }
  .cardLeftSide p {
    font-size: 15px;
    line-height: 18px;
  }
  .cardLeftSide h4 {
    font-size: 18px;
    line-height: 22px;
  }
  .cardUpperRow h1 {
    font-size: 18px;
    line-height: 22px;
  }
  .FS1Carddiv {
    padding: 1.5rem;
  }
  .sidebarToggle {
    display: none;
  }
  .sidebar {
    position: absolute;
    top: 3.35rem;
    z-index: 9;
  }
  .sideBarMainContent {
    margin-top: 3.4rem;
  }
  .wrapperInsideSecondDiv {
    padding-top: 4rem;
  }
  .uppderTMb {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .WS1BtnDiv {
    display: flex;
    gap: 0.5rem;
    padding-bottom: 2rem;
  }
  .CWS5PopUpBtnDiv {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  .AUp6s5PopUpDiv {
    padding-top: 7rem;
    padding-bottom: 8rem;
  }
  .AUp6s5PopUpDiv {
    padding-top: 10rem;
    padding-bottom: 12rem;
  }
  .HPS1Imfg {
    position: relative;
    left: 0;
  }
  .BAS5PopUpBtnDiv {
    padding-top: 6rem;
    padding-bottom: 7rem;
  }
  .HPs5PopUpDiv {
    padding-top: 3rem;
    padding-bottom: 7rem;
  }
  .SignInForm {
    padding: 0 1.5rem;
  }
  .BottomText {
    padding: 2rem 0;
  }
  .S1Heading {
    font-size: 24px;
    line-height: 32px;
  }
  .S1SubHeading {
    font-size: 24px !important;
    line-height: 32px;
  }
  .S1Para {
    font-size: 16px;
    line-height: 19px;
  }
  .p6s5PopUpDiv {
    padding-top: 1rem;
    padding-bottom: 19rem;
  }

  .p7s2Heading {
    font-size: 24px;
    line-height: 32px;
  }
  .p7s2Para {
    font-size: 16px;
    line-height: 19px;
  }

  .s5PopUpBtnP10 {
    padding: 2.5rem;
  }

  .accountsTypeDiv {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }

  .homepageContainerP2 {
    padding-left: 3rem !important;
    padding-right: 2rem !important;
    padding-bottom: 9rem;
  }

  .accTypesHeading2 {
    padding: 0.5rem 0.5rem 0rem 0.2rem;
    margin-bottom: 0 !important;
  }

  .p5S7Heading {
    font-size: 24px;
    line-height: 30px;
  }
  .S3BottomCenterHeading {
    font-size: 16px;
    line-height: 19px;
  }
  .S1PATopPaddingP3 {
    padding-top: 9rem;
  }

  .homepageContainerP3 {
    padding-left: 3rem !important;
    padding-right: 2rem !important;
    padding-bottom: 9rem;
  }

  /* .s5BannerBg {
  padding: 0 0rem 0 0rem;
} */

  .btnLarge {
    width: 220px;
    height: 50px;
  }
  .brnLargeText {
    font-size: 20px !important;
  }
  .s7P2RightsideColumn {
    padding: 0rem 2rem 0rem 4rem;
    margin-bottom: 7rem;
  }
  .s7P2LeftsideColumn {
    padding: 0rem 4rem 0rem 2rem;
    margin-bottom: 6rem;
  }
  .s7P2RightsideColumnImg {
    padding: 0rem 0rem 0rem 4rem;
    margin-bottom: 1rem;
  }

  .S4Para {
    font-size: 16px;
    line-height: 19px;
    padding: 1rem 4rem 0.5rem 0;
  }

  .PAS3Padding {
    padding: 0 1.5rem 0 1.5rem;
  }
  .p0md {
    padding: 0 !important;
  }
  .FotterBg {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .HPS8Ri {
    position: relative;
    top: -2rem;
  }

  .columnSettingsS7 {
    padding: 0 0.7rem;
  }
  .S4BtnDiv {
    padding-top: 1rem;
  }
  .HPS7Btn {
    width: 254px !important;
  }
  .S5BtnDiv {
    padding-top: 2.5rem;
  }
  .paddingS4HpParaRight {
    padding-right: 4rem;
  }
  .paddingS4HpParaLeft {
    padding-right: 4rem;
  }
  .s4BottomHeading {
    font-size: 24px;
    line-height: 29px;
  }
  .homepageS3MainHeadingDiv h3 {
    font-size: 24px;
    line-height: 32px;
  }
  .homePageS4SubHeading {
    font-size: 16px;
    line-height: 19px;
  }
  .HMS3Bg {
    padding-top: 18rem;
    padding-bottom: 3rem;
  }
  .navbarContainer {
    height: auto;
    background-color: #ffffff !important;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    padding: 0 2rem;
  }
  .navbar_titles {
    padding-left: 0 !important  ;
  }
  .homepageContainer {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
    padding-bottom: 5rem;
  }
  .homepageS1MainHeadingDiv h3 {
    font-size: 24px;
    line-height: 32px;
  }
  .homepageS1MainHeadingDiv h4 {
    font-size: 24px;
    line-height: 32px;
  }
  .homePageS1SubHeading {
    font-size: 16px;
    line-height: 19px;
    padding-bottom: 0;
  }
  .homePageS2SubHeading {
    padding: 0;
  }
  .homePageS3SubHeading {
    padding: 0;
  }
  .manageImmg01 {
    padding-right: 3rem !important;
  }
}

@media only screen and (max-width: 780px) {
  
  .ftrMbIcon {
    padding-bottom: 0 !important;
  }
  .bottomFooterDiv {
    gap: 20px !important;
    flex-wrap: wrap;
  }
  .socialIcons img {
    height: 17px !important;
  }
  .S1SubHeading05 {
    font-size: 28px;
    text-align: center !important;
  }
  .statementDiv02 {
    flex-wrap: wrap;
  }
  .dateInput01 {
    font-size: 10.754px;
    padding: 10px 12px;
  }
  .downloadBtn01 {
    font-size: 11px;
    padding: 11px 15px;
  }
  .statementDiv01 {
    margin-top: 25px;
    margin-bottom: 1rem;
    gap: 10px;
  }
  .s5PopUpDiv1 {
    padding: 5rem 0;
  }
  .takeControlP {
    font-size: 25px;
    padding-bottom: 74px;
  }
  .playLogos {
    display: none;
  }
  .WS1BtnDiv {
    padding-right: 2rem;
  }
  .DWS1HeadingBold {
    font-size: 25px;
    text-align: center;
    line-height: 33px;
  }
  .DWS1Heading {
    font-size: 25px;
    text-align: center;
    line-height: 33px;
  }

  .cardDetailsCard {
    padding: 1.5rem;
  }
  .CSwapLabelDiv p {
    font-size: 16px;
    line-height: 18px;
  }
  .swapCard {
    padding: 2rem;
  }
  .savedPaymentCard .name {
    font-size: 16px;
    line-height: 18px;
  }
  .newPaymentDiv h6 {
    font-size: 14px;
    line-height: 16px;
  }
  .savedPaymentCard .accNo {
    font-size: 10px;
    line-height: 12px;
  }
  .lastTransactionCard .trAmount span {
    font-size: 10px;
    line-height: 12px;
  }
  .lastTransactionCard .trDate {
    font-size: 8px;
    line-height: 10px;
  }
  .lastTransactionCard .trTitle {
    font-size: 8px;
    line-height: 10px;
  }
  .savedPaymentCard .accTitle {
    font-size: 10px;
    line-height: 12px;
  }
  .cardUpperRow h3 {
    font-size: 18px;
    line-height: 19px;
  }
  .fiatTransferCard {
    padding: 1.5rem;
  }
  .cardContentDiv a {
    width: 150px;
    padding: 1rem;
    font-size: 14px;
    line-height: 14px;
  }
  .cryptoMainRow h1 {
    font-size: 32px;
    line-height: 36px;
  }
  .WCDUpperRow h2 {
    font-size: 24px;
    line-height: 34px;
  }
  .addCurrencyCard {
    padding: 0.3rem 1.8rem;
  }
  .hoverBtnRow {
    display: none;
  }
  .walletInsideMainContainer {
    padding: 3rem 0.5rem;
  }
  .SummaryTxt {
    font-size: 18px;
    line-height: 28px;
  }

  .UpperNavHeading {
    font-size: 20px;
    line-height: 36px;
  }
  .WBackArrow {
    width: 28px;
    height: 28px;
  }
  .WMainDiv {
    padding: 1rem 2rem;
    width: 100%;
  }
  .WHPS3RecentActivityDiv span {
    font-size: 9px;
    line-height: 9px;
  }
  .WHPS3RecentActivityDiv p {
    font-size: 12px;
    line-height: 18px;
  }
  .WHPS3RecentActivityDiv a {
    padding: 0.5rem 0rem;
    border-radius: 0;
    background-color: transparent;
    color: #2645e0 !important;
  }
  .WHPS3RecentActivityDiv h1 {
    padding: 0.5rem 0rem;
    font-size: 16px;
    font-weight: 500;
    line-height: 18px;
  }
  .WHPS2Card h2 {
    font-size: 24px;
    font-weight: 600;
    line-height: 29px;
  }
  .WCDUpperRow h2 {
    font-size: 24px;
    line-height: 29px;
  }
  .WHPS2Card h4 {
    font-size: 15px;
    line-height: 18px;
  }
  .CWS5PopUpBtnDiv {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .contactusupperTxt {
    font-size: 18px !important;
    line-height: 21px !important;
    text-align: left !important;
  }
  .file-drop-container p {
    font-size: 18px !important;
    line-height: 22px !important;
    padding: 4rem 0;
  }
  .homepageContainerP3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
    padding-bottom: 0rem;
  }
  .p12S4BottomHeading {
    font-size: 20px;
    line-height: 24px;
    padding: 2rem 0rem 1rem 0;
  }
  .p12ParaHeading {
    font-size: 24px;
    line-height: 30px;
  }
  .p12Para {
    font-size: 20px;
    line-height: 24px;
    padding-right: 0;
  }
  .file-drop-container {
    padding: 2rem;
  }
  .p8S1Heading {
    font-size: 32px;
    line-height: 46px;
  }
  .pr_rem5 {
    padding-right: 0;
  }
  .pr_rem6 {
    padding-right: 0;
  }
  .p9s1Heading {
    font-size: 16px;
    line-height: 16px;
  }
  .accordionCustomColorBody {
    background-color: #e8f2ee !important;
    color: #000000 !important;
    border: #e8f2ee !important;
    /* font-size: 32px !important;
    font-weight: 600 !important;
    line-height: 38px !important;
    letter-spacing: 0em !important;
    text-align: left !important; */
    padding: 0.5rem 1rem 0 01rem !important;
  }
  .accordionCustomColor {
    padding: 0.7rem 1.5rem 0 0.5rem !important;
  }
  .accordian_heading {
    font-size: 24px;
  }
  .p14S1Para {
    font-size: 20px;
    padding-right: 0.5rem;
  }
  .p14S1ParaBold {
    font-size: 20px;
  }
  .p9s1HeadingBold {
    font-size: 16px;
    line-height: 16px;
  }
  .pr_rem4 {
    padding-right: 0rem;
  }
  .S1Heading {
    font-size: 22px;
    line-height: 25px;
  }
  .S1SubHeading {
    font-size: 22px !important;
    line-height: 25px;
  }
  .S1Para {
    font-size: 12px;
    line-height: 17px;
  }
  .p6-sub-gradient-text {
    font-size: 24px;
    line-height: 26px;
  }
  .p6-gradient-text {
    font-size: 32px;
    line-height: 36px;
  }
  .aboutUsBg {
    background-image: url("../../../public/images/AUS4BgMb.svg");
  }
  .s5PopUpBtnP10 {
    padding: 1.25rem;
    border-radius: 18px;
  }
  .p6s31LastCol {
    padding: 0 0 0 0;
  }
  .p6s6columnBorder {
    border: none;
  }

  .S4Para {
    font-size: 12px;
    line-height: 17px;
    padding: 1rem 4rem 0.5rem 0;
  }

  .IndexS8 {
    padding-bottom: 3rem !important;
  }
  .s5PopUpDiv {
    padding-top: 7rem;
    padding-bottom: 3rem;
  }
  .paddingS4HpParaRight {
    padding-right: 1rem;
  }
  .manageParaHeight {
    min-height: 85px;
  }
  .S3BottomHeading {
    font-size: 24px;
    line-height: 30px;
  }
  .s4BottomSubHeading {
    font-size: 12px;
    line-height: 17px;
  }
  .HMS3Bg {
    padding-top: 13rem;
    padding-bottom: 2rem;
  }
}
@media only screen and (max-width: 768px) {
  .careerAccordionButton::after {
    top: -20px !important;
  }
  .sideBar {
    display: flex;
    gap: 12px;
    flex-wrap: wrap;
    margin-bottom: .5rem;
}
.careerAccordionButton:not(.collapsed)::after {
  top: -28px !important;
}
.accordian_heading{
  margin-bottom: 0 !important;
}
.search-container{
  margin: 1rem 0 2rem 0 !important;
}
.accordionCustomColor {
  padding: 0.5rem 0.5rem 0 0.5rem !important;
  --bs-accordion-btn-icon-width: 1.1rem !important;
}
.accordian_heading {
  font-size: 21px;
}
}

/* Below 92px Screens */
@media only screen and (max-width: 580px) {
  .manageLogoW{
    width: 40%;
  }
  .manageBtnW{
    width: 75%;
  }
  .fixedHeight001 {
    height: calc(100vh - 105px) !important;
}
  .checkImg{
    width: 10px;
    height: 10px;
  }
  .bottom_steps_h_black , .bottom_steps_h_blue {
    font-size: 10px;
    margin-bottom: 2px !important;
  }
  .buttom_steps_row{
    height: 12px;
    flex-shrink: 0;
    background: #FAFAFA;
  }
  .fill_up{
    background: var(--Primary-Blue, #2645E0);
    transition: ease-in-out .5s;
  }
  .acc_type_h2{
    font-family: 'Inter';
    font-size: 16px;
    font-weight: 300;
    line-height: 29.05px;
    text-align: left;
    color: rgba(0, 5, 26, 1);
  }
  .new_error_text {
    color: #F0263C;
    font-family: 'Inter';
    font-size: 9px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.back_icon {
  width: 30px;
  cursor: pointer;
}
  .securityImg{
    position: relative;
    bottom: 0px;
  }
  .detailsPageTxt{
    font-size: 16px;
  }
  .detailsPageTxtb{
    font-size: 16px;
  }
  .eyeIcons{
    right: 7%;
  }
  .cookieModalRejectBtn, .cookieModalAcceptBtn {
    width: 100%;
  }
  .accordionCustomColorBody{
    background-color: transparent !important;
  }
  .careerAccordionButton01::after {
    top: -25px;
  }
  .detailsPageH , .detailsPageHBlack{
    font-size: 24px;
    line-height: normal;
  }
  .accordian_heading{ 
    width: 90% !important;
  }
  .exRightDiv01{
    justify-content: center;
  }
  .modal-container{
    height: 50%;
    overflow-y: hidden;
    overflow-x: hidden;
  }
  .modal-contents {
    transform: translate(.5rem,.5rem);
  }
  .modal-contents h3{
    font-size: 24px;
  }
  .modal-contents p{
    font-size: 18px;
    width: 90%;
  }
  .modal-contents .acceptBtn ,.modal-contents .rejectBtn{
    font-size: 15.5px;
    padding: 11px 6px;
    width: 180px;
  }
  .search-container {
    margin: 1rem 0 1rem 0 !important;
}
.sidebarLinkCareers a {
  font-size: 14px;
}
.sideBar {
  gap: 10px;
}
.accordian_heading {
  font-size: 16px !important;
}

  .exHeading {
    font-size: 24px;
    line-height: 29px; /* 107.5% */
    text-align: center;
  }
  .exPara {
    font-size: 16px;
    line-height: 19px;
    max-width: 100%;
    margin-top: 10px;
    text-align: center;
  }
  .exCard01 .currencyLogo {
    height: 20px;
    min-width: 20px;
  }
  .exImgbottomImg {
    display: none;
  }
  .exCard {
    position: unset;
    transform: translate(0, 0);
    margin-top: 30px;
  }
  .exImgbottomImg01 {
    display: none;
  }
  .sendMoneyRow {
    margin-top: 0 !important;
    position: unset !important;
  }
  .exRightDiv {
    justify-content: center !important;
  }
  .downLineCm {
    display: none;
  }
  .exSectionMainHeading {
    font-size: 24px;
    line-height: 30px; /* 125% */
    margin-top: 30px;
  }
  .exSectionMainPara {
    font-size: 16px;
    line-height: 23px;
    margin-top: 17px;
    width: 100%;
  }
  .exHeadingH1 {
    margin-top: 30px !important;
  }
  .gradinatHeadingTxt {
    text-align: center;
    font-size: 24px;
    line-height: 29px; /* 112.5% */
    letter-spacing: -1.2px;
  }
  .exDetailsCardBg {
    margin-bottom: 6px;
  }
  .exDetailsCardBg h3 {
    font-size: 16px;
  }
  .coleageImg {
    justify-content: center;
  }
  .coleageImg img{
    width: 100%;
  }
  .exDetailsCardBg p {
    font-size: 12px;
  }
  .exCard01 {
    margin-top: 34px;
    position: unset;
    transform: translate(0, 0);
  }
  .exSectionMainPara {
    font-size: 16px;
    line-height: 19px;
}
.exBottomImg {
  width: 32px;
}
.upperRow p {
  font-size: 12.5px;
}
.upperRow img {
  width: 24px;
}
.row1 p, .row2 p {
  font-size: 13px;
}
.row1 span, .row2 span {
  font-size: 13px;
}
.exSubHeading {
  font-size: 16px;
  line-height: 19px;
}
  .exHeadingB {
    font-size: 24px;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: -0.48px;
  }
  .exbottomheading {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 14px;
  }
  .reverseRow {
    flex-direction: column-reverse;
  }
  .exHeadingH1 {
    font-size: 24px;
    font-weight: 300;
    line-height: 30px;
    letter-spacing: -0.48px;
  }
  .exHeadingBR {
    display: none !important;
  }
  .containerFluid {
    padding: 0 !important;
  }
  .ftrTitle {
    font-size: 14px !important;
  }
  .noneImg {
    display: none !important;
  }
  .borderTop {
    border-top: 2px solid #00051a !important;
    margin-top: 10px !important;
    padding-bottom: 0 !important;
  }
  .showFooter {
    display: block !important;
    margin-bottom: 2rem !important;
  }
  .accordionButton {
    padding: 16px 0 !important;
    border: none !important;
    outline: 0 !important;
    box-shadow: none !important;
    --bs-accordion-btn-icon-width: 0.76rem !important;
  }
  .accordionButton:not(.collapsed)::after {
    background-image: url("../images/Vector.svg") !important;
    position: relative;
    top: -2px;
    border: none !important;
    outline: 0 !important;
  }
  .accordionButton::after {
    background-image: url("../images/Vector.svg") !important;
    position: relative;
    top: 4px;
    border: none !important;
    outline: 0 !important;
  }

  .ftrSubTitle {
    margin-bottom: 1rem !important;
  }
  .accordionItem .accordion-body {
    padding: 0px 0 0 0 !important;
  }
  .hideFooter {
    display: none !important;
  }
  .footerTxtDiv {
    display: none !important;
  }
  .footerLinkCol {
    max-width: 175px;
  }

  .gradient-text10 {
    font-size: 10px;
  }
  .blogHeadnigDetails {
    font-size: 24px;
    line-height: normal;
  }
  .blogSubMainImg {
    max-height: 400px;
    min-height: auto;
    min-width: 50%;
  }
  .blogMainImg01 {
    height: auto;
    max-height: 400px;
  }
  .sideBar-logo-image {
    width: auto;
    /* height: 31px;
    margin-bottom: 2rem; */
  }

  .allBlogCatBtn {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .bSubH1 {
    font-size: 24px;
    line-height: normal;
  }
  .allBlogCatBtn {
    display: flex;
    flex-wrap: wrap;
    flex-basis: 2;
    justify-content: center;
  }
  .blogUpperBtnRow button {
    width: max-content;
    height: 30;
    font-size: 12px;
  }
  .blogUpperBtnRow {
    width: max-content;
    height: 30;
    font-size: 12px;
  }
  .simpleBtn {
    font-size: 12px;
    line-height: 11px;
  }
  .bH1 {
    font-size: 24px;
  }
  .blogSubMainImg {
    width: 100%;
  }
  .sideBlogDiv {
    display: flex;
    flex-wrap: wrap;
  }
  .blogLeftSideMainImg {
    width: 100%;
    height: auto;
  }
  .p6s4BottomSubHeading {
    min-height: auto;
  }
  .S1HpImg {
    width: 80% !important;
  }
  .btnForMB {
    display: flex;
    justify-content: center;
  }
  .dropdownDiv01 {
    flex-wrap: wrap;
    gap: 5px;
    justify-content: flex-end;
  }
  .statementDiv01 {
    flex-wrap: wrap;
    gap: 14px;
  }
  .dateInput01 {
    font-size: 10.754px;
    padding: 9px 12px;
    max-width: 116px;
  }
  .downloadBtn01 {
    font-size: 11px;
    padding: 10px 11px;
  }
  .p7s3Heading,
  .aboutUSNewHeading {
    font-size: 24px;
    line-height: normal;
  }
  .btnMediumText {
    font-size: 16px;
    line-height: normal;
  }
  .CdS1SubHeading {
    font-size: 24px;
    line-height: normal;
    text-align: left !important;
  }
  .mobileRespons {
    max-width: 50% !important;
  }
  .imgCenter {
    display: flex;
    justify-content: center;
  }
  .s5PopUpDiv1 {
    padding: 3rem 0;
  }
  .takeControlP {
    font-size: 20px;
    padding-bottom: 64px;
  }
  .p6-gradient-text {
    font-size: 18px;
    line-height: 26px;
  }
  .AUnewPara {
    font-family: "sf-ui-display";
    font-size: 16px;
    padding: 0 0.5rem;
  }
  .p6-sub-gradient-text {
    font-size: 16px;
    line-height: 22px;
  }
  .visibiltyMange {
    visibility: visible;
  }

  .mb022 {
    margin-top: 2rem;
  }

  .s3InsidePadding {
    padding: 2rem 0 0 0;
  }

  .UpperNavHeading,
  .cardUpperRow h3 {
    font-size: 16px;
  }
  .WHS2MainDiv {
    padding-bottom: 3rem !important;
  }
  .WHPS2Card {
    padding: 1rem 1.25rem;
  }
  .cardLogo {
    border-radius: 50%;
    width: 33px;
    height: 33px;
    position: relative;
    bottom: 3.5px;
  }
  .swapBtn {
    width: 62px;
    height: auto;
    top: 0.2rem;
  }
  .blogMainImg10 {
    max-height: auto;
  }
  .divBtnMedium {
    width: 200px;
  }
  .btnMedium {
    width: 200px !important;
  }
  .transparent-gradient-border-btn {
    width: max-content;
  }
  .transparent-gradient-border-btn .button-content {
    width: max-content;
  }

  .p12Para {
    font-size: 16px;
    line-height: normal;
  }
  .PPMainDiv {
    padding-bottom: 3rem !important;
  }
  .TOUMainDiv {
    padding-bottom: 3rem !important;
  }
  .p12ParaHeading {
    padding: 1.5rem 0 1rem 0;
    line-height: normal;
  }
  .p12S4BottomHeading {
    font-size: 19px;
    line-height: normal;
  }
  .S1PATopPaddingP3 {
    padding-top: 5.5rem;
  }

  .accordian_heading {
    font-size: 20px;
  }
  .contactusupperTxt {
    font-size: 16px !important;
    line-height: normal !important;
  }
  .accordionCustomColor {
    padding: 0.7rem 0.5rem 0 0.5rem !important;
  }
  .jobLocation {
    font-size: 16px;
    line-height: normal;
  }
  .p14S1ParaBold {
    font-size: 16px;
    line-height: normal;
  }
  .p9s1HeadingBold {
    font-size: 24px;
    line-height: normal;
  }
  .CPunderRowCard:hover .leftSideText1 {
    display: flex !important;
    flex-wrap: wrap;
    justify-content: flex-end;
    width: 100px;
  }
  .sidebar {
    box-shadow: unset !important;
  }
  .detailInput {
    padding: 0.75rem 0.5rem;
  }
  .detailInput p {
    font-family: "sf-ui-display";
    font-size: 13px;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    color: #00051a;
    margin-bottom: 0 !important;
  }
  .fDHoverBtnDiv {
    min-width: 50px;
  }

  .WFDS2Card:hover .hoverBtnRow {
    transition: ease-in 0.3s;
    display: flex;
    flex-direction: column;
    min-width: 200px;
    padding: 0;
    align-items: center;
  }
  .navbarLogo {
    width: 130px;
    height: 33px;
    max-width: 100%;
    padding-left: 1rem;
    margin: 0.5rem;
  }
  .mainContainer {
    padding: 6.75rem 2rem 0rem 2rem;
  }
  .BAS5PopUpBtnDiv {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .gradient-text {
    font-size: 16px;
    line-height: normal;
  }
  .accTypesHeading2 {
    line-height: normal !important;
  }
  .DWS1Img {
    position: relative;
    top: 5rem;
    left: 1rem;
  }
  .PSS6RImg img {
    position: relative;
    top: 0rem;
  }
  .pe-4 {
    padding-right: 0 !important;
  }
  .paymentConfirmationCard .title {
    font-size: 16px;
    line-height: 18px;
  }
  .pageLink {
    font-size: 16px;
    line-height: 18px;
  }
  .tid {
    font-size: 16px;
    line-height: 18px;
  }
  .paymentConfirmationCard .amount {
    font-size: 16px;
    line-height: 18px;
  }
  .paymentConfirmationCard img {
    width: 85px;
    height: 85px;
  }
  .code-input-container input {
    width: 36px;
    height: 36px;
  }
  .WMainDiv {
    padding: 1rem 1rem 5rem 1rem;
    width: 100%;
  }
  .currencySwapOptionImg {
    width: 28px;
    height: 28px;
  }
  .currencySwapOption h3 {
    font-size: 16px;
    line-height: 20px;
  }
  .AUp6s5PopUpDiv {
    padding-top: 12rem;
    padding-bottom: 8rem;
  }
  .rowActiveBg {
    background: none;
  }
  .rowActivecover {
    border-image: none;
  }
  .p7s5ImgTextDiv {
    padding: 2rem 1rem;
  }
  .Ps4GradientRightSubHeading {
    padding: 0rem 2rem 0rem 0rem;
  }
  .Ps4GradientSubHeading {
    padding: 0.3rem 0 1.5rem 1rem;
    font-size: 16px;
    line-height: normal;
  }
  .Ps7s6optionText {
    font-size: 16px;
    line-height: normal;
  }
  .S1Heading {
    font-size: 24px !important;
    line-height: 29px !important;
  }
  .statementDiv02 {
    margin-top: 2rem !important;
    justify-content: flex-start !important;
  }
  .S1SubHeading {
    font-size: 24px !important;
    line-height: normal;
  }
  .S1Para {
    font-size: 16px;
    line-height: normal;
    text-align: center;
  }
  .p10s4Para {
    line-height: normal;
    font-size: 16px;
  }
  .SignupHeadings {
    font-size: 24px !important;
    font-weight: 600 !important;
    line-height: 32px !important;
    padding: 3rem 0.5rem 0 1rem;
  }
  .AUS4Bg {
    background-image: url("../../../public/images/AUS4Bg.svg");
  }
  .p6s5PopUpDiv {
    padding-top: 1rem;
    padding-bottom: 5rem;
  }
  .mainContainerSignups {
    padding: 6.75rem 1.75rem 0rem 1.75rem;
  }
  .contactusLabels {
    font-size: 20px !important;
    line-height: 28px !important;
    padding-bottom: 0 !important;
  }
  .formInputs {
    font-size: 18px;
  }
  .formError {
    font-size: 18px !important;
    line-height: 24px !important;
    color: #f10d0d !important;
  }
  .p10S4Heading,
  .p8S1Heading {
    font-size: 24px;
    line-height: normal;
  }

  .p7s2Heading {
    font-size: 40px;
    line-height: 48px;
    text-align: left;
  }
  .p7s2Para {
    font-size: 16px;
    line-height: normal;
    text-align: left;
  }

  .authSubHeading {
    font-size: 16px !important;
    line-height: normal !important;
    color: #00051a;
    width: 100% !important;
    max-width: 240px !important;
    margin-top: 23px;
  }
  .accTypesHeading {
    font-size: 18px !important;
    line-height: normal !important;
    padding: 2.5rem 0.5rem 0.5rem 0rem;
  }
  .registertypeCardsHeading {
    font-size: 20px !important;
    line-height: normal !important;
  }
  .registertypeCardsPara {
    font-size: 16px !important;
    line-height: normal !important;
    padding: 1rem 2.5rem 2rem 0rem;
  }
  .registertypeCardsPara1 {
    font-size: 16px !important;
    line-height: normal !important;
    padding: 1rem 2rem 2rem 0;
  }
  .homepageContainerP2 {
    padding-left: 1.3rem !important;
    padding-right: 1.5rem !important;
    padding-bottom: 1rem;
  }
  .p5S7Heading {
    font-size: 24px;
    line-height: normal;
    padding-top: 0;
  }
  .Ps7s6optionTextBold {
    font-size: 16px;
    line-height: normal;
  }
  .S3BottomCenterHeading {
    font-size: 16px;
    line-height: 19px;
  }

  .CS4Bg {
    background-image: url("../../../public/images/p5s4RightSide.svg");
  }
  .rPBCS2 {
    padding-bottom: 0 !important;
  }

  .s7P2LeftsideColumn {
    padding: 0rem 2rem 0rem 1rem;
    margin-bottom: 1rem;
  }

  .s7P2RightsideColumn {
    padding: 0rem 2rem 0rem 1rem;
    margin-bottom: 1rem;
  }

  .DW4Bg {
    background-image: url("../../../public/images/DWS2MBBG.svg");
  }

  .BAS4PopUp {
    padding: 4rem 0 !important;
  }
  .BABgS5 {
    background-image: url("../../../public/images/BAS5BGMb1.svg");
  }
  .BAS4Bg {
    background-image: url("../../../public/images/BAS5bgMb.svg");
  }
  .BABgS3 {
    background-image: url("../../../public/images/BAS4BgMb.svg");
  }
  .S4Para {
    padding: 1rem 0rem 0.5rem 0;
  }

  .s5PopUpBtnP3 {
    padding: 4rem 0rem;
  }

  .SAS4Bg {
    background-image: url("../../../public/images/SAMbS4.svg");
  }

  .s7P2RightsideColumn img {
    display: block;
  }
  .s7P2LeftsideColumn img {
    display: block;
  }

  .s7P2RightsideColumn {
    padding: 0rem 2rem 0rem 2rem;
    margin-bottom: 0rem;
  }
  .s7P2LeftsideColumn {
    padding: 0rem 2rem 0rem 2rem;
    margin-bottom: 2rem;
  }
  .PAS7Bg {
    background-image: url("../../../public/images/cards section.svg");
  }

  .S4Para {
    font-size: 16px;
    line-height: 19px;
  }
  .btnLarge {
    width: 214px;
    height: 47px;
  }

  .S4StartP {
    padding-top: 3rem;
  }

  .S1PATopPadding {
    padding-top: 5rem;
  }

  .S3Para {
    padding: 0.5rem 2rem 1rem 0rem;
    line-height: normal;
  }
  .prMb {
    padding-left: 0 !important;
  }
  .pRemovMb {
    padding: 0 !important;
  }
  .PMB1rem {
    padding: 0rem !important;
  }

  .textCenterMb {
    text-align: center !important;
  }
  .ftrMbIcon {
    display: flex !important;
    justify-content: space-between !important;
    padding-bottom: 1rem;
  }
  .kingdomTxt {
    display: none !important;
  }
  .ftrLogo {
    margin-top: 0rem !important;
    margin-bottom: 4rem !important;
  }
  .ftrSubTitle {
    font-size: 12px !important;
    line-height: 18px !important;
    margin-bottom: 1rem !important;
  }
  .bottomTxt p {
    padding-bottom: 10px !important;
    margin-top: 20px !important;
  }
  .bottomMoneyDiv h6 {
    font-size: 12px !important;
  }
  .bottomMoneyDiv a {
    font-size: 12px !important;
  }
  .bottomMoneyDiv span {
    font-size: 10px !important;
  }
  .footerMain {
    padding-bottom: 30px !important;
    padding-top: 56px !important;
  }
  .FotterBg {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
    background-image: url("../../../public/images/ftrMbBg.svg") !important;
  }
  .ftrLinkShow {
    display: flex !important;
  }
  .ftrLinkShow div {
    padding: 0 !important;
  }
  .secondStephr {
    padding: 0 !important;
  }
  /* .ftrLinkHide {
    display: none !important;
  } */
  .s10Text {
    padding-left: 2rem;
    padding-right: 0;
    font-size: 16px;
    line-height: 19px;
  }
  .s10ImgsSize2 {
    width: 66px;
    height: 66px;
  }
  .s10ImgsSize1 {
    width: 55px;
    height: 55px;
  }
  .s10ImgsSize3 {
    width: 61px;
    height: 61px;
  }
  .s10ImgsSize4 {
    width: 63px;
    height: 63px;
  }
  .HPS8Ri {
    position: relative;
    top: -0rem;
  }
  .HPS8Ri img {
    padding: 0;
  }
  .btnShow {
    display: block;
  }
  .btnShow {
    display: none;
  }
  .HPS7Btn {
    width: 264px !important;
  }
  .reverseColumnS6 {
    display: flex !important;
    flex-direction: column-reverse !important;
  }
  .IndexS6Bg {
    background-image: url("../../../public/images/HPS6Bg.svg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: unset;
    display: flex;
    justify-content: center;
    /* margin-top: 4rem; */
  }
  .PAS5Bg {
    background-image: url("../../../public/images/PAS6Mb.svg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: unset;
    display: flex;
    justify-content: center;
    /* margin-top: 4rem; */
  }
  .s5PopUpDiv {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
  .hidePopUp {
    display: none;
  }
  .reverseColumn {
    flex-direction: column-reverse !important;
  }
  .homepageS3MainHeadingDiv h3 {
    font-size: 24px;
    line-height: 29px;
  }
  .homePageS4SubHeading {
    font-size: 16px;
    line-height: 19px;
    padding: 0;
  }
  .s4BottomHeading {
    font-size: 24px;
    line-height: 29px;
  }
  .s5Btn {
    width: 168px;
    height: 51px;
  }
  .s5Btntxt {
    font-size: 16px;
  }
  .hrSmHide {
    display: none !important;
  }
  .hrSmShow {
    display: block !important;
  }
  .btnHide {
    display: none;
  }
  .btnShow {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1rem 0;
    margin: auto;
  }
  .navbarContainer {
    height: auto;
    background-color: #ffffff !important;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    padding: 0;
  }
  .navbarLogo {
    position: relative;
    left: 1.5rem;
  }
  .navMb {
    display: block;
  }
  .navbar-collapse {
    background: #f2f9ff;
    padding: 1rem 1.5rem 1rem 1.5rem;
  }
  .navbar_titles {
    padding-left: 0 !important  ;
  }
  .homepageContainer {
    padding-left: 0.7rem !important;
    padding-right: 0.7rem !important;
    padding-bottom: 2.5rem;
  }
  .Ps4GradientHeading {
    padding: 1rem 0 0rem 1rem;
    font-size: 24px;
    line-height: normal;
  }
  .homepageS1MainHeadingDiv h3 {
    font-size: 24px;
    line-height: normal;
    /* padding: 1rem; */
  }
  .S1Para {
    padding-right: 0 !important;
    text-align: left;
  }
  .s4BannerP4s3 {
    padding: 3.5rem 0 0 0.9rem !important;
  }

  .homePageS1SubHeading {
    font-size: 16px !important;
    line-height: 19px !important;
    padding-bottom: 0;
    padding: 1rem 1.2rem;
    text-align: center;
  }
  .s2HeadingBold {
    font-size: 24px;
    line-height: 29px;
    text-align: left;
  }
  .s2HeadingBold05 {
    font-size: 24px;
    line-height: 29px;
    text-align: left;
  }
  .homePageS2SubHeading {
    font-size: 16px;
    line-height: 19px;
    padding-bottom: 0;
    padding: 1rem 0rem 0rem 0rem;
  }
  .S1Heading {
    text-align: center !important;
  }
  .homePageS3SubHeading {
    font-size: 16px;
    line-height: normal;
    padding-right: 1rem;
  }
  .HMS3Bg {
    background-image: none;
    padding-top: 3.5rem;
    padding-bottom: 3.5rem;
    gap: 2rem;
  }
  .HMS3BgMb {
    background-image: url("../../../public/images/wemb.svg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: unset;
    padding-top: 2rem;
    padding-bottom: 1rem;
  }
  .S7BottomColumns {
    gap: 2.25rem;
    padding-top: 2rem;
  }
  .IndexS7Bg {
    background-image: url("../../../public/images/HMS7Bg.svg");
  }
  .columnBorder {
    border-right: none;
  }
  .columnBorderMb {
    border-left: 2px solid rgba(255, 255, 255, 1);
    margin-top: 5.3rem;
  }
  .S3BottomHeading {
    font-size: 24px;
    line-height: normal;
  }
  .s4BottomSubHeading {
    font-size: 16px;
    line-height: normal;
    font-weight: 300;
    padding-right: 0rem;
  }
  .IndexS4Bg {
    background-image: url("../../../public/images/mbs4Hm.svg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: unset;
  }
  .s4Banner {
    padding-top: 1rem !important;
  }
  .imgShow {
    display: block !important;
  }
  .imgHide {
    display: none !important;
  }
  .PSS6RImg img {
    padding-left: 0 !important;
  }
  .pr_rem4 {
    padding-right: 0 !important;
  }
  .p7S1Img {
    padding: 3.5rem 0 1.5rem 0;
  }
  .p7s2BtnDiv {
    padding-right: 0 !important;
  }
  .rightSideHrS6P4 {
    width: 100%;
  }
  .WHPS3bottomDiv2 a,
  .WHPS3bottomDiv1 a {
    padding: 0.75rem;
    width: 75%;
    font-size: 14px;
    font-weight: 600;
    line-height: normal;
  }
  .cardUpperRow h1 {
    font-size: 16px;
    line-height: normal;
  }
  .upgradeCardHeading {
    font-size: 18px;
  }
  .careerAccordionButton{
    padding: 0 !important;
    --bs-accordion-btn-icon-width: .88rem !important;
  }
  .careerAccordionButton::after {
    top: -20px !important;
}
  .accordionHeader{
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .careerAccordionButton:not(.collapsed)::after {
    top: -24px !important;
}
.p9s1HeadingBold {
  font-size: 20px;
  line-height: normal;
}
.qrImg {
  height: 120px;
}
}

@media only screen and (max-width: 400px) {
  .new_error_text {
    color: #F0263C;
    font-family: 'Inter';
    font-size: 8px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

}

/* @media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min-device-pixel-ratio: 2) {
  .logo-image1 {
      width: 100px; 
    
  }
} */
