@font-face {
  font-family: "sf-ui-display";
  src: url("../fonts/Inter-VariableFont_slnt\,wght.ttf");
  font-style: normal;
}
a {
  text-decoration: none !important;
}
body {
  transition: transform 0.3s ease-in-out;
  /* overflow-x: hidden !important;
    overflow-y: scroll !important; */
}
body::-webkit-scrollbar {
  height: 0;
  width: 0;
}
.bH1{
  color: var(--Black, #000);
  font-family: 'sf-ui-display';
  font-size: 47px;
  font-style: normal;
  font-weight: 600;
  line-height: 51.7px; /* 110% */
}
.bSubH1{
  font-family: 'sf-ui-display';
  color: #1A1A1A;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px; /* 100% */
}

.blogMainImg{
  height: auto;
  width: 100%;
  border-radius: 9px;
}
.blogMainImg10{
  max-height: 400px;
  width: 100%;
  border-radius: 9px;
}
.blogPara > h6{
  font-size: .8rem !important;
  font-weight: 300 !important;
  line-height: 1.2rem;
}
.blogMainImg01{
  height: 240px;
  width: 100%;
  border-radius: 9px;
}
.mainBlogDiv{
  width: 100%;
  display: flex;
}
.blogMainDiv{
  width: 60%;
  padding: 1rem;
}
.blogSubMainDiv{
  width: 40%;
  padding: 1rem;
}
.blogSubMainImg{
  height: auto;
  width: 50%;
  border-radius: 20px;
}
.blogDate{
  color: var(--extra, #767676);
  /* Text sm/Semibold */
  font-family: 'sf-ui-display';
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
}
.blogHeading{
  color: var(--secondary-dark, #00051A);
  font-family: 'sf-ui-display';
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px; /* 133.333% */
  white-space:nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.blogSubHeading{
  color: var(--secondary-dark, #00051A);
  font-family: 'sf-ui-display';
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px; /* 133.333% */
  white-space:nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.blogOtherDetailDIv{
  max-height: 100%;
  overflow: hidden;
}
.blogPara{
  color: var(--secondary-dark, #00051A);
  font-family: 'sf-ui-display';
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px; /* 133.333% */
}
.blogPara05{
  color: var(--secondary-dark, #00051A);
  font-family: 'sf-ui-display';
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px; /* 133.333% */
  height: 50px;
  overflow: hidden;
}
.blogSubPara{
  color: var(--secondary-dark, #00051A);
  font-family: 'sf-ui-display';
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px; /* 133.333% */
  height: 72px;
  white-space:inherit;
  overflow: hidden;
  text-overflow: ellipsis;
}
.blogBtn{
  display: flex;
  padding: 6px 26px;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  background: var(--secondary-dark, #00051A);
  color: var(--accent-yellow, #FFE200);
  text-align: center;

  /* Text sm/Medium */
  font-family: 'sf-ui-display';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  max-width: 140px;
  cursor: pointer;
}
.simpleBtn{
  color: var(--Black, #000);
  font-family: 'sf-ui-display';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
  text-decoration: none;
}
.simpleBtn:hover{
  color: var(--Black, #000);
  cursor: pointer;
}
.blogUpperBtnRow {
  Width : max-content ;
  Height : 37px ;
  position: relative;
  /* display: inline-block; */
  padding: 0;
  font-size: 16px;
  cursor: pointer;
  background: linear-gradient(to bottom right, rgba(255, 226, 0, 1), rgba(38, 69, 224, 1), rgba(7, 30, 237, 1));
  z-index: 1;
  overflow: hidden;
  border-radius: 78px;
}

.blogUpperBtnRow button {
  Width : max-content ;
  Height : 37px ;
  border: 1px solid transparent;
  border-image: linear-gradient(to bottom right, rgba(255, 226, 0, 1), rgba(38, 69, 224, 1), rgba(7, 30, 237, 1));
  border-image-slice: 1;
  border-radius: 78px;
  background-clip: padding-box, border-box;
  transition: all 0.3s ease;
  background-color: #FFFFFF;
}
.blogUpperBtnRow button .gradient-text10 {
  background-image: linear-gradient(to bottom right, rgba(255, 226, 0, 1), rgba(38, 69, 224, 1), rgba(7, 30, 237, 1));
  -webkit-background-clip: text; /* Apply gradient to text */
  color: transparent; /* Hide the original text */
  font-family: 'sf-ui-display';
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  padding: 2px 7px;
}
.blogUpperBtnRow1 {
  Width : 120px ;
  Height : 37px ;
  position: relative;
  /* display: inline-block; */
  padding: 0;
  font-size: 16px;
  cursor: pointer;
  background: linear-gradient(to bottom right, rgba(255, 226, 0, 1), rgba(38, 69, 224, 1), rgba(7, 30, 237, 1));
  z-index: 1;
  overflow: hidden;
  border-radius: 7px;
}

.blogUpperBtnRow1 button {
  Width : 120px ;
  Height : 37px ;
  border: 2px solid transparent;
  border-image: linear-gradient(to bottom right, rgba(255, 226, 0, 1), rgba(38, 69, 224, 1), rgba(7, 30, 237, 1));
  border-image-slice: 1;
  border-radius: 7px;
  background-clip: padding-box, border-box;
  transition: all 0.3s ease;
  background-color: #FFFFFF;
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap:4px
}
.blogUpperBtnRow1 button .gradient-text {
  background-image: linear-gradient(to bottom right, rgba(255, 226, 0, 1), rgba(38, 69, 224, 1), rgba(7, 30, 237, 1));
  -webkit-background-clip: text; /* Apply gradient to text */
  color: transparent; /* Hide the original text */
  font-family: 'sf-ui-display';
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
}
.navbarLogo1 {
  width: 100px;
  height: auto;
  max-width: 100%;
  position: relative;
}
.HPS1Imfg {
  position: relative;
  left: 7%;
}
.divBtnMedium {
  width: 175px;
  height: 50px;
  position: relative;
  /* display: inline-block; */
  padding: 0;
  font-size: 16px;
  cursor: pointer;
  background: linear-gradient(
    to bottom right,
    rgba(255, 226, 0, 1),
    rgba(38, 69, 224, 1),
    rgba(7, 30, 237, 1)
  );
  z-index: 1;
  overflow: hidden;
  border-radius: 10px;
}
.btnLarge {
  width: 220px;
  height: 50px;
}
.brnLargeText {
  font-size: 24px;
}
.PAS3Padding {
  padding: 0 5rem 0 7rem;
}
.divBtnMedium .btnMedium {
  width: 175px;
  height: 50px;
  border: 2px solid transparent;
  border-image: linear-gradient(
    to bottom right,
    rgba(255, 226, 0, 1),
    rgba(38, 69, 224, 1),
    rgba(7, 30, 237, 1)
  );
  border-image-slice: 1;
  border-radius: 10px;
  background-clip: padding-box, border-box;
  transition: all 0.3s ease;
  background-color: #ffffff;
}
.btnMediumText {
  font-size: 24px;
  background-image: linear-gradient(
    to bottom right,
    rgba(255, 226, 0, 1),
    rgba(38, 69, 224, 1),
    rgba(7, 30, 237, 1)
  );
  -webkit-background-clip: text; /* Apply gradient to text */
  color: transparent; /* Hide the original text */
  font-family: "sf-ui-display";
  font-size: 18px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: center;
}

.homepageContainer {
  padding-left: 7.75rem !important;
  padding-right: 5.75rem !important;
  padding-bottom: 6rem;
}
.homepageContainerS3 {
  padding-left: 7.75rem !important;
  padding-right: 7.75rem !important;
  padding-bottom: 6rem;
}
.paraWidth{
  max-width:90%;
}
.paraWidth01{
  max-width:110%;
}
.homepageContainerP2 {
  padding-left: 7.75rem !important;
  padding-right: 4.75rem !important;
  padding-bottom: 6rem;
}
.paddingS4HpParaRight {
  padding-right: 7rem;
}
.manageParaHeight{
  min-height: 130px;
}
.paddingS4HpParaLeft {
  padding-right: 10rem;
}

.homepageContainerP3 {
  padding-left: 7.75rem !important;
  padding-right: 3.75rem !important;
  padding-bottom: 6rem;
}

.heading {
  font-family: "sf-ui-display";
  color: aqua;
}

.removeStyleA {
  color: #00051a;
  text-decoration: none;
}

.imgResponsive {
  max-width: 100%;
}
.imgResponsivesWidth {
  max-width: 80%;
}
.S1PATopPadding {
  padding-top: 10rem;
}

.S1PATopPaddingP3 {
  padding-top: 15rem;
}
.S1PATopPaddingP2 {
  padding-top: 4rem;
}
.S1Heading {
  font-family: "sf-ui-display";
  font-size: 40px;
  font-weight: 500;
  line-height: 48px;
  letter-spacing: 0em;
  text-align: left;
  color: #000000;
}

.p7s3Heading {
  font-family: "sf-ui-display";
  font-size: 24px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
  color: #000000;
  margin-top: 0.5rem;
}
.securityCard{
  width: 100%;
  padding: 1.25rem .75rem;
  background: #FAFAFA;
}
.securityCard .iconImg{
  width: 20px;
  height: 20px;
}
.securityCard h1{
  font-family: "sf-ui-display";
  font-size: 21.6px;
  font-weight: 700;
  line-height: 25.92px;
  letter-spacing: -0.5831999778747559px;
  text-align: left;
  color:#2645E0;
}
.securityCard p{
  font-family: "sf-ui-display";
  font-size: 14.4px;
  font-weight: 400;
  line-height: 19px;
  text-align: left;
  color: #00051A;
}
.sidebarUpperSection{
  border-bottom: 1px solid #D9D9D980 !important;
  min-width: 175px;
}
.img-rotate{
  transform: rotate(180deg);
}
.delay-effect{
  transition: ease-in-out all .4s;
}
.contactSupportBtn{
  background: linear-gradient(117.26deg, #FFE200 2.71%, #2645E0 39.73%, #071EED 76.47%);
  gap: 0px;
  border-radius: 10px ;
  opacity: 0px;  
  border: 0;
  padding: .5rem 1.4rem;
  display: flex;
  align-items: center;
}
.contactSupportBtn span{
  color: white;
  font-family: "sf-ui-display";
  font-size: 14px;
  font-weight: 400;
  line-height: 19.36px;
  text-align: left;
}

.securityImg{
  position: relative;
  bottom: 90px;
}

.S1SubHeading {
  font-family: "sf-ui-display";
  font-size: 40px;
  font-weight: 300;
  line-height: 48px;
  letter-spacing: 0em;
  text-align: left;
  color: #000000;
}
.S1SubHeading05 {
  font-family: "sf-ui-display";
  font-size: 60px;
  font-weight: 300;
  line-height: normal;
  letter-spacing: 0em;
  text-align: left;
  color: #000000;
}

.S1SubHeadingAU {
  font-family: "sf-ui-display";
  font-size: 40px;
  font-weight: 500;
  line-height: 48px;
  letter-spacing: 0em;
  text-align: left;
  color: #000000;
}

.S1Para {
  font-family: "sf-ui-display";
  font-size: 20px;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #000000;
  padding: 2rem 9rem 2.5rem 0;
}
.CdS1SubHeading {
  font-family: "sf-ui-display";
  font-size: 40px;
  font-weight: 300;
  line-height: 48px;
  letter-spacing: 0em;
  text-align: left !important;
}
.rightSideS2ImgP2 {
  width: 765px;
  /* height: 574px; */
}

.rightSideS2ImgP6 {
  width: 747px;
  height: 630px;
}

.leftSideS2ImgP4 {
  width: 692px;
  /* height: 610px; */
}

.s3InsidePadding {
  padding: 3rem 0 0 0;
}

.S3Heading {
  font-family: "sf-ui-display";
  font-size: 24px;
  font-weight: 600;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: left;
  color: #2645e0;
}

.S3Para {
  font-family: "sf-ui-display";
  font-size: 16px;
  font-weight: 300;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  color: #000000;
  padding: 0.5rem 5rem 4rem 0;
}
.p14S1Para {
  font-family: "sf-ui-display";
  font-size: 24px;
  font-weight: 300;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: left;
  color: #000000;
  padding-right: 3rem;
}
.p14S1ParaBold {
  font-family: "sf-ui-display";
  font-size: 18px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: #000000;
}
.sidebarLinkCareers a{
  display: block;
  color: var(--Black, #000);
  font-family: "sf-ui-display";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}
.sidebarLinkCareers a:hover{
  cursor: pointer;
  color: var(--Black, #2645E0);
}
.sidebarLinkCareers a.active{
  display: block;
  color: var(--Black, #2645E0);
  font-family: "sf-ui-display";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}
.sidebarLinkCareers p{
  font-family: "sf-ui-display";
  color: #000;
  text-align: left;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.S4StartP {
  padding-top: 5rem;
}
.bSubH101{
  color: #183B56;
  font-family: "sf-ui-display";
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  line-height: 48px; /* 133.333% */
  letter-spacing: 0.2px;
}
.allBlogCatBtn{
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.bSubH102{
  color: #5A7184;
  font-family: "sf-ui-display";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px; /* 177.778% */
}
.blogIngoDiv{
  display: inline-flex;
  padding: 25px 53.11px 49px 25px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 9px;
  background: #E8F2EE;
  width: 100%;
}
.blogInfoHeading{
  color: var(--Black, #000);
  font-family: "sf-ui-display";
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 47.73px; /* 149.156% */
}
.blogInfoHeading01{
  color: var(--Black, #000);
  font-family: "sf-ui-display";
  font-size: 12.5px;
  font-style: normal;
  font-weight: 600;
  line-height: 47.73px; /* 149.156% */
}
.blogInfoHeading02{
  color: var(--Black, #000);
  font-family: "sf-ui-display";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 27.73px; /* 149.156% */
  height: 80px;
  overflow: hidden;
  margin-bottom: 1px !important;
}
.blogInfoHeading03{
  color: var(--Black, #000);
  font-family: "sf-ui-display";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 27.73px; /* 149.156% */
  height: 80px;
  overflow: hidden;
  margin-bottom: 1px !important;
}
.blogMintDetails{
  color: #0C0C0C;
  font-family: "sf-ui-display";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 25.5px; /* 159.375% */
  text-transform: uppercase;
}

.blogHeadnigDetails{
  font-family: "sf-ui-display";
  color: var(--Black, #000);
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: 51.7px; /* 129.25% */
}
.S4LeftSideImg {
  width: 722px;
  /* height: 586px; */
}
.S4Para {
  font-family: "sf-ui-display";
  font-size: 20px;
  font-weight: 300;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
  color: #000000;
  padding: 2rem 9rem 2.5rem 0;
}
.p6S4Para {
  font-family: "sf-ui-display";
  font-size: 32px;
  font-weight: 300;
  line-height: 38px;
  letter-spacing: 0em;
  text-align: left;
  color: #000000;
  padding: 2rem 9rem 0.5rem 0;
}
.s5BtnDiv {
  margin-top: 2rem !important;
}

.S5Para {
  font-family: "sf-ui-display";
  font-size: 20px;
  font-weight: 300;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
  color: #000000;
  padding: 4.5rem 9rem 2.5rem 0;
}

.S5ParaP4 {
  font-family: "sf-ui-display";
  font-size: 30px;
  font-weight: 300;
  line-height: 38px;
  letter-spacing: 0em;
  text-align: left;
  color: #000000;
  padding: 4.5rem 3rem 2.5rem 0;
}

.s5BannerBg {
  padding: 0 5.1rem 0 1rem;
}
.S5PopUpBtnDivP2 {
  padding: 20rem 0 9rem 0;
}
.S5PopUpBtnDivP3 {
  padding: 7rem 0 22rem 0;
}

.BAS5PopUpBtnDiv {
  padding-top: 5rem;
  padding-bottom: 8rem;
}
.AUp6s5PopUpDiv {
  padding: 25rem 0;
}
.s5PopUpDiv1 {
  padding: 15rem 0;
}
.s5PopUpBtnP2 {
  width: 686px;
  height: 293px;
  border-radius: 43px;
  border: 2px solid rgba(0, 0, 0, 1);
}
.CWS5PopUpBtnDiv {
  padding-top: 4rem;
  padding-bottom: 10rem;
}
.manageImmg01{
  padding-right: 7.5rem !important;
}
.s5PopUpBtnP3 {
  width: 586px;
  height: 293px;
  border-radius: 43px;
  border: 2px solid rgba(0, 0, 0, 1);
}
.s5PopUpBtnP10 {
  width: 100%;
  border-radius: 43px;
  border: 2px solid rgba(0, 0, 0, 1);
  padding: 5rem;
}
.s6P2heading {
  padding-top: 4rem;
}

.s7P2RightsideColumn {
  padding: 0rem 3rem 0rem 14rem;
  margin-bottom: 6rem;
}
.s7P2RightsideColumnp3 {
  padding: 0 4.5rem;
}

.s7P2LeftsideColumn {
  padding: 0rem 17rem 0rem 3rem;
  margin-bottom: 6rem;
}
.s7P2LeftsideColumnP4s5 {
  padding: 0rem 14rem 0rem 3rem;
  margin-bottom: 6rem;
}
.s7P2RightsideColumnImg {
  padding: 0rem 0rem 0rem 14rem;
  margin-bottom: 1rem;
}
.s7P2RightsideColumnImgP3 {
  padding: 0 5rem;
  margin-bottom: 1rem;
}

.s7P2LeftsideColumnImg {
  padding: 0rem 17rem 0rem 3rem;
  margin-bottom: 1rem;
}

.S3BottomCenterHeading {
  font-family: "sf-ui-display";
  font-size: 20px;
  font-weight: 300;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: center;
  color: rgba(255, 255, 255, 1);
}
.DWS1HeadingBold {
  font-family: "sf-ui-display";
  font-size: 40px;
  font-weight: 500;
  line-height: 48px;
  letter-spacing: 0em;
  text-align: left;
}
.DWS1Heading {
  font-family: "sf-ui-display";
  font-size: 40px;
  font-weight: 300;
  line-height: 48px;
  letter-spacing: 0em;
  text-align: left;
}
.DWS1Img {
  position: relative;
  top: -3rem;
}

.rightSideHrS6P4 {
  width: 677px;
  height: 538px;
}
.rightSideHrS6P5 {
  width: 814px;
  height: 732px;
}
.p5S7Heading {
  font-family: "sf-ui-display";
  font-size: 24px;
  font-weight: 300;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: center;
  color: #ffffff;
  padding-top: 4rem;
}
.p5S7Heading01 {
  font-family: "sf-ui-display";
  font-size: 40px;
  font-weight: 300;
  line-height: normal;
  letter-spacing: 0em;
  text-align: center;
  color: #ffffff;
  padding-top: 4rem;
}
.aboutUSNewHeading{
  font-family: "sf-ui-display";
  font-size: 40px;
  font-weight: 500;
  line-height: 48px;
  letter-spacing: 0em;
  text-align: center;
  color: #FFFFFF;
}
.AUnewPara{
  font-family: "sf-ui-display";
  font-size: 20px;
  font-weight: 300;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: center;
  color: #FFFFFF;
  padding: 0 2.5rem;
}
.p5S7HeadingB {
  font-family: "sf-ui-display";
  font-size: 24px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: center;
  color: #ffffff;
  padding-top: 4rem;
}
.p5S7HeadingBold {
  font-family: "sf-ui-display";
  font-size: 40px;
  font-weight: 600;
  line-height: 48px;
  letter-spacing: 0em;
  text-align: center;
  color: #ffffff;
  padding-top: 7rem;
}
.p10S4Heading {
  font-family: "sf-ui-display";
  font-size: 40px;
  font-weight: 300;
  line-height: 48px;
  letter-spacing: 0em;
  text-align: center;
  color: #00051a;
  /* padding-top: 7rem; */
}
.p7s2Heading {
  font-family: "sf-ui-display";
  font-size: 40px;
  font-weight: 300;
  line-height: 48px;
  letter-spacing: 0em;
  text-align: right;
  color: #00051a;
  /* padding-right: 4rem; */
}
.s4BannerP4s3 {
  padding: 3.5rem 0 0 0 !important;
}
.s4BannerP6s3 {
  padding: 2.5rem 0 0 0 !important;
}
.p6S7Heading {
  font-family: "sf-ui-display";
  font-size: 24px;
  font-weight: 300;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: center;
  color: #00051a;
}
.p7s2Para {
  font-family: "sf-ui-display";
  font-size: 20px;
  font-weight: 300;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
  color: #00051a;
  /* padding:0 5rem 0 0rem; */
}
.takeControlP {
  color: var(--Background, #f2f9ff);
  font-family: "sf-ui-display";
  font-size: 40px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  padding-bottom: 102px;
  margin-bottom: 0;
}
.p10s4Para {
  font-family: "sf-ui-display";
  font-size: 20px;
  font-weight: 300;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: center;
  color: #00051a;
  /* padding:0 5rem 0 0rem; */
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
}
.p7s2BtnDiv {
  padding-right: 4.5rem;
}

.p6s6columnBorder {
  border-right: 3px solid #00051a;
}
.p6S6BottomHeading {
  font-family: "sf-ui-display";
  font-size: 24px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
  color: #00051a;
}
.p12S4BottomHeading {
  font-family: "sf-ui-display";
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #00051a;
  padding: 4rem 0rem 1rem 0;
}

.p12ParaHeading {
  font-family: "sf-ui-display";
  font-size: 24px;
  font-weight: 500;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: left;
  color: #00051a;
  padding: .75rem;
}
.p12Para {
  font-family: "sf-ui-display";
  font-size: 20px;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #00051a;
  padding: 0 0rem .5rem 0;
}

.p6s4BottomSubHeading {
  font-family: "sf-ui-display";
  font-size: 16px;
  font-weight: 300;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #00051a;
  min-height: 80px;
}
.p6s5PopUpDiv {
  padding-top: 1rem;
  padding-bottom: 30rem;
}
.HPs5PopUpDiv {
    padding-top: 8rem;
    padding-bottom: 22rem;
}
.mainContainerssssss{
    overflow: hidden;
}
  .p6-gradient-text {
    color: #FFFFFF; /* Hide the original text */
    font-family: 'sf-ui-display';
    font-size: 36px;
    font-weight: 500;
    line-height: 42px;
    letter-spacing: 0em;
    text-align: center;
  }

  .p6-sub-gradient-text {
    color: #FFFFFF; /* Hide the original text */
    font-family: 'sf-ui-display';
    font-size: 24px;
    font-weight: 300;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: center;
    
  }
.p6s31LastCol{
    padding: 0 5.2rem 0 0;
}
.p7S1Img{
    padding: 8.5rem 0 4.5rem 0;
}
.p7S3Img{
    margin: 4rem 0;
    width: 350px;
}

  


  .cardActiveBg {
    Width : 100% ;
    Height : auto ;
    position: relative;
    display: inline-block;
    padding: 0;
    font-size: 16px;
    cursor: pointer;
    background: linear-gradient(to bottom right, rgba(38, 69, 224, 1), rgba(7, 30, 237, 1),rgba(255, 226, 0, 1));
    z-index: 1;
    overflow: hidden;
    border-radius: 18px;
  }
  
  .cardActivecover {
    Width : 100% ;
    Height : auto ;
    border: 3px solid transparent;
    border-image: linear-gradient(to bottom right, rgba(38, 69, 224, 1), rgba(7, 30, 237, 1),rgba(255, 226, 0, 1));
    border-image-slice: 1;
    border-radius: 18px;
    background-clip: padding-box, border-box;
    transition: all 0.3s ease;
    background-color: #FFFFFF;
    padding-bottom: 2rem;
  }



  .rowActiveBg {
    Width : 100% ;
    Height : auto ;
    position: relative;
    display: inline-block;
    padding: 0;
    font-size: 16px;
    cursor: pointer;
    background: linear-gradient(to bottom right,rgba(255, 226, 0, 1), rgba(38, 69, 224, 1), rgba(7, 30, 237, 1));
    z-index: 1;
    overflow: hidden;
    border-radius: 18px;
  }
  
  .rowActivecover {
    Width : 100% ;
    Height : auto ;
    border: 3px solid transparent;
    border-image: linear-gradient(to bottom right,rgba(255, 226, 0, 1), rgba(38, 69, 224, 1), rgba(7, 30, 237, 1));
    border-image-slice: 1;
    border-radius: 18px;
    background-clip: padding-box, border-box;
    transition: all 0.3s ease;
    background-color: #FFFFFF;
    padding-bottom: 2rem;
  }
  .Ps4GradientHeading {
    font-size: 36px;
    background-image: linear-gradient(to bottom right, rgba(255, 226, 0, 1), rgba(38, 69, 224, 1), rgba(7, 30, 237, 1));
    -webkit-background-clip: text; /* Apply gradient to text */
    color: transparent; /* Hide the original text */
    font-family: 'sf-ui-display';
    font-weight: 500;
    line-height: 43px;
    text-align: left;
    padding: 4rem 0 0rem 1rem;
  }

  .Ps4GradientSubHeading {
    font-size: 20px;
    /* background-image: linear-gradient(to bottom right, rgba(255, 226, 0, 1), rgba(38, 69, 224, 1), rgba(7, 30, 237, 1)); */
    /* -webkit-background-clip: text; Apply gradient to text */
    /* color: transparent; Hide the original text */
    font-family: 'sf-ui-display';
    font-weight: 300;
    line-height: 24px;
    text-align: left;
    padding: 0.3rem 0 3rem 1rem;
  }

  .Ps4GradientRightHeading {
    font-size: 24px;
    background-image: linear-gradient(to bottom right, rgba(255, 226, 0, 1), rgba(38, 69, 224, 1), rgba(7, 30, 237, 1));
    -webkit-background-clip: text; /* Apply gradient to text */
    color: transparent; /* Hide the original text */
    font-family: 'sf-ui-display';
    font-weight: 500;
    line-height: 30px;
    text-align: right;
    padding: 0rem 2rem 3rem 0rem;
  }

  .Ps4GradientRightSubHeading {
    font-size: 20px;
    /* background-image: linear-gradient(to bottom right, rgba(255, 226, 0, 1), rgba(38, 69, 224, 1), rgba(7, 30, 237, 1)); */
    /* -webkit-background-clip: text; Apply gradient to text */
    /* color: transparent; Hide the original text */
    font-family: 'sf-ui-display';
    font-weight: 500;
    line-height: 24px;
    text-align: right;
    padding: 4rem 1rem 0rem 0rem; 
  }
  .Ps7s6optionTextBold {
    font-size: 20px;
    font-family: 'sf-ui-display';
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    color: #00051A;
    margin-bottom: 0 !important;
    /* padding: 0rem 2rem 3rem 0rem; */
  }
  .Ps7s6optionText {
    font-size: 20px;
    font-family: 'sf-ui-display';
    font-weight: 300;
    line-height: 24px;
    text-align: left;
    color: #00051A;
    margin-bottom: 0 !important;

    /* padding: 0rem 2rem 3rem 0rem; */
  }
.p7s5ImgTextDiv{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 2rem 5rem;
}
.p7S3CirlceImg{
    width: 22px;
    height: 22px;
    margin-top: 0.4rem;
    margin-right: 2rem ;
}
.p7s6Img{
    position: relative;
    bottom: -.3rem;
    z-index: -1;
}

.contactusupperTxt{
    font-size: 18px !important;
    font-family: 'sf-ui-display' !important;
    font-weight: 500 !important;
    line-height: 21px !important;
    letter-spacing: 0;
    text-align: left !important;
    color: #D9D9D9 !important;
}


.contactusLabels{
    font-size: 18px !important;
    font-family: 'sf-ui-display' !important;
    font-weight: 500 !important;
    line-height: 21px !important;
    letter-spacing: 0;
    text-align: left !important;
    color: #00051A !important;
}

.p8S1Heading{
    font-family: 'sf-ui-display';
    font-size: 40px;
    font-weight: 500;
    line-height: 48px;
    letter-spacing: 0em;
    text-align: left;
    color:#00051A;
    padding-top: .7rem;
}
.formInputs{
    border:1px solid #00051A !important ;
    font-size: 16px;
    font-weight: 300;
    line-height: 20px;
    font-family: 'sf-ui-display';
    padding: .6rem 1rem ;
    border-radius: 13.5px;
    color: #00051A;
    /* transition: 0.5s;
    -webkit-transition: all .2s ;;
    -moz-transition: all .2s ;;
    -ms-transition: all .2s ;;
    -o-transition: all .2s ;;
    transition: all .2s ; */
}
.changeNumberInput{
    border: 1px solid #00051A !important;
    font-size: 16px;
    font-weight: 300;
    line-height: 20px;
    font-family: 'sf-ui-display';
    padding: 1rem ;
    border-radius: 7.38px;
    color: #00051A;
}

.S5BtnDivAuth {
  padding-top: 3.5rem;
}
.formInputsM {
  font-size: 16px;
  font-weight: 400;
  font-family: "sf-ui-display";
  border-radius: 20px;
  color: #00051a;
  /* transition: 0.5s;
    -webkit-transition: all .2s ;;
    -moz-transition: all .2s ;;
    -ms-transition: all .2s ;;
    -o-transition: all .2s ;;
    transition: all .2s ; */
}
.formInputs:focus{
    border-color: #00051A !important;
    border:1px solid #00020a !important ;
    outline: 0 !important;
    box-shadow: 0 0 0 0 !important;
}
.changeNumberInput:focus{
    border-color: #00051A !important;
    border:1px solid #00020a !important ;
    outline: 0 !important;
    box-shadow: 0 0 0 0 !important;
}
.textinfo{
    font-size: 16px !important;
    font-family: 'sf-ui-display' !important;
    font-weight: 400 !important;
    line-height: 20px !important;
    letter-spacing: 0;
    text-align: left !important;
    color: #00051A !important;
}

.formError{
    font-size: 18px !important;
    font-family: 'sf-ui-display' !important;
    font-weight: 600 !important;
    line-height: 24px !important;
    letter-spacing: 0;
    text-align: left !important;
    color: #f10d0d !important;
    padding-left: 2rem;
    padding-bottom: 1rem;
}

.inputFiledError{
    font-size: 12px !important;
    font-family: 'sf-ui-display' !important;
    font-weight: 600 !important;
    line-height: 24px !important;
    letter-spacing: 0;
    text-align: left !important;
    color: red !important;
    /* padding-left: 2rem;

    padding-bottom: 1rem; */
}
.ftrSubTitle a{
  cursor: pointer;
}
.eyeIcon{
  width: 25px;
  height: auto;
  position: absolute;
  bottom: 14%;
  right: 2%;
  cursor: pointer;
}
.eyeIcons{
  width: 22px;
  height: auto;
  position: absolute;
  bottom: 10%;
  right: 3.5%;
  cursor: pointer;
}
.file-drop-container {
  max-width: 100%;
  padding: 2rem;
  border: 1px solid #00051a !important ;
  border-radius: 18px;
  cursor: pointer;
}
.file-drop-container p {
  font-size: 24px !important;
  font-family: "sf-ui-display" !important;
  font-weight: 300 !important;
  line-height: 24px !important;
  letter-spacing: 0;
  text-align: left !important;
  color: #00051a !important;
}

.p9s1Heading {
  font-size: 32px;
  font-family: "sf-ui-display";
  font-weight: 300;
  line-height: 38px;
  text-align: left;
  padding: 0rem 1rem 0rem 0.5rem;
}

.p9s1HeadingBold {
  font-size: 24px;
  font-family: "sf-ui-display";
  font-weight: 500;
  line-height: 38px;
  text-align: left;
  padding: 0rem 1rem 0rem 0rem;
}
.httpTxtDiv {
  overflow: hidden;
}
.httpTxtDiv p,
.httpTxtDiv span {
  word-break: break-all !important;
  margin-bottom: 3px !important;
}
.httpTxtDiv span {
  word-break: break-all !important;
}
.blogLeftSideMainImg {
  width: 100%;
  height: 450px;
  border-radius: 18px;
}
.blogLeftSideMainImg {
  width: 900px;
  height: 450px;
  max-width: 100%;
  border-radius: 18px;
}
.blogRightSideImgs {
  width: 100%;
  border-radius: 18px;
}
.userCirlcleImg {
  width: 38px;
  height: 38px;
  border-radius: 45px;
}
.textinfo span {
  font-weight: 500;
}
.blogSideHeading {
  font-family: "sf-ui-display";
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #000000;
  padding: 0.5rem 2rem 1rem 0rem;
  word-break: break-all;
}
.P9S1ParaBold {
  font-family: "sf-ui-display";
  font-size: 32px;
  font-weight: 600;
  line-height: 38px;
  letter-spacing: 0em;
  text-align: left;
  color: #000000;
  padding: 0.5rem 2rem 1rem 0rem;
  word-break: break-all;
}
.P9S1Para {
  font-family: "sf-ui-display";
  font-size: 32px;
  font-weight: 300;
  line-height: 38px;
  letter-spacing: 0em;
  text-align: left;
  color: #000000;
  padding: 0.5rem 1.5rem 1rem 0rem;
  word-break: break-all;
}
.P9S1ParaBlog {
  font-family: "sf-ui-display";
  font-size: 20px;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #000000;
  padding: 0.5rem 1.5rem 1rem 0rem;
  word-break: break-all;
}
.userInfo {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  justify-content: flex-start;
  align-items: center;
}
.accordion-item{
  border: none !important;
}
.accordionCustomColor {
  background-color: transparent !important;
  color: #000000 !important;
  border: none !important;
  font-size: 32px !important;
  font-weight: 600 !important;
  line-height: 38px !important;
  letter-spacing: 0em !important;
  text-align: left !important;
  padding: 0.2rem 0.2rem 0 0.2rem !important;
  outline: 0 !important;
}
.accordionCustomColorBody {
  background-color: transparent !important;
  color: #000000 !important;
  border: none !important;
  font-family: "sf-ui-display";
  /* font-size: 32px ;
  font-weight: 600 ;
  line-height: 38px ;
  letter-spacing: 0em ;
  text-align: left ; */
  padding: 0.5rem .3rem 0.5rem .3rem !important;
  outline: 0 !important;

}
.accordionCustomColor:focus {
  border: #e8f2ee !important;
  box-shadow: none;
  font-size: 32px !important;
  font-weight: 600 !important;
  line-height: 38px !important;
  letter-spacing: 0em !important;
  text-align: left !important;
}
.exHeading{
  color: var(--Primary-Blue, #2645E0);
  font-family: "sf-ui-display";
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
  line-height: 48px; /* 100.986% */
  letter-spacing: -1.2px;
  text-transform: uppercase;
}
.exSubHeading{
  color: #00051A;
  font-family: "sf-ui-display";
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px; /* 123.416% */
  letter-spacing: -0.24px;
}
.exPara{
  color: #191C1F;
  font-family: "sf-ui-display";
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px; /* 139.891% */
  max-width: 80%;
}
.exImgbottomImg{
  position: relative;
  right: -1rem;
  bottom: 7rem;
  overflow: hidden;
}
.exImgbottomImg01{
  position: relative !important;
  right: -11.5rem !important;
  bottom: 7rem !important;
  overflow: hidden !important;
}
.exRightDiv{
  position: relative;
  display: flex;
  justify-content: flex-end;
}
.exRightDiv01{
  position: relative;
  display: flex;
  justify-content: flex-end;
}
.exCard{
  padding: 1.35rem 1rem;
  width: 375px;
  height: 367px;
  flex-shrink: 0;
  border-radius: 22px;
  background: rgba(255, 255, 255, 0.70);
  box-shadow: 0px 0px 8.25px 0px rgba(0, 0, 0, 0.12);
  backdrop-filter: blur(6.550000190734863px);
  position: absolute;
  transform: translate(-200px, 20px);
}
.exCard .detailRow{
  border-radius: 16px;
  background: var(--Background, #F2F9FF);
  display: inline-flex;
  padding: .5rem .75rem;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 10px;
}
.exCard p{ 
  color: #505A63;
  font-family: "sf-ui-display";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 150% */
  letter-spacing: 0.18px;
  margin-bottom: 0 !important;
}
.exCard .currencyLogo{ 
  height: 24px;
  min-width: 24px;
  max-width: 122.83px;
  flex-shrink: 0;
  align-self: stretch;
  border-radius: 50%;
}
.exCard .gradinatTxt{ 
  background-image: linear-gradient(to bottom right, rgba(255, 226, 0, 1), rgba(38, 69, 224, 1), rgba(7, 30, 237, 1));
  -webkit-background-clip: text; /* Apply gradient to text */
  color: transparent; /* Hide the original text */
  font-family: 'sf-ui-display';
  font-family: "sf-ui-display";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px; /* 175% */
  letter-spacing: -0.24px;
}
.gradinatHeadingTxt{ 
  background-image: linear-gradient(to bottom right, rgba(255, 226, 0, 1), rgba(38, 69, 224, 1), rgba(7, 30, 237, 1));
  -webkit-background-clip: text; /* Apply gradient to text */
  color: transparent; /* Hide the original text */
  font-family: 'sf-ui-display';
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
  line-height: 48px; /* 175% */
  letter-spacing: -0.24px;
  text-align: center;
  text-transform: uppercase;
}

.exCard01{
  padding: 1.35rem 1rem;
  width: 375px;
  height: 367px;
  flex-shrink: 0;
  border-radius: 20px;
  background: rgba(255, 255, 255, 0.70);
  box-shadow: 0px 0px 8.25px 0px rgba(0, 0, 0, 0.12);
  backdrop-filter: blur(12px);
  position: absolute;
  transform: translate(-200px, 40px);
}
.exCard01 .detailRow{
  border-radius: 16px;
  background: var(--Background, #F2F9FF);
  display: inline-flex;
  padding: .5rem .75rem;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 10px;
}
.exCard01 p{ 
  color: #505A63;
  font-family: "sf-ui-display";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 150% */
  letter-spacing: 0.18px;
  margin-bottom: 0 !important;
}
.exCard01 .currencyLogo{ 
  height: 24px;
  min-width: 24px;
  max-width: 122.83px;
  flex-shrink: 0;
  align-self: stretch;
  border-radius: 50%;
}
.exCard01 .gradinatTxt{ 
  background-image: linear-gradient(to bottom right, rgba(255, 226, 0, 1), rgba(38, 69, 224, 1), rgba(7, 30, 237, 1));
  -webkit-background-clip: text; /* Apply gradient to text */
  color: transparent; /* Hide the original text */
  font-family: 'sf-ui-display';
  font-family: "sf-ui-display";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px; /* 175% */
  letter-spacing: -0.24px;
}



.exSwap {
  position: relative;
  padding: .5rem;
  cursor: pointer;
}
.exInput {
  padding: 0.75rem;
  border: none !important;
  width: 100% !important;
  font-family: "sf-ui-display" !important;
  color: #191C1F !important;
  text-align: right !important;
  font-size: 20px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 28px !important; /* 140% */
  position: relative;
  top: -.25rem;
  background-color: transparent !important;
}
.exInput:focus {
  outline: 0;
  box-shadow: none !important;
}
.exInput::placeholder {
  color: #191C1F !important;
}
.exInput01 {
  padding: 0.75rem;
  border: none !important;
  width: 100% !important;
  font-family: "sf-ui-display" !important;
  color: #191C1F !important;
  text-align: left !important;
  font-size: 20px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 28px !important; /* 140% */
  position: relative;
  top: -.25rem;
  background-color: transparent !important;
}
.exInput01:focus {
  outline: 0;
  box-shadow: none !important;
}
.exInput01::placeholder {
  color: #191C1F !important;
}
.exSwap .CSwapDropDown{
  top: 3rem !important;
}
.feeHeading{
  color: #505A63;
  font-family: "sf-ui-display" !important;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px; /* 150% */
  letter-spacing: 0.12px;
}

.feeTxt{
  color: #191C1F;
  text-align: right;
  font-family: "sf-ui-display" !important;
  font-size: 13.875px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 158.559% */
}
.convertBtn{
  display: block;
  width: 100%;
  border-radius: 16px;
  border: 0.75px solid #000;
  background: var(--secondary-dark, #00051A);
  color: var(--accent-yellow, #FFE200);
  -webkit-text-stroke-width: 0.75;
  -webkit-text-stroke-color: #000;
  font-family: "sf-ui-display" !important;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: center;
  padding: .75rem;
  margin-top:1.75rem;
  cursor: pointer;
}
.downLineCm{
  position: absolute;
  bottom: 15%;
}
.exHeadingH1{
  color: var(--secondary-dark, #00051A);
  font-family: "sf-ui-display" !important;
  font-size: 40px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  letter-spacing: -0.96px;
  text-align: center;
}
.exHeadingB{
  color: var(--secondary-dark, #00051A);
  text-align: center;
  font-family: "sf-ui-display" !important;
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.96px;
  text-align: center;
}

.exDetailsCardBg img{
  width: 34px;
}
.exDetailsCardBg h3{
  color: #191C1F;
  font-family: "sf-ui-display" !important;
  font-size: 22.688px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px; /* 123.416% */
  letter-spacing: -0.24px;
}
.exDetailsCardBg p{
  color: #505A63;
  font-family: "sf-ui-display" !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 171.429% */
  letter-spacing: -0.2px;
  height: 50px;
}
.exDetailsCardBg {
  Width : 100% ;
  Height : auto ;
  position: relative;
  display: inline-block;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  flex-shrink: 0;
  cursor: pointer;
  background: linear-gradient(to bottom right,rgba(255, 226, 0, 1), rgba(38, 69, 224, 1), rgba(7, 30, 237, 1));
  z-index: 1;
  overflow: hidden;
  border-radius: 20px;
}

.exDetailsCardCover {
  Width : 100% ;
  Height : auto ;
  border: 2px solid transparent;
  border-image: linear-gradient(to bottom right,rgba(255, 226, 0, 1), rgba(38, 69, 224, 1), rgba(7, 30, 237, 1));
  border-image-slice: 1;
  border-radius: 20px;
  background-clip: padding-box, border-box;
  transition: all 0.3s ease;
  background-color: #FFFFFF;
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  flex-shrink: 0;
}
.upperRow{
  display: flex;
  justify-content: space-between;
  padding: .25rem;
}
.upperRow p{
  color: #191C1F;
  text-align: center;
  font-family: "sf-ui-display" !important;
  font-size: 15.5px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 141.935% */
}
.upperRow img{
  width: 32px;
}
.row1{
   background: #F7F7F7;
   display: flex;
   justify-content: space-between;
   padding: .5rem .3rem;
}
.row2{
  background: #EDEFF2;
  display: flex;
  justify-content: space-between;
  padding: .5rem .3rem;
}
.row1 p , .row2 p{
  color: #191C1F;
  font-family: "sf-ui-display" !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; 
  letter-spacing: -0.16px;
  text-decoration-line: underline;
}
.row1 span , .row2 span{
  color: #505A63;
  text-align: right;
  font-family: "sf-ui-display" !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; 
  letter-spacing: -0.16px;
}
.exInfoTxt{
  color: #191C1F;
  font-family: "sf-ui-display" !important;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 150% */
  letter-spacing: 0.18px;
}
.exSectionMainHeading{
  color: #191C1F;
  font-family: "sf-ui-display" !important;
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
  line-height: 48px; /* 120% */
  letter-spacing: -0.48px;
  width: 80%;
}
.exSectionMainPara{
  color: #191C1F;
  font-family: "sf-ui-display" !important;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px; /* 138.753% */
  width: 80%;
}
.exbottomheading{
  color: #191C1F;
  font-family: "sf-ui-display" !important;
  font-size: 22.688px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px; /* 123.416% */
  letter-spacing: -0.24px;
}
.exBottomImg{
  width: 52px;
}
/* .exInputl:focus {
  color: var(--bs-body-color);
  background-color: var(--bs-body-bg);
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13,110,253,.25);
} */



.careerAccordionButton:not(.collapsed)::after {
  background-image: url("../images/Vector.svg") !important;
  position: relative;
  top: -45px;
  border: none !important;
  outline: 0 !important;

}
.careerAccordionButton::after {
  background-image: url("../images/Vector.svg") !important;
  position: relative;
  top: -45px;
  border: none !important;
  outline: 0 !important;

}
.careerAccordionButton01:not(.collapsed)::after {
  background-image: url("../images/Vector.svg") !important;
  position: relative;
  top: -45px;
  border: none !important;
  outline: 0 !important;
  rotate: 180deg !important;
}
.careerAccordionButton01::after {
  background-image: url("../images/Vector.svg") !important;
  position: relative;
  top: -45px;
  border: none !important;
  outline: 0 !important;
  rotate: 270deg !important;
}


.careerAccordionButton{
  border: none !important;
  outline: 0 !important;
  box-shadow: none !important;
}
 .careerAccordionButton01{
  border: none !important;
  outline: 0 !important;
  box-shadow: none !important;
}
.notifyHeading{
  color: #000;
  font-family: "sf-ui-display";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.notifyTxt{
  color: #767676;
  font-family: "sf-ui-display";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  opacity: 0.5;
}
.notifyTxt1{
  color: #000;
  font-family: "sf-ui-display";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.notifyTxt2{
  color: #767676;
  font-family: "sf-ui-display";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.language-selector {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  /* gap: 10px; */
}
.accordian_heading {
  font-family: "sf-ui-display";
  color: #000;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.accordian_heading01{
  font-family: "sf-ui-display";
  color: #000;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.sendMoneyRow{
  margin-top: 2rem !important;
}
.jobLocationB {
  font-family: "sf-ui-display";
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
}
.jobLocation {
  font-family: "sf-ui-display";
  font-size: 18px;
  font-weight: 300;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
}
.language-option {
  padding: 15px 10px 15px 2rem;
  width: 100%;
  background-color: transparent;
  /* border: 1px solid #ddd; */
  cursor: pointer;
  transition: background-color 0.3s ease;
  /* text-align: center; */
}

.language-option:hover {
  background-color: #f2f2f2;
  border-radius: 18px;
}

/* CSS for error state */
.formInputs.error {
  border: 1px solid red !important;
}

/* Authentication Style Start  */
/* .LoginMainDivBg{
    background-image:url('../images/login.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position:unset;
} */
.LoginMainDivBg {
  background-image: url("../../../public/images/new_login.png");
  background-position:bottom;
  background-repeat: no-repeat;
  background-size: cover;
  background-clip: border-box;
  height: 100vh;
  overflow: hidden;
  width: 50%;
}
.LoginMainDivBg001 {
  background-image: url("../../../public/images/Frame 6.png");
  background-position:top;
  background-repeat: no-repeat;
  background-size: cover;
  background-clip: border-box;
  height: 100vh;
  overflow: hidden;
  width: 50%;
}
.LoginMainDivBg002 {
  background-image: url("../../../public/images/new_sign_up.png");
  background-position:top;
  background-repeat: no-repeat;
  background-size: cover;
  background-clip: border-box;
  height: 100vh;
  overflow: hidden;
  width: 50%;
}


.new_login_main_div{
  padding: 3rem 4rem 3rem 4.5rem;
}
.loginTitle {
  font-family: "sf-ui-display";
  font-size: 24px !important;
  font-weight: 500 !important;
  line-height: 30px !important;
  letter-spacing: 0em !important;
  text-align: center !important;
  padding: 4rem 0 .5rem 0;
}
.SignInForm {
  max-width: 100%;
  width: 100%;
  padding: 0 4rem;
}
.newOtpHeading{
  color: var(--secondary-dark, #00051A);
  text-align: center;
  font-family: 'Inter';
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.newEmailTxt{
  color: var(--secondary-dark, #00051A);
  text-align: center;
  font-family: 'Inter';
  font-size: 15px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  width: 65%;
  margin: 0 auto;
}
.otpError{
  color: #DD4C4C;
text-align: center;
font-family: 'Inter';
font-size: 15px;
font-style: normal;
font-weight: 400;
line-height: normal;
}
.otpInputError{
  transition: ease-in-out .2s;
  border: 2px solid #DD4C4C !important;
}
.rememberMeText {
  font-family: "sf-ui-display";
  font-size: 14px !important;
  font-weight: 300 !important;
  line-height: 18px !important;
  letter-spacing: 0em !important;
  text-align: left !important;
  cursor: pointer;
}
.checkBoxDiv {
  padding: 0.3rem 0 0 2.1rem;
  cursor: pointer;
}
.authSubmitBtn {
  width: 100% !important;
}
.s5BtnLogin {
  width: 100%;
  height: 50px;
  border-radius: 18px;
  border: 1px;
  border: none;
  background: rgba(0, 0, 0, 1);
}
.s5Btn {
  width: 220px;
  height: 50px;
  border-radius: 18px;
  border: 1px;
  border: none;
  background: rgba(0, 0, 0, 1);
}
.BottomText {
  text-align: center !important;
  padding: 3rem 0 1rem 0;
  font-size: 16px !important;
  font-family: "sf-ui-display" !important;
  font-weight: 500 !important;
  line-height: 20px !important;
  letter-spacing: 0;
  color: #00051a;
  cursor: pointer;
}
#Password::placeholder {
  transform: translateY(3px);
}
.formInputs::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #696969;
}

.formInputs:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #696969;
}

.formInputs::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #696969;
}

.mainContainer {
  padding: 8.75rem 6.75rem 0rem 6.75rem;
}
.mainContainerSignups {
  padding: 8.75rem 6.75rem 0rem 6.75rem;
}
.authSubHeading {
  font-family: "sf-ui-display";
  font-size: 24px !important;
  font-weight: 300 !important;
  line-height: 30px !important;
  letter-spacing: 0em !important;
  text-align: left !important;
  color: #00051a;
}
.applyNowBtns {
  width: 152px;
  height: 50px;
  border-radius: 13.5px;
  background-color: #ffffff;
  border: 3px solid #ffffff;
  box-shadow: 2px 2px 5px #e4e4e4;
}
.applyNowBtns:hover {
  box-shadow: 4px 4px 6px #e4e4e4;
}
.registertypeCards {
  display: flex;
  max-width: 100%;
  width: 500px;
  border: 1px solid #fbfbfb;
  border-radius: 18px;
  background-color: #fbfbfb;
  padding: 2rem 0.3rem 2rem 2rem;
  box-shadow: 2px 2px 5px #ffffff;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
}
.registertypeCardsPara {
  font-family: "sf-ui-display";
  font-size: 16px !important;
  font-weight: 300 !important;
  line-height: 21px !important;
  letter-spacing: 0em !important;
  text-align: left !important;
  color: #00051a;
  padding: 2rem 0;
}
.registertypeCardsPara1 {
  font-family: "sf-ui-display";
  font-size: 16px !important;
  font-weight: 300 !important;
  line-height: 21px !important;
  letter-spacing: 0em !important;
  text-align: left !important;
  color: #00051a;
  padding: 2rem 0;
}
.upperImgRegister {
  max-height: 77px !important;
}
.registertypeCardsHeading {
  font-family: "sf-ui-display";
  font-size: 20px !important;
  font-weight: 500 !important;
  line-height: 24px !important;
  letter-spacing: 0em !important;
  text-align: left !important;
  color: #00051a;
}
.accountsTypeDiv {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}
.accTypesHeading {
  font-family: "sf-ui-display";
  font-size: 18px !important;
  font-weight: 500 !important;
  line-height: 22px !important;
  letter-spacing: 0em !important;
  text-align: left !important;
  color: #2645e0;
  padding: 2rem 0.5rem 0.5rem 1rem;
}

.accTypesHeading2 {
  font-family: "sf-ui-display";
  font-size: 18px !important;
  font-weight: 600 !important;
  line-height: 22px !important;
  letter-spacing: 0em !important;
  text-align: left !important;
  color: #2645e0;
  padding: 2rem 0.5rem 0.5rem 2rem;
}
.SignupHeadings {
  font-family: "sf-ui-display";
  font-size: 20px !important;
  font-weight: 500 !important;
  line-height: 24px !important;
  letter-spacing: 0em !important;
  text-align: left !important;
  color: #d9d9d9;
  padding: 5rem 0.5rem 0 1rem;
}
.customSelectOptions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  display: flex;
  padding: 14px 21px;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 11px;
  border: 1.5px solid #D1D1D1;
  font-family: 'Inter';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;  
  color:#00051A ;
  width: 100%;
}
.modalVerify{
    box-shadow: 0px 0px 8.25px 0px #0000001A !important;
    border-radius: 27.5px !important;
    outline: unset !important;
}
.modal{
    outline: unset !important;
    --bs-modal-border-width: unset !important;
}

/* Custom Dropdown Start  */
.options {
  cursor: pointer;
  width: 254px;
  height: 300px;
  border-radius: 15px;
  background-color: #ffffff;
  /* box-shadow: 1px 1px 8px #D6CBFF; */
  border: 1px solid #d6cbff;
  border-radius: 18px;
  transition: all 300ms;
  padding: 0.3rem 0.7rem;
  position: absolute;
  z-index: 2;
  overflow-y: auto;
  scrollbarWidth: 'none'; /* Firefox */
  msOverflowStyle: 'none';  /* Internet Explorer 10+ */
}
.options::-webkit-scrollbar {
  display: none;
}
.options.opened {
  overflow-y: hidden;
}

.option {
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px;
  transition: all 200ms;
}

.option:nth-child(1) {
  border-radius: 15px;
}

.option:hover {
  background-color: #00051a;
  color: #ffffff;
  border-radius: 18px;
}

.button {
  cursor: pointer;
  margin-top: 30px;
  width: 300px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(184, 255, 112);
  color: #212121;
  font-weight: 600;
  box-shadow: 2px 4px 8px rgba(45, 87, 5, 0.247);
}

/* Custom DropDown End  */

/* Custom Radio Button Start  */
.sq-radio {
  position: relative;
  padding: 0.25rem 0 0 2.5rem;
  margin-bottom: 0.5rem;
  margin-top: 2px;
  text-align: left;
}

/* Hide the browser's default radio button */
.sq-radio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #ffffff;
  /* padding: .5px; */
  border: 3px solid #080808;
  cursor: pointer;
}
/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Show the indicator (dot/circle) when checked */
.sq-radio input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.sq-radio .checkmark:after {
  top: 2px;
  left: 1.9px;
  width: 21px;
  height: 21px;
  border-radius: 50%;
  background-color: #080808;
}
/* Custom Radio Button End  */

/* All Bgs Images */
.aboutUsBg {
  background-image: url("../images/aboutusnewdesign.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: unset;
  padding-bottom: 3rem !important;
}
.aboutUsBgS6 {
  background-color: #fdfdfd;
}
.BABgS3 {
  background-image: url("../images/p4s3BannerBg.svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: unset;
  padding-bottom: 4rem !important;
}
.BABgS5 {
  background-image: url("../images/p4S5BannerImg.svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: unset;
  padding-bottom: 0 !important;
}
.BABgS7 {
  padding-bottom: 4rem !important;
}
.cardsS5 {
  padding-bottom: 5rem !important;
}
.contactUsBg {
  padding-bottom: 5rem !important;
}
.cryptoS5MainDiv {
  padding-bottom: 0 !important;
}
.CryptoS7Bg {
  background-image: url("../images/p4s3BannerBg.svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: unset;
  padding-bottom: 6rem !important;
}
.DWS3 {
  padding-bottom: 0 !important;
}
.IndexS4Bg {
  background-image: url("../images/Untitled (1).svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: unset;
}
.BAS4Bg {
  background-image: url("../../../public/images/image 17.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: unset;
  margin-top: 4rem;
}
.CS4Bg {
  background-image: url("../../../public/images/crypto4.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: unset;
  margin-top: 4rem;
}

.AUS4Bg {
  background-image: url("../../../public/images/Group 552.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: unset;
  margin-top: 4rem;
}

.DW4Bg {
  background-image: url("../../../public/images/indoor-photo.svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: unset;
  margin-top: 4rem;
}
.IndexS6Bg {
  background-image: url("../images/Untitled (2).svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: unset;
  margin-top: 4rem;
}
.PSS6RImg img {
  position: relative;
  top: -2rem;
}
.PSS6RImg img {
  padding-left: 3rem;
}
.imgShow {
  display: none !important;
}
.s7P2RightsideColumn img {
  display: none;
}
.s7P2LeftsideColumn img {
  display: none;
}
.visibiltyMange {
  visibility: hidden;
}
.PAS5Bg {
  background-image: url("../images/PAS6Mb.svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: unset;
  margin-top: 4rem;
}
.ftrLinkShow {
  display: none !important;
}
.btnForMB{
  display: none;
}
.btnShow {
  display: none;
}
.HPS7Btn {
  width: 264px !important;
}
.HPS8Ri {
  position: relative;
  top: -8rem;
}
.HPS8Ri img {
  padding: 3rem;
}
.HPS9RSPara {
  padding-right: 7rem;
}

.IndexS7Bg {
  background-image: url("../images/Untitled (3).svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: unset;
}
.IndexS8 {
  padding-bottom: 3rem !important;
}
.IndexS8 {
  padding-bottom: 4rem !important;
}
.PAS3Bg {
  background-color: #fafafa;
  padding-bottom: 0 !important;
}
.PAS3Bg {
  padding-bottom: 0 !important;
}
.PAS7Bg {
  background-image: url("../images/s6bannerBg.svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: unset;
}
.SAS3Bg {
  background-color: #fafafa !important;
  padding-bottom: 0 !important;
}
.SAS4Bg {
  background-image: url("../images/BAs4BannerBg.svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: unset;
  margin-top: 4rem !important;
}
.HMS3Bg {
  background-image: url("../../../public/images/home3.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: unset;
  padding-top: 35rem;
  margin-top: 0rem !important;
  padding-bottom: 6rem;
}

.SAS6Bg {
  padding-bottom: 0 !important;
}
.SAS7Bg {
  background-image: url("../images/s6bannerBg.svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: unset;
}

.PPMainDiv {
  padding-bottom: 5rem !important;
}
.TOUMainDiv {
  padding-bottom: 5rem !important;
}

.hrSmShow {
  display: none;
}

.pl_rem1 {
  padding-left: 1rem;
}

.pl_rem2 {
  padding-left: 2rem;
}

.pl_rem3 {
  padding-left: 3rem;
}

.pl_rem4 {
  padding-left: 4rem;
}

.pl_rem5 {
  padding-left: 5rem;
}

.pl_rem6 {
  padding-left: 6rem;
}

.pl_rem7 {
  padding-left: 7rem;
}

.pl_rem8 {
  padding-left: 8rem;
}

.pl_rem9 {
  padding-left: 9rem;
}

.pl_rem10 {
  padding-left: 10rem;
}

.pr_rem1 {
  padding-right: 1rem;
}

.pr_rem2 {
  padding-right: 2rem;
}

.pr_rem3 {
  padding-right: 3rem;
}

.pr_rem4 {
  padding-right: 4rem;
}

.pr_rem5 {
  padding-right: 5rem;
}

.pr_rem6 {
  padding-right: 6rem;
}

.pr_rem7 {
  padding-right: 7rem;
}

.pr_rem8 {
  padding-right: 8rem;
}

.pr_rem9 {
  padding-right: 9rem;
}

.pr_rem10 {
  padding-right: 10rem;
}

.pt_rem1 {
  padding-top: 1rem;
}

.pt_rem2 {
  padding-top: 2rem;
}

.pt_rem3 {
  padding-top: 3rem;
}

.pt_rem4 {
  padding-top: 4rem;
}

.pt_rem5 {
  padding-top: 5rem;
}

.pt_rem6 {
  padding-top: 6rem;
}

.pt_rem7 {
  padding-top: 7rem;
}

.pt_rem8 {
  padding-top: 8rem;
}

.pt_rem9 {
  padding-top: 9rem;
}

.pt_rem10 {
  padding-top: 10rem;
}

.pb_rem1 {
  padding-bottom: 1rem;
}

.pb_rem2 {
  padding-bottom: 2rem;
}

.pb_rem3 {
  padding-bottom: 3rem;
}

.pb_rem4 {
  padding-bottom: 4rem;
}

.pb_rem5 {
  padding-bottom: 5rem;
}

.pb_rem6 {
  padding-bottom: 6rem;
}

.pb_rem7 {
  padding-bottom: 7rem;
}

.pb_rem8 {
  padding-bottom: 8rem;
}

.pb_rem9 {
  padding-bottom: 9rem;
}

.pb_rem10 {
  padding-bottom: 10rem;
}

/* bank css start here  */
.walletMainContainer {
  display: flex;
  gap: 0.2rem;
}
.sideBarMainContent {
  width: 100%;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: scroll;
}
/* Hide scrollbar for Chrome, Safari and Opera */
.sideBarMainContent::-webkit-scrollbar , .CSwapDropDown::-webkit-scrollbar {
  display: none;
}
.popover-body ul{
  padding-left: 1rem;
}
/* Hide scrollbar for IE, Edge and Firefox */
.sideBarMainContent , .CSwapDropDown {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.WS1Bg {
  width: 100%;
}
.WHS1MainDiv {
  background-image: url("../../../public/images/WHS1bg.svg");
  background-position: left;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 1.5rem 1rem 1.5rem 0;
  position: relative;
  box-shadow: 0px 3px 32.25px 0px #00000040;
}

.notifyIconDiv {
  padding: 2rem 9rem 0 0;
  position: absolute;
  top: 0;
  right: 0;
}
.notifyIcon {
  width: 17px;
  height: 17px;
}
.wrapperInsideSecondDiv {
  padding-top: 5rem;
}

.wrapperInsideSecondDiv h3 {
  font-family: "sf-ui-display" !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  line-height: 16px !important;
  letter-spacing: 0em !important;
  text-align: left !important;
  color: #ffffff !important;
}
.wrapperInsideSecondDiv h1 {
  font-family: "sf-ui-display" !important;
  font-size: 48px !important;
  font-weight: 600 !important;
  line-height: 58px !important;
  letter-spacing: 0em !important;
  text-align: left !important;
  color: #ffffff !important;
}
.settingImg {
  width: 26px;
  height: 26px;
}
.WS1Btn svg {
  max-width: 10.2px;
  max-height: 11.29px;
}
.WS1Btn {
  max-width: 90px;
  max-height: 30px;
  padding: 4.5px 13.5px 2px 13.5px;
  font-family: "sf-ui-display" !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  letter-spacing: 0em !important;
  text-align: left !important;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 27.75px;
  border: 0.75px solid #ffffff;
  cursor: pointer;
  gap: 7.5px;
  text-decoration: none;
}
.WDS1Btn svg {
  width: 9.17 !important;
  height: 10.3 !important;
}
.WDS1Btn {
  padding: 0.45rem 1rem 0.5rem 1rem;
  font-family: "sf-ui-display" !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  line-height: 15px !important;
  letter-spacing: 0em !important;
  text-align: left !important;
  color: #00051a;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 15.5px;
  cursor: pointer;
  gap: 7.5px;
  text-decoration: none;
  border: 1px solid #00051a;
  background-color: transparent;
}
.WS1Btn.active {
  color: #2645e0;
  background-color: #ffffff;
}
.WDS1Btn.active {
  color: #ffe200;
  background-color: #00051a;
}
.WDS1Btn.active svg {
  fill: #ffe200;
}
.WDS1Btn:hover {
  transition: ease-in-out 0.3s;
  color: #ffe200;
  background-color: #00051a;
}
.WDS1Btn:hover svg {
  fill: #ffe200;
}
.WDS2Btn {
  padding: 0.7rem 0.7rem 0.5rem 0.7rem;
  font-family: "sf-ui-display" !important;
  font-size: 10.5px !important;
  font-weight: 500 !important;
  line-height: 12.5px !important;
  letter-spacing: 0em !important;
  text-align: left !important;
  color: #cbcbcb;
  border: 2px solid #cbcbcb;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 18px;
  cursor: pointer;
  gap: 0.7rem;
  text-decoration: none;
  background-color: transparent;
}
.WDS2Btn.active {
  color: #ffffff;
  border: 2px solid #2645e0;
  background-color: #2645e0;
}
.WDS2Btn:hover {
  transition: ease-in-out 0.3s;
  color: #ffffff;
  border: 2px solid #2645e0;
  background-color: #2645e0;
}
.WS1Btn svg {
  padding-bottom: 0.05rem;
}

.WS1Btn:hover {
  background-color: #ffffff;
  color: #2645e0;
  transition: ease-in-out 0.3s;
}
.WS1Btn:hover svg {
  fill: #2645e0;
}
.WS1Btn.active svg {
  fill: #2645e0;
}
.WS1BtnDiv {
  padding-right: 5rem;
  display: flex;
  gap: 2rem;
}
.settingImg {
  cursor: pointer;
  width: 26px;
  height: 26px;
}
.rightSideDivLogin {
  height: 100vh;
  overflow-y: auto;
  background: #FFF;
box-shadow: -13px -46px 61.1px 0px #000;
}
.error_login_div{
  transition: ease-in-out .3;
  border-radius: 11px;
  border: 1px solid #F0263C;
  background: #FEF7F7;
  width: 340px;
  padding: .75rem 1.25rem;
  flex-shrink: 0;
}
.error_login_div img{
    width: 16px;
    height: 16px;
}
.new_error_text{
  color: #F0263C;
  font-family: 'Inter';
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.back_icon{
  width: 40px;
  height: 40px;
  cursor: pointer;
}
.new_login_logo{
  width: 100px;
}
.new_login_logo01{
  width: 220px;
}
.new_heading{
  color: #FFF;
  font-family: 'Inter';
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.sign_up_new_heading{
  color: #fff;
  font-family: 'Inter';
  font-size: 32px;
  font-weight: 200;
  line-height: 38.41px;
  text-align: left;
}
.sign_up_new_heading_b{
  font-weight: 400;
}
.sign_up_new_para{
  color: #FFF;
  font-family: 'Inter';
  font-size: 20px;
  font-style: normal;
  font-weight: 100;
  line-height: normal;
}
.buttom_steps_row{
  height: 20px;
  flex-shrink: 0;
  background: #FAFAFA;
}
.fill_up{
  background: var(--Primary-Blue, #2645E0);
  transition: ease-in-out .5s;
}
.bottom_steps_h{
  color: var(--Background, #F2F9FF);
  font-family: 'Inter';
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: center;
}
.checkImg{
  width: 16px;
  height: 16px;
}
.manageBtnW{
  width: 25%;
}
.manageLogoW{
  width: 65%;
}
.bottom_steps_h_black{
  color: rgba(0, 5, 26, 1);
  font-family: 'Inter';
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: center;
}
.bottom_steps_h_blue{
  color: rgba(38, 69, 224, 1);
  font-family: 'Inter';
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: center;
}




/* Hide scrollbar for Chrome, Safari and Opera */
.rightSideDivLogin::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.rightSideDivLogin {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.WHPS1downBtn {
  border: 1px solid #ffffff;
  border-radius: 40px;
  padding: 0.15rem 0.75rem;
  font-family: "sf-ui-display" !important;
  font-size: 9px !important;
  font-weight: 500 !important;
  line-height: 11px !important;
  letter-spacing: 0em !important;
  text-align: left !important;
  color: #ffffff;
  text-decoration: none;
  cursor: pointer;
  background: transparent;
}
.WHPS2downBtn {
  border: 1px solid #00051a;
  border-radius: 40px;
  padding: 0.15rem 0.75rem;
  font-family: "sf-ui-display" !important;
  font-size: 9px !important;
  font-weight: 500 !important;
  line-height: 11px !important;
  letter-spacing: 0em !important;
  text-align: left !important;
  color: #00051a;
  text-decoration: none;
  cursor: pointer;
  background: transparent;
}
.WHPS1downBtn1 {
  border-radius: 50%;
  background-color: #0fa801;
  width: 12.75px;
  height: 12.75px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.upperArrowImg {
  width: 5.85px;
  height: 6.56px;
}
/* wallet Cards  */
.WHPS2Card {
  width: 100%;
  padding: 1.25rem 1.75rem;
  background-color: #ffffff;
  box-shadow: 0px 0px 11px 0px #00000026;
  border-radius: 18px;
  cursor: pointer;
}
.WFDS2Card {
  width: 100%;
  padding: 1.5rem 1.8rem;
  background-color: #f9f9f9;
  box-shadow: 0px 0px 11px 0px #00000026;
  border-radius: 18px;
  height: 180px;
}
.WFDS2Card:hover {
  background-color: #ffffff;
  cursor: pointer;
}

.WHPS3LCard {
  width: 100%;
  padding: 2rem;
  background-color: #ffffff;
  box-shadow: 0px 0px 11px 0px #00000026;
  border-radius: 18px;
  position: relative;
  max-height: 340px;
}
.WHPS3RCard {
  width: 100%;
  padding: 1.5rem;
  background-color: #ffffff;
  box-shadow: 0px 0px 11px 0px #00000026;
  border-radius: 18px;
  position: relative;
  height: 340px;
  overflow-y: auto;
}
.WHPS3RCard::-webkit-scrollbar {
  width: 1.5px; /* Width of the scrollbar */
  background-color: #f5f5f5; /* Background color */
}
.addCurrencyCardWraper {
  background: linear-gradient(
    to top left,
    rgba(255, 226, 0, 1),
    rgba(38, 69, 224, 1),
    rgba(7, 30, 237, 1)
  );
  width: 100%;
  padding: 2px;
  border-radius: 22.5px;
  /* position: relative; */
}
.addCurrencyCard {
  width: 100%;
  background-color: #ffffff;
  padding: 2.3rem 1.8rem;
  border-radius: 18px;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.WCDUpperRow {
  padding: 3rem 7rem 1rem 7rem !important;
}

.WHPS2Card h2 {
  background-image: linear-gradient(
    to bottom right,
    rgba(255, 226, 0, 1),
    rgba(38, 69, 224, 1),
    rgba(7, 30, 237, 1)
  );
  -webkit-background-clip: text; /* Apply gradient to text */
  color: transparent; /* Hide the original text */
  font-family: "sf-ui-display";
  font-size: 30px;
  font-weight: 600;
  line-height: 35px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 0 !important;
}
.WCDUpperRow h2 {
  background-image: linear-gradient(
    to bottom right,
    rgba(255, 226, 0, 1),
    rgba(38, 69, 224, 1),
    rgba(7, 30, 237, 1)
  );
  -webkit-background-clip: text; /* Apply gradient to text */
  color: transparent; /* Hide the original text */
  font-family: "sf-ui-display";
  font-size: 24px;
  font-weight: 600;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 0 !important;
}
.WHPS2Card h4 {
  color: #767676; /* Hide the original text */
  font-family: "sf-ui-display";
  font-size: 15px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
}
.WHPS2Card p {
  color: #00051a; /* Hide the original text */
  font-family: "sf-ui-display";
  font-size: 12px;
  font-weight: 500;
  line-height: 9px;
  letter-spacing: 0em;
  text-align: left;
  margin: 0 !important;
}
.WCDUpperRow p {
  color: #00051a; /* Hide the original text */
  font-family: "sf-ui-display";
  font-size: 15px;
  font-weight: 500;
  line-height: 9px;
  letter-spacing: 0em;
  text-align: left;
  margin: 0 !important;
}
.WHPS2Card span {
  color: #2645e0; /* Hide the original text */
  font-family: "sf-ui-display";
  font-size: 12px;
  font-weight: 600;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: left;
}
.WCDUpperRow span {
  color: #2645e0; /* Hide the original text */
  font-family: "sf-ui-display";
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: left;
}
.cardLogo {
  border-radius: 50%;
  width: 27px;
  height: 27px;
}
.navToggler {
  font-family: "sf-ui-display";
  font-size: 12px;
  font-weight: 300;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
  color: #00051a;
  cursor: pointer;
}

.navToggler.active {
  border-bottom: 1.5px solid #00051a;
  font-weight: 500;
}
.currencySwapOptionImg {
  width: 28px;
  height: 28px;
  border-radius: 50%;
}
.currencySwapOptionArrow {
  width: 12px;
  height: 6px;
}
.currencySwapOption h3 {
  font-family: "sf-ui-display";
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #00051a;
  cursor: pointer;
  margin-bottom: 0 !important;
}
.currencySwapOption {
  cursor: pointer;
  border-right: 0.75px solid #cfcfcf;
  padding: 0.75rem;
}
.currencySwapOption {
  cursor: pointer;
  border-right: 0.75px solid #cfcfcf;
  padding: 0.75rem;
}
.custom_dropDown_in_WTH {
  width: 100% !important;
  max-width: 180px !important;
  box-shadow: 0 0 11px #e6e6e6;
  border: none;
}
.custom_dropDown_in_WTH a {
  width: 100% !important;
  max-width: 180px !important;
}
.custom_dropDown_in_WTH1 {
  width: 100% !important;
  box-shadow: 0 0 11px #e6e6e6;
  border: none;
}
.custom_dropDown_in_WTH1 a {
  width: 100% !important;
}
.centerRow {
  border: 1px solid black;
  border-radius: 13.5px;
  width: 100%;
}
.centerRow1 {
  border: 1px solid black;
  border-radius: 13.5px;
  width: 100%;
  margin: 4rem 0;
  position: relative;
}
.swapInput {
  padding: 1rem 0.05rem;
  border: none;
  width: 100%;
  font-family: "sf-ui-display";
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center;
  color: #2645e0;
}

.swapInput:focus-visible {
  border: none !important;
  outline: none;
  box-shadow: none;
}
.swapImg {
  width: 48px;
  height: 48px;
  z-index: 1;
  text-align: center;
  position: absolute;
  top: 40%;
  cursor: pointer;
}
.firstDropDown {
  position: absolute !important;
  top: 40%;
  left: 10%;
}
.CSwapDropDown {
  width: 100%;
  position: absolute !important;
  top: 3.5rem;
  left: 0;
  box-shadow: 0 0 11px #b9b9b9;
  border: none !important;
  outline: 0 !important;
  max-height: 70vh;
  overflow-y: auto;
}
.CurrencySwapDropDown {
  width: fit-content;
  padding: .5rem;
  position: absolute !important;
  top: 3.5rem;
  right: 0;
  border: none !important;
  outline: 0 !important;
  max-height: 350px;
  overflow-y: auto;
  box-shadow: 0px 2.25px 15.75px 0px #0000000D !important;
  border-radius: 13.5px !important;
  z-index: 5;
}
/* Hide scrollbar for Chrome, Safari and Opera */
.CurrencySwapDropDown::-webkit-scrollbar, .cSwapRightSide::-webkit-scrollbar {
  display: none;
}

.CurrencySwapDropDown , .cSwapRightSide {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.CurrencySwapDropDown0007 {
  width: fit-content;
  padding: .5rem;
  position: absolute !important;
  top: 3.25rem;
  left: -2%;
  border: none !important;
  outline: 0 !important;
  max-height: 350px;
  overflow-y: auto;
  box-shadow: 0px 2.25px 15.75px 0px #0000000D !important;
  border-radius: 13.5px !important;
  z-index: 5;
}
/* Hide scrollbar for Chrome, Safari and Opera */
.CurrencySwapDropDown0007::-webkit-scrollbar, .cSwapRightSide::-webkit-scrollbar {
  display: none;
}

.CurrencySwapDropDown0007 , .cSwapRightSide {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}




.CurrencySwapDropDown005 {
  width: 320px;
  padding: .5rem 1rem;
  position: absolute !important;
  top: 2rem;
  left: 23%;
  border: none !important;
  outline: 0 !important;
  max-height: 350px;
  overflow-y: auto;
  box-shadow: 0px 2.25px 15.75px 0px #0000000D !important;
  border-radius: 13.5px !important;
  z-index: 5;
}
/* Hide scrollbar for Chrome, Safari and Opera */
.CurrencySwapDropDown005::-webkit-scrollbar {
  display: none;
}

.CurrencySwapDropDown005 {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}


.CurrencySwapDropDown007 {
  width: 100%;
  padding: .5rem 1rem;
  position: absolute !important;
  top: 2.7rem;
  left: 0;
  border: none !important;
  outline: 0 !important;
  max-height: 350px;
  overflow-y: auto;
  box-shadow: 0px 2.25px 15.75px 0px #0000000D !important;
  border-radius: 13.5px !important;
  z-index: 5;
}
/* Hide scrollbar for Chrome, Safari and Opera */
.CurrencySwapDropDown007::-webkit-scrollbar {
  display: none;
}

.CurrencySwapDropDown007 {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}





.CSwapDropDownSC {
  width: 100%;
  position: absolute !important;
  top: 2.5rem;
  left: 0;
  box-shadow: 0 0 11px #e6e6e6;
  border: none;
}

.blogwapDropDown {
  width: 100%;
  position: absolute !important;
  top: 2rem;
  left: 0;
  box-shadow: 0 0 11px #e6e6e6;
  border: none !important;
  cursor: pointer;
  outline: 0 !important;
  box-shadow: 0 0 11px #e6e6e6 !important;
}

.CSwapDropDown1 {
  width: 100%;
  position: absolute !important;
  top: 3.2rem;
  left: 0;
  box-shadow: 0 0 11px #e6e6e6;
  border: none;
}

.firstDropDown1 {
  position: absolute !important;
  top: 62%;
  left: 10%;
}
.firstDropDown2 {
  position: absolute !important;
  top: 100%;
  left: 0%;
}
.WHPS3bottomDiv {
  padding: 1rem;
}
.WHPS3bottomDiv a {
  background-color: #000000;
  padding: 1rem 2rem;
  width: 100%;
  font-family: "sf-ui-display";
  font-size: 18px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;
  color: #ffe200 !important;
  border-radius: 14.5px;
  cursor: pointer;
}
.WHPS3bottomDiv .btnChange {
  background-color: #000000;
  padding: 0.75rem 1.5rem;
  width: 100%;
  font-family: "sf-ui-display";
  font-size: 15px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;
  color: #ffe200 !important;
  border-radius: 14.5px;
  cursor: pointer;
}
.WHPS3bottomDiv1 a {
  background-color: transparent;
  padding: 1.2rem;
  width: 100%;
  font-family: "sf-ui-display";
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center;
  color: #0000001a !important;
  border-radius: 10.5px;
  cursor: pointer;
  border: 1px solid #00051a;
}
.WHPS3bottomDiv1 a:hover {
  background-color: #00051a;
  color: #ffe200 !important;
}
.WHPS3bottomDiv2 a {
  background-color: #00051a;
  padding: 1.2rem;
  width: 100%;
  font-family: "sf-ui-display";
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center;
  color: #ffe200 !important;
  border-radius: 10.5px;
  cursor: pointer;
  border: 1px solid #00051a;
}

.WHPS3RCard h2 {
  font-family: "sf-ui-display";
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
}

.WHPS3RCard .coinName {
  font-family: "sf-ui-display";
  font-size: 12px;
  font-weight: 300;
  line-height: 14.25px;
  letter-spacing: 0em;
  text-align: center;
}
.WHPS3RCard .coinNameBold {
  font-family: "sf-ui-display";
  font-size: 12px;
  font-weight: 500;
  line-height: 14.25px;
  letter-spacing: 0em;
  text-align: center;
}
.WHPS3RCard .coinBalanceIng {
  background-image: linear-gradient(
    to bottom right,
    rgba(255, 226, 0, 1),
    rgba(38, 69, 224, 1),
    rgba(7, 30, 237, 1)
  );
  -webkit-background-clip: text; /* Apply gradient to text */
  color: transparent; /* Hide the original text */
  font-family: "sf-ui-display";
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}

.WHPS3RCard .coinPriceText {
  color: #2645e0; /* Hide the original text */
  font-family: "sf-ui-display";
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}
.WHPS3RCard .ProfitLoss {
  font-family: "sf-ui-display";
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #a1a1a1;
}
.WHPS3RCardInnerContentDiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.WHPS1downBtn2 {
  border-radius: 50%;
  background-color: #0fa801;
  width: 12px;
  height: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.WHPS1downBtn2 img {
  width: 7px !important;
  height: 7px !important;
}
.WHPS3RecentActivityDiv h1 {
  font-family: "sf-ui-display";
  font-size: 16px;
  font-weight: 600;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
  color: #00051a;
  margin-bottom: 0 !important;
}
.WHPS3RecentActivityDiv img {
  width: 36px;
  height: 36px;
}
.wdBtnIco {
  width: 11.5px !important;
  height: 11.5px !important;
}
.WHPS3RecentActivityDiv a {
  padding: 0.5rem 3rem;
  border-radius: 48px;
  background-color: #2645e0;
  color: #ffffff !important;
  font-family: "sf-ui-display";
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
}
.customBtnWD {
  padding: 0.5rem 1rem !important;
  border-radius: 48px !important;
  background-color: #2645e0 !important;
  color: #ffffff !important;
  font-family: "sf-ui-display" !important;
  font-size: 10px !important;
  font-weight: 600 !important;
  line-height: 12px !important;
  letter-spacing: 0em !important;
  text-align: center !important;
  text-decoration: none !important;
  cursor: pointer !important;
}
.WHPS3RecentActivityDiv p {
  font-family: "sf-ui-display";
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #00051a;
}
.WHPS3RecentActivityDiv span {
  font-family: "sf-ui-display";
  font-size: 9px;
  font-weight: 500;
  line-height: 9px;
  letter-spacing: 0em;
  text-align: left;
  color: #454745;
}
.WHPS3RecentActivityDiv .trDates {
  font-family: "sf-ui-display";
  font-size: 9px;
  font-weight: 300 !important;
  line-height: 9px;
  letter-spacing: 0em;
  text-align: left;
  color: #454745;
}
.WSHS2Input {
  width: 100%;
  border-color: #00051a !important;
  font-family: "sf-ui-display";
  font-size: 18px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #454745;
  border-radius: 13.5px;
  padding: 1rem 0.5rem;
}
.WSHS2Input:focus {
  outline: 0 !important;
  box-shadow: 0 0 0 0.1rem rgba(17, 17, 17, 0.25) !important;
  border-color: #00051a !important;
  outline: 0;
}
.WMainDiv {
  padding: 1rem 5rem;
  width: 100%;
}
.UpperNavHeading {
  font-family: "sf-ui-display";
  font-size: 24px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
  color: #00051a;
}
.WBackArrow {
  width: 36px;
  height: 36px;
  cursor: pointer;
}
.WNotifyIcon {
  width: 24px;
  height: 27px;
  cursor: pointer;
}
.upperNavFix {
  position: sticky;
  top: 2%;
  z-index: 5;
  background-color: #ffffff;
}
.upperNavMainDiv {
  border-bottom: 1px solid #d9d9d9;
}
.SummaryTxt {
  font-family: "sf-ui-display";
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #00051a;
}
.walletInsideMainContainer {
  padding: 3rem 7rem;
}
.FS1Carddiv {
  padding: 2rem 5rem;
  box-shadow: 0px 0px 24px 0px #00000026;
  border-radius: 34px;
}
.FS1Carddiv h5 {
  font-family: "sf-ui-display";
  font-size: 15px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #000000;
}
.dateInput01{
  border-radius: 7.377px;
border: 0.738px solid var(--secondary-dark, #00051A);
color: var(--extra, #767676);
font-family: "sf-ui-display";
font-size: 14.754px;
font-style: normal;
font-weight: 300;
line-height: normal;
width: 100%;
max-width: 168px;
padding: 13px 14px;
}
.statementDiv01{
  display: flex;
  gap: 20px;
  margin-top: 30px;
  align-items: flex-end;
}
.downloadBtn01{
  color: var(--accent-yellow, #FFE200);
  font-family: "sf-ui-display";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border-radius: 7.38px;
background: var(--secondary-dark, #00051A);
padding: 13px 20px;
border: none;
}
.dateInput02{
  padding: 6px 10px !important;
  font-size: 12px !important;
}
.downloadBtn02{
  padding: 8px 12px !important;
  font-size: 11px !important;
}
.statementDiv02{
  justify-content: end !important;
}
.statementDiv01 h6{
  color: var(--secondary-dark, #00051A);
  font-family: "sf-ui-display";
font-size: 10.5px;
font-style: normal;
font-weight: 500;
line-height: normal;
}
.AcCurrencyddDivApply{
  /* width: 100% !important; */
  width: 200px !important;
  padding: 0.4rem .6rem !important; 
  border-radius: 13px !important;
}
.optionUl01{
  padding: 0;
  margin: 0;
}
.optionUl01 li{
  list-style: none;
  padding: 8px 10px;
  color: var(--secondary-dark, #00051A);
  font-family: "sf-ui-display";
font-size: 14.328px;
font-style: normal;
font-weight: 300;
line-height: normal;
}
.dropdownDiv01{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
  gap: 15px;
}
.FS1Carddiv h1 {
  background-image: linear-gradient(
    to bottom right,
    rgba(255, 226, 0, 1),
    rgba(38, 69, 224, 1),
    rgba(7, 30, 237, 1)
  );
  -webkit-background-clip: text; /* Apply gradient to text */
  color: transparent; /* Hide the original text */
  font-family: "sf-ui-display";
  font-size: 48px;
  font-weight: 600;
  line-height: 58px;
  letter-spacing: 0em;
  text-align: left;
}
.FS1Carddiv h6 {
  font-family: "sf-ui-display";
  font-size: 10px;
  font-weight: 500;
  line-height: 13px;
  letter-spacing: 0em;
  text-align: left;
  color: #00051a;
}
.FDetailsDiv {
  width: 100%;
  border-radius: 5px;
  background-color: #f5f5f5;
  padding: 0.5rem;
  word-break: break-all;
}
.ApplyUpgradeHeading {
}
.FDetailsDiv p {
  font-family: "sf-ui-display";
  font-size: 12px;
  font-weight: 300;
  line-height: 14.5px;
  letter-spacing: 0em;
  text-align: left;
  color: #757575;
}
.WFDS2Card h2,
.addCurrencyCard p {
  background-image: linear-gradient(
    to bottom right,
    rgba(255, 226, 0, 1),
    rgba(38, 69, 224, 1),
    rgba(7, 30, 237, 1)
  );
  -webkit-background-clip: text; /* Apply gradient to text */
  color: transparent; /* Hide the original text */
  font-family: "sf-ui-display";
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
}
.WFDS2Card h1 {
  font-family: "sf-ui-display";
  font-size: 15px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #2645e0;
}
.WFDS2Card p {
  font-family: "sf-ui-display";
  font-size: 12px;
  font-weight: 300;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: left;
  color: #00051a;
}
.cryptoMainRow h1 {
  font-family: "sf-ui-display";
  font-size: 64px;
  font-weight: 600;
  line-height: 76px;
  letter-spacing: 0em;
  text-align: center;
  color: #000000;
}
.swapCard {
  box-shadow: 0px 0px 11px 0px #0000001a;
  background-color: #ffffff;
  padding: 4rem;
  border-radius: 37px;
  transition: transform 0.3s ease;
}
.cardDetailsCard {
  box-shadow: 0px 0px 11px 0px #0000001a;
  background-color: #ffffff;
  padding: 1.5rem 3rem;
  border-radius: 37px;
  transition: transform 0.3s ease;
}
.fiatTransferCard {
  box-shadow: 0px 0px 11px 0px #0000001a;
  background-color: #ffffff;
  padding: 1.5rem 3rem;
  border-radius: 25.5px;
  transition: transform 0.3s ease;
}
.addMargin {
  margin-bottom: 4rem;
}
.CSwapLabelDiv {
  width: 100%;
}
.CSwapLabelDiv p {
  font-family: "sf-ui-display";
  font-size: 20px;
  font-weight: 200;
  line-height: 24px;
  letter-spacing: 0.03em;
  text-align: left;
  color: #00051a;
}
.CSwapLabelDiv .gradiantBtn {
  width: 77px;
  height: 22px;
  position: relative;
  /* display: inline-block; */
  padding: 0;
  font-size: 14px;
  cursor: pointer;
  background: linear-gradient(
    to bottom right,
    rgba(255, 226, 0, 1),
    rgba(38, 69, 224, 1),
    rgba(7, 30, 237, 1)
  );
  z-index: 1;
  overflow: hidden;
  border-radius: 10px;
}

.CSwapLabelDiv .gradiantBtn .button-content {
  width: 77px;
  height: 22px;
  border: 1px solid transparent;
  border-image: linear-gradient(
    to bottom right,
    rgba(255, 226, 0, 1),
    rgba(38, 69, 224, 1),
    rgba(7, 30, 237, 1)
  );
  border-image-slice: 1;
  border-radius: 10px;
  background-clip: padding-box, border-box;
  transition: all 0.3s ease;
  background-color: #ffffff;
}

.CSwapLabelDiv .gradiantBtn .sidebar-gradient-text {
  background-image: linear-gradient(
    to bottom right,
    rgba(255, 226, 0, 1),
    rgba(38, 69, 224, 1),
    rgba(7, 30, 237, 1)
  );
  -webkit-background-clip: text; /* Apply gradient to text */
  color: transparent; /* Hide the original text */
  font-family: "sf-ui-display";
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;
}

.CSwapDiv {
  border: 1px solid black;
  border-radius: 18px;
}
.cSwapRightSide {
  border-right: 1px solid #cfcfcf;
  position: relative;
  padding: 1rem 1.5rem;
  cursor: pointer;
}
.countrySelect {
  border-radius: 11px;
  border: 1px solid var(--gray, #D9D9D9);
  background: var(--Background, #F2F9FF);
  position: relative;
  padding: .75rem;
  cursor: pointer;
}
.cSwapRightSide p {
  font-family: "sf-ui-display";
  font-size: 24px;
  font-weight: 500;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: left;
}
.cSwapRightSide .cLogo {
  width: 37px;
  height: 37px;
  border-radius: 50%;
}
.cSwapRightSide1 {
  position: relative;
  cursor: pointer;
}
.cSwapRightSide1 p {
  font-family: "sf-ui-display";
  font-size: 18px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: #000000;
}
.cSwapRightSide1 .cLogo {
  width: 25px;
  height: 25px;
  border-radius: 50%;
}
.CSwapDropDown .dropdown-item {
  font-family: "sf-ui-display" !important;
  font-size: 18px !important;
  font-weight: 500 !important;
  line-height: normal !important;
  letter-spacing: 0em !important;
  text-align: left !important;
}

.CurrencySwapDropDown .dropdown-item {
  font-family: "sf-ui-display" !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: normal !important;
  letter-spacing: 0em !important;
  text-align: left !important;
}
.CurrencySwapDropDown .dropdown-item span {
  font-family: "sf-ui-display" !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: normal !important;
  letter-spacing: 0em !important;
  text-align: left !important;
  color: #C0C0C0 !important;
}



.CSwapDropDownSC {
  font-family: "sf-ui-display" !important;
  font-size: 13px !important;
  font-weight: 300 !important;
  line-height: 13px !important;
  letter-spacing: 0em !important;
  text-align: left !important;
}

.CSwapDropDown .dropdown-item img {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-right: 1rem;
}
.CurrencySwapDropDown .dropdown-item img {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  margin-right: 1rem;
}




.cInput {
  padding: 0.75rem;
  border: none !important;
  width: 100%;
  font-family: "sf-ui-display";
  font-size: 24px;
  font-weight: 500;
  line-height: 44px;
  letter-spacing: 0em;
  text-align: center;
  color: #2645e0 !important;
  position: relative;
  top: 20%;
}
.cInput:focus {
  outline: 0;
  box-shadow: none !important;
}
.cInput::placeholder {
  color: #2645e0 !important;
}
.fInput {
  padding: 0.75rem;
  border: none;
  width: 100%;
  font-family: "sf-ui-display";
  font-size: 24px;
  font-weight: 500;
  line-height: 44px;
  letter-spacing: 0em;
  text-align: center;
  color: #b0b0b0 !important;
}
.fInput:focus {
  outline: 0;
  box-shadow: none !important;
}
.fInput::placeholder {
  color: #b0b0b0 !important;
}

.swapBtnRow {
  width: 100%;
  position: relative;
  border-bottom: 2px solid #cfcfcf;
}
.swapIcon {
  width: 74px;
  height: 74px;
  position: absolute;
  cursor: pointer;
  top: -35px;
  z-index: 1;
}
.swapTotal {
  word-break: break-all;
  font-family: "sf-ui-display";
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: center;
  color: #000000;
}

.cardRightSide img {
  width: 100%;
}
.cardLeftSide label {
  font-family: "sf-ui-display";
  font-size: 15px;
  font-weight: 300;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #000000;
}
.cardLeftSide h4 {
  font-family: "sf-ui-display";
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
  color: #071eed;
}
.cardLeftSide p {
  font-family: "sf-ui-display";
  font-size: 15px;
  font-weight: 300;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #00051a;
}
.cardLeftSide p b {
  font-family: "sf-ui-display";
  font-size: 15px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #00051a;
}
.cardLeftSide .info {
  font-family: "sf-ui-display";
  font-size: 16px;
  font-weight: 300;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  color: #757575;
}
.networkText {
  font-family: "sf-ui-display";
  font-size: 13px;
  font-weight: 300;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: #00051a;
}

.cardUpperRow {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-bottom: 1px solid #d9d9d9;
}
.cardUpperRow h1 {
  background-image: linear-gradient(
    to bottom right,
    rgba(255, 226, 0, 1),
    rgba(38, 69, 224, 1),
    rgba(7, 30, 237, 1)
  );
  -webkit-background-clip: text; /* Apply gradient to text */
  color: transparent; /* Hide the original text */
  font-family: "sf-ui-display";
  font-size: 24px;
  font-weight: 500;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: left;
}
.cardUpperRow h3 {
  font-family: "sf-ui-display";
  font-size: 18px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0em;
  text-align: left;
  color: #000000;
}
.cardUpperRow p {
  font-family: "sf-ui-display";
  font-size: 10px;
  font-weight: 300;
  line-height: 13px;
  letter-spacing: 0em;
  text-align: center;
  color: #000000;
  cursor: pointer;
}
.cardUpperRow p.active {
  margin-bottom: 0.3rem;
  font-weight: 600;
  border-bottom: 1.5px solid #000000;
}

.setRowWidth {
  width: 100% !important;
}
.paymentCard {
  max-width: 258px;
  width: 100%;
  background-color: #ffffff;
  padding: 2rem;
  box-shadow: 0px 0px 11px 0px #0000001a;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2.5rem;
  border-radius: 27px;
  cursor: pointer;
}
.paymentCard img {
  width: 71px;
  height: 71px;
  max-width: 100%;
}
.paymentCard p {
  font-family: "sf-ui-display";
  font-size: 15px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;
  color: #000000;
}
.paymentCardBills {
  max-width: 120px;
  width: 100%;
  background-color: #ffffff;
  padding: 1.5rem 1rem;
  box-shadow: 0px 0px 11px 0px #0000001a;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  border-radius: 27px;
  cursor: pointer;
}
.paymentCardBills img {
  width: 24px;
  height: 24px;
  max-width: 100%;
}
.paymentCardBills p {
  font-family: "sf-ui-display";
  font-size: 13px;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: center;
  color: #000000;
  margin: 0 !important;
}

.cardContentDiv a {
  width: 210px;
  max-width: 100%;
  padding: 1rem;
  box-shadow: 0px 0px 11px 0px #00000026;
  background-color: #ffffff;
  font-family: "sf-ui-display";
  font-size: 13px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0em;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
  color: #00051a;
  border-radius: 13.5px;
}
.cardContentDiv a.active {
  background-color: #2645e0;
  color: #ffffff;
}
.cardContentDiv a:hover {
  transition: ease-in-out 0.3s;
  background-color: #2645e0;
  color: #ffffff !important;
}
.newPaymentDiv h6 {
  font-family: "sf-ui-display";
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  color: #00051a;
}
.newPaymentDiv h4 {
  font-family: "sf-ui-display";
  font-size: 18px;
  font-weight: 300;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: #808080;
}
.fTransferInput {
  border: none !important;
  outline: 0 !important;
  background-color: #f5f5f5 !important;
  font-family: "sf-ui-display";
  font-size: 13px;
  font-weight: 300;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: #00051a;
  padding: 0.75rem !important;
}
.fTransferInput:focus {
  border: none !important;
  outline: 0 !important;
  box-shadow: none !important;
}
.fTransferInput::placeholder {
  font-family: "sf-ui-display";
  font-size: 13px;
  font-weight: 300;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: #888888;
}
.savedPaymentCard {
  box-shadow: 0px 0px 11px 0px #00000026;
  padding: 1.5rem;
  border-radius: 13px;
  cursor: pointer;
}
.savedPaymentCard .name {
  color: #00051a;
  font-family: "sf-ui-display";
  font-size: 15px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0em;
  text-align: left;
}
.savedPaymentCard .accTitle {
  color: #9d9d9d;
  font-family: "sf-ui-display";
  font-size: 9px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0em;
  text-align: left;
}
.savedPaymentCard .accNo {
  color: #00051a;
  font-family: "sf-ui-display";
  font-size: 9px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0em;
  text-align: left;
}
.lastTransactionCard {
  background: #f2f9ff;
  max-width: max-content;
  width: 100%;
  border-radius: 9px;
  padding: 0.5rem 1rem;
}
.lastTransactionCard .trTitle {
  font-family: "sf-ui-display";
  font-size: 7.5px;
  font-weight: 300;
  line-height: normal;
  letter-spacing: 0em;
  text-align: left;
  color: #00051a;
}
.lastTransactionCard .trAmount {
  font-family: "sf-ui-display";
  font-size: 10.5px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0em;
  text-align: left;
  color: #00051a;
}
.lastTransactionCard .trAmount span {
  font-family: "sf-ui-display";
  font-size: 10.5px;
  font-weight: 300;
  line-height: normal;
  letter-spacing: 0em;
  text-align: left;
  color: #00051a;
}
.lastTransactionCard .trDate {
  font-family: "sf-ui-display";
  font-size: 7.5px;
  font-weight: 300;
  line-height: normal;
  letter-spacing: 0em;
  text-align: left;
  color: #a09f9f;
}
.savedPaymentCard:hover p {
  transition: ease-in-out 0.3s;
  color: #ffffff !important;
}
.savedPaymentCard:hover {
  transition: ease-in-out 0.3s;
  background-color: #071eed !important;
}
.savedPaymentCard:hover .lastTransactionCard {
  transition: ease-in-out 0.3s;
  background-color: #071eed !important;
}
.stepTwoContentDiv .h {
  font-family: "sf-ui-display";
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  color: #00051a;
}
.stepTwoContentDiv .pb {
  font-family: "sf-ui-display";
  font-size: 18px;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: #2645e0;
}
.stepTwoContentDiv .p {
  font-family: "sf-ui-display";
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  color: #2645e0;
}
.stepTwoContentDiv .s {
  font-family: "sf-ui-display";
  font-size: 16px;
  font-weight: 300;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  color: #00051a;
}
.AmountInputDiv {
  border-right: 2px solid #cfcfcf;
}
.stepThreeoContentDiv .h {
  font-family: "sf-ui-display";
  font-size: 24px;
  font-weight: 500;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: center;
  color: #00051a;
}
.stepThreeoContentDiv .p {
  font-family: "sf-ui-display";
  font-size: 20px;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  color: #00051a;
}
/* Add this CSS in your stylesheet or component-specific CSS */
.code-input-container {
  display: flex;
  justify-content: space-between; /* Adjust as needed for spacing between inputs */
  padding-bottom: 7rem;
  padding-top: 2rem;
}

.code-input-container input {
  width: 50px; /* Adjust the width of each input as needed */
  height: 50px; /* Adjust the height of each input as needed */
  text-align: center;
  border: 1px solid #d9d9d9; /* Add borders or styling as desired */
  border-radius: 10px;
  margin: 0 0.2em; /* Adjust margin for spacing between inputs */
  font-family: "sf-ui-display";
  font-size: 20px;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  color: #00051a;
}
.code-input-container input:focus {
  outline: 0;
  box-shadow: 0px 0px 11px 0px #13007c26;
}
.paymentConfirmationCard {
  width: 100%;
  box-shadow: 0px 0px 11px 0px #0000001a;
  background-color: #ffffff;
  padding: 2rem;
  border-radius: 32px;
}
.paymentConfirmationCard img {
  width: 128px;
  height: 133px;
}
.paymentConfirmationCard .title {
  background-image: linear-gradient(
    to bottom right,
    rgba(255, 226, 0, 1),
    rgba(38, 69, 224, 1),
    rgba(7, 30, 237, 1)
  );
  -webkit-background-clip: text; /* Apply gradient to text */
  color: transparent; /* Hide the original text */
  font-family: "sf-ui-display";
  font-size: 24px;
  font-weight: 500;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: left;
}
.paymentConfirmationCard .amount {
  font-family: "sf-ui-display";
  font-size: 24px;
  font-weight: 500;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: left;
  color: #00051a;
}
.paymentConfirmationCard .amount span {
  color: #071eed;
}
.tid {
  font-family: "sf-ui-display";
  font-size: 20px;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #00051a;
}
.pageLink {
  font-family: "sf-ui-display";
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  color: #071eed;
  text-decoration: underline;
  cursor: pointer;
}
.pageLink a {
  text-decoration: none;
}
.cPHeading {
  font-family: "sf-ui-display";
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  color: 1px solid #00051a;
}
.fDHoverBtnDiv {
  display: block;
  width: 100%;
  border-radius: 11px;
  border: 1px solid #00051a;
  font-family: "sf-ui-display";
  font-size: 8px;
  font-weight: 500;
  line-height: 10px;
  letter-spacing: 0em;
  text-align: center;
  color: #00051a;
  padding: 0.25rem;
  min-width: 50px;
}
.fDHoverBtnDiv:hover,
.fDHoverBtnDiv.active {
  color: #ffe200;
  background-color: #00051a;
}
.fDHoverBtnDiv1 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 75px;
  height: 25px;
  border-radius: 17px;
  border: 1px solid #00051a;
  font-family: "sf-ui-display";
  font-size: 12px;
  font-weight: 500;
  line-height: 10px;
  letter-spacing: 0em;
  text-align: center;
  color: #00051a;
  padding: 0.25rem;
}
.fDHoverBtnDiv1:hover,
.fDHoverBtnDiv1.active {
  color: #ffe200;
  background-color: #00051a;
}
.hoverBtnRow {
  padding: 2.2rem 0 0 0;
  display: none;
}
.WFDS2Card:hover .bottomDiv {
  transition: ease-out 0.3s;
  display: none;
}
.WFDS2Card:hover .hoverBtnRow {
  transition: ease-in 0.3s;
  display: flex;
  justify-content: space-between;
}
.uppderTMb {
  display: none;
  box-shadow: 0px 0px 11px 0px #00000026;
  background: #ffffff;
  z-index: 1;
  position: fixed;
  top: 0;
  padding: 1rem;
}

.uppderTMb .img1 {
  width: 20px;
  max-width: 100%;
}
.uppderTMb .img2 {
  width: 64px;
  max-width: 100%;
}
.uppderTMb .img3 {
  width: 20px;
  max-width: 100%;
}
.WMPATag {
  color: #2645e0 !important;
}
.ACHeading.active {
  color: #071eed;
  font-family: "sf-ui-display";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  border-bottom: 2px solid #071eed;
}
.ACHeading {
  color: #00051a;
  font-family: "sf-ui-display";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  cursor: pointer;
}
.bottomLineAC {
  border-bottom: 1.5px solid #00051a;
}
.AcCurrencyddDiv {
  padding: 0.5rem 1rem;
  width: 100%;
  border-radius: 18px;
  border: 1px solid #00051a;
  background: #fff;
  position: relative;
  cursor: pointer;
}
.blogddDiv {
  padding: 0.5rem;
  width: 100%;
  border-radius: 18px;
  border: 1px solid #00051a;
  background: #fff;
  position: relative;
  cursor: pointer;
}
.blogddDiv h3 {
  color: #00051a;
  font-family: "sf-ui-display";
  font-size: 16px !important;
  font-weight: 300 !important;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
}

.blogddDiv1 {
  padding: 0.5rem;
  width: 100%;
  border-radius: 13.5px;
  background: #2645e0;
  position: relative;
  cursor: pointer;
}
.blogddDiv1 h4 {
  color: #ffffff;
  font-family: "sf-ui-display";
  font-size: 10.5px !important;
  font-weight: 500 !important;
  line-height: normal;
  letter-spacing: 0em;
  text-align: left;
}
.ddLabel {
  color: #00051a;
  font-family: "sf-ui-display";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.AcPwLabels {
  color: #00051a;
  font-family: "sf-ui-display";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.applyforUpgradeInfoText {
  font-family: "sf-ui-display";
  font-size: 16px;
  font-weight: 300;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 3px !important;
}
.applyforUpgradeInfoTextBold {
  font-family: "sf-ui-display";
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 3px !important;
}
.upgradeCardHeading {
  font-family: "sf-ui-display";
  font-size: 24px;
  font-weight: 600;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: left;
}

.pwInfoText P,
.pwInfoText li {
  color: #767676;
  font-family: "sf-ui-display";
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
/* NotFound.css */
.not-found-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
}

.not-found-heading {
  font-size: 3rem;
  color: #ff6347; /* Tomato red color, you can change it to match your theme */
}

.not-found-message {
  font-size: 1.5rem;
  color: #555; /* Dark gray color for the message */
}
.subTextInForgotPasswordPage {
  font-family: "sf-ui-display";
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: center;
  color: #00051a;
}
.backText {
  font-family: "sf-ui-display";
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center;
  color: #001b30;
}
.backToPage {
  margin-top: 5rem;
  cursor: pointer;
}

.emailForgot {
  font-family: "sf-ui-display";
  font-size: 18px;
  font-weight: 600;
  line-height: 31px;
  letter-spacing: 0em;
  text-align: center;
}

.resendEmail {
  font-family: "sf-ui-display";
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: center;
  color: #2645e0 !important;
  text-decoration: underline;
  cursor: pointer;
}

.blogwapDropDown .dropdown-item {
  font-family: "sf-ui-display" !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 24px !important;
  letter-spacing: 0em !important;
  text-align: left !important;
  color: #00051a !important;
}
.blogwapDropDown .dropdown-item:hover {
  transition: ease-in-out 0.3s;
  background-color: #00051a !important;
  color: #ffffff !important;
  border-radius: 13.5px;
  /* padding: 0.3rem; */
}
.CPunderRowCard {
  padding: 1.25rem 1.5rem;
  width: 100%;
  box-shadow: 0px 0px 6.75px 0px #0000000f;
  background-color: #ffffff;
  border-radius: 13.5px;
  cursor: pointer;
}
.CPunderRowCard img {
  border-radius: 50%;
  width: 39px;
  height: 39px;
}
.CPunderRowCard .rightHeading {
  font-family: "sf-ui-display";
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: #00051a;
}
.CPunderRowCard .rightPara {
  font-family: "sf-ui-display";
  font-size: 12px;
  font-weight: 600;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
  color: #2645e0;
}
.CPunderRowCard .leftHeading {
  background-image: linear-gradient(
    to bottom right,
    rgba(255, 226, 0, 1),
    rgba(38, 69, 224, 1),
    rgba(7, 30, 237, 1)
  );
  -webkit-background-clip: text; /* Apply gradient to text */
  color: transparent; /* Hide the original text */
  font-family: "sf-ui-display";
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
}
.CPunderRowCard .leftPara {
  font-family: "sf-ui-display";
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
  color: #767676;
}

.CPunderRowCard h5 {
  font-family: "sf-ui-display";
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #00051a;
}

.CPunderRowCard h6 {
  font-family: "sf-ui-display";
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: #d9d9d9;
}
.CPunderRowCard:hover .leftSideText {
  transition: ease-out 0.3s;
  display: none !important;
}
.CPunderRowCard:hover .leftSideText1 {
  transition: ease-out 0.3s;
  display: flex !important;
}
.QrscannerDiv {
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
}
.currencyImgOnQr {
  width: 34px;
  height: 34px;
  position: absolute;
  left: 40.5%;
  top: 40.5%;
}
.inputDiv {
  width: 100%;
}
.inputTitle {
  font-family: "sf-ui-display";
  font-size: 12px;
  font-weight: 300;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
  color: #00051a;
  margin-bottom: 2px !important;
}
.inputDropDownDiv {
  background-color: #f5f5f5;
}
.AcCurrencyddDiv {
  padding: 0.5rem 1rem;
  width: 100%;
  border-radius: 8px;
  border: 1px solid #00051a;
  background: #fff;
  position: relative;
  cursor: pointer;
}
.fTransferddDiv {
  position: relative;
  border: none !important;
  outline: 0 !important;
  background-color: #f5f5f5 !important;
  font-family: "sf-ui-display";
  font-size: 13px;
  font-weight: 300;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: #00051a;
  padding: 0.75rem !important;
  cursor: pointer;
}
.detailInput {
  display: flex;
  justify-content: space-between;
  border: 0.75px solid #00051a;
  border-radius: 10.5px;
  padding: 1rem 1.5rem;
}
.imgQRScan {
  position: relative;
}
.detailInput p {
  font-family: "sf-ui-display";
  font-size: 14px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  color: #00051a;
  margin-bottom: 0 !important;
}
.detailInput img {
  cursor: pointer;
}
.recievedDetailDiv p {
  font-family: "sf-ui-display";
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: right;
  color: #071eed;
  margin-bottom: 5px;
}
.recievedDetailDiv h5 {
  font-family: "sf-ui-display";
  font-size: 12px;
  font-weight: 500;
  line-height: 14.5px;
  letter-spacing: 0em;
  text-align: right;
  color: #071eed;
}
.recievedDetailDiv h6 {
  font-family: "sf-ui-display";
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
  color: #00051a;
}
.moreDetailsText {
  font-family: "sf-ui-display";
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: center;
  color: #00051a;
  cursor: pointer;
}
.FDInputDiv .inputTitle {
  font-family: "sf-ui-display";
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
  color: #00051a;
}
.SVCNBottomText {
  font-family: "sf-ui-display";
  font-size: 11px;
  font-weight: 300;
  line-height: 13px;
  letter-spacing: 0em;
  text-align: left;
  color: #767676;
}
.billCompanyLogo {
  width: 37px;
  height: 37px;
  border-radius: 50%;
}
.billComapnyTitle {
  font-family: "sf-ui-display";
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
  color: #00051a;
}
.billsRow {
  border-radius: 8px;
  padding: 7px 0;
}
.billsRow:hover {
  cursor: pointer;
  transition: ease-in-out 0.3s;
  background-color: #fafafa;
}
.billTotalDetails {
  background-color: #f5f5f5;
  border-radius: 7.5px;
  display: flex;
  justify-content: space-between;
  padding: 1.5rem;
}
.billTotalDetails h6 {
  font-family: "sf-ui-display";
  font-size: 15px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #00051a;
  margin: 0 !important;
}
.billTotalDetails p {
  font-family: "sf-ui-display";
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: right;
  color: #2645e0;
  margin: 0 !important;
}
.swapTitle {
  font-family: "sf-ui-display";
  font-size: 18px;
  font-weight: 600;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
  color: #00051a;
  margin: 0 !important;
}
.confirmSwapDiv {
  display: flex;
  justify-content: space-between;
  background-color: #f9f9f9;
  border-radius: 16px;
  padding: 1rem 1.5rem;
  position: relative;
}
.confirmSwapDiv p {
  font-family: "sf-ui-display";
  font-size: 18px;
  font-weight: 600;
  line-height: 33px;
  letter-spacing: 0em;
  text-align: right;
  color: #00051a;
  margin: 0 !important;
}
.confirmSwapDiv .logo {
  width: 32px;
  height: 32px;
  border-radius: 50%;
}
.swapBtn {
  width: 72px;
  height: 72px;
  border-radius: 50%;
  position: absolute;
  left: 42.5%;
  top: -0.2rem;
}
.cardRightSide {
  margin-top: 2rem;
  max-width: 325px !important;
}
.reciepentDetails {
  width: 100%;
  padding: 0.75rem 1.25rem;
  border-radius: 8.12px;
  box-shadow: 0px 0px 6.872868061065674px 0px #00000026;
}

.reciepentDetails h3 {
  font-family: "sf-ui-display";
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #000000;
  margin-bottom: 3px;
}

.reciepentDetails p {
  font-family: "sf-ui-display";
  font-size: 11px;
  font-weight: 600;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: left;
  color: #9d9d9d;
  margin-bottom: 1px;
}

.reciepentDetails img {
  width: 26px;
  height: 26px;
  cursor: pointer;
}
.cardSelection {
  width: 100%;
  position: absolute !important;
  top: 1.8rem;
  left: 0;
  box-shadow: 0 0 11px #e6e6e6;
  border: none;
}
.AcCurrencyddDivApply H3{
  font-size: 14px !important;
  line-height: normal !important;
  font-weight: 500 !important;
}
.cardSelection button{
  font-size: 14px !important;
  line-height: normal !important;
  font-weight: 500 !important;
}
.pdfViewBox{
  width: 150px;
  height: auto;
  box-shadow: 3px 3px 3px .5px #e4e4e4;
  outline: 0 !important;
  border: 0 !important;
  border-radius: 7.5px;
  position: relative;
}
.bottomDetailDiv{
  position: absolute;
  bottom: -3%;
  background: #ededed;
  border-radius: 0 0 7.5px 7.5px;
  padding: 5px 7px 5px 7px;
  width: 150px;
  z-index: 1;
}
.blogMainImg{
  height: 260px;
}
.blogSubMainImg{
  height: 204px;
  min-width: 50%;
}


/* BottomModal.css */

/* Styles for the overlay */
.overlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 8;
  background: #00000026;

}

/* Styles for the modal container */
.upperBorder{
  width: 100%;
  height: 8px ;
  background: linear-gradient(117.26deg, #FFE200 2.71%, #2645E0 39.73%, #071EED 76.47%),
  linear-gradient(0deg, #FBFBFB, #FBFBFB);
}
.modal-container {
  display: none;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 50%;
  overflow: hidden;
  z-index: 9;
  transition: 0.5s;
  box-shadow: 0px -3px 24.700000762939453px 0px #00000026;
  border: 8px, 0px, 0px, 0px;
  background-color: #FBFBFB;
  
}

/* Styles for the modal content */
.modal-contents {
  position: absolute;
  left: 0;
  width: 100%;
  /* background-color: #fff; */
  padding: 20px;
  box-sizing: border-box;
  transform: translate(3rem ,1rem);
}
.modal-contents h3, .cookieModalHeading{
  font-family: "sf-ui-display";
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  background-image: linear-gradient(to bottom right, rgba(255, 226, 0, 1), rgba(38, 69, 224, 1), rgba(7, 30, 237, 1));
  -webkit-background-clip: text; /* Apply gradient to text */
  color: transparent; /* Hide the original text */
}
.modal-contents p {
  font-family: "sf-ui-display";
  color: var(--secondary-dark, #00051A);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 596px;
}

.cookieModalPara{
  font-family: "sf-ui-display";
  color: var(--secondary-dark, #00051A);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.cookieModalPara01{
  font-family: "sf-ui-display";
  color: var(--secondary-dark, #071EED);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.cookieModalPara02{
  font-family: "sf-ui-display";
  color: var(--secondary-dark, #00051A);
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.cookieModalPara03{
  font-family: "sf-ui-display";
  color: var(--secondary-dark, #00051A);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.modal-contents .acceptBtn{
  display: flex;
  width: 203px;
  padding: 12px;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 13.5px;
  border: 0.75px solid #000;
  background: var(--secondary-dark, #00051A);
  color: var(--accent-yellow, #FFE200);
  font-family: "sf-ui-display";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.modal-contents .rejectBtn{
  display: flex;
  width: 203px;
  padding: 12px;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 13.5px;
  border: 0.75px solid #000;
   color: var(--secondary-dark, #00051A);
   background: var(--accent-yellow, transparent);
  font-family: "sf-ui-display";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
 .cookieModalAcceptBtn{
  display: flex;
  width: 30%;
  padding: 12px;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 13.5px;
  border: 0.75px solid #000;
  background: var(--secondary-dark, #00051A);
  color: var(--accent-yellow, #FFE200);
  font-family: "sf-ui-display";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
 .cookieModalRejectBtn{
  display: flex;
  width: 50%;
  padding: 12px;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 13.5px;
  border: 0.75px solid #000;
   color: var(--secondary-dark, #00051A);
   background: var(--accent-yellow, transparent);
  font-family: "sf-ui-display";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}


.cookeisModal{
  box-shadow: rgba(0, 0, 0, 0.05) 0px 2.025px 14.175px 0px !important;
  --bs-modal-width: 760px !important;
  flex-shrink: 0;
}



.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 20px;
  cursor: pointer;
}

/* Apply styles to the overlay when the modal is open */
.modal-container.open , .overlay.open {
  display: block;
}
.detailsPageH{
  color: var(--Primary-Blue, #2645E0);
  text-align: center;
  font-family: "sf-ui-display";
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
  line-height: 48px; /* 107.5% */
  letter-spacing: -0.6px;
  text-transform: uppercase;
}
.detailsPageHBlack{
  font-family: "sf-ui-display";
  color: #191C1F;
  font-size: 36px;
  font-style: normal;
  font-weight: 500;
  line-height: 60px; /* 166.667% */
  letter-spacing: -0.6px;
}
.cookiesModal{
  border-radius: 18px !important;
  background: var(--White, #FFF) !important;
  box-shadow: 0px 2.025px 14.175px 0px rgba(0, 0, 0, 0.05) !important;
}
.detailsPageTxt{
  color: #191C1F;
  font-family: "sf-ui-display";
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 20px;
  text-align: justify;
}
.detailsPageTxtb{
  color: #191C1F;
  font-family: "sf-ui-display";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  text-align: justify;
}
.faqsHeading{
  color: #191C1F;
  text-align: center;
  font-family: "sf-ui-display";
  font-size: 36px;
  font-style: normal;
  font-weight: 500;
  line-height: 60px; /* 166.667% */
  letter-spacing: -0.6px;
  text-align: center;
}
.downloadPdfBtn{
  display: inline-flex;
  padding: 14px 22px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 13.5px;
  border: 0.75px solid #000;
  background: var(--secondary-dark, #00051A);
  color: var(--accent-yellow, #FFE200);
  font-family: "sf-ui-display";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.setTransform{
  transform: translate(0px,0px) !important;
}
.modalDesc{
  font-family: "sf-ui-display";
  color: black;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 0;
  text-align: center;
}
.modalInput{
  position: relative;
  display: flex;
  align-items: center;
}
.pencilImg{
  position: absolute;
  right: 10px;
}
.bi-info-circle-fill{
  cursor: pointer;
}
.enableBtn {
  padding: 4px 12px;
  font-family: "sf-ui-display";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border: none;
  color: white;
  cursor: pointer;
  border-radius: 6px;
  background-color: #071EED;
}

.qrImg{
  width: auto;
  height: 130px;
  margin-bottom: 2rem;
}



.new_input_feilds_label{
  color: var(--secondary-dark, #00051A);
  font-family: 'Inter';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 7px;
}


.new_input_feilds{
  display: flex;
  padding: 16px 21px;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 11px;
  border: 1.5px solid #D1D1D1;
  font-family: 'Inter';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;  
  color:#00051A ;
  width: 100%;

}
.new_input_feilds::placeholder{
  color: #D1D1D1;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.new_input_feilds:focus{
  transition: ease-in-out .3s !important;
  box-shadow: 1px 0 0 1px dark;
  border : 2px rgb(116, 116, 116)solid #D1D1D1 !important;
}
.new_input_feilds_00{
  display: flex;
  padding: 16px 11px 16px 43px;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 11px;
  border: 1.5px solid #D1D1D1;
  font-family: 'Inter';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;  
  color:#00051A ;
  width: 100%;

}
.new_input_feilds_00::placeholder{
  color: #D1D1D1;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.new_input_feilds_00:focus{
  transition: ease-in-out .3s !important;
  box-shadow: 1px 0 0 1px dark;
  border : 2px rgb(116, 116, 116)solid #D1D1D1 !important;
}


.new_sign_up_btn{
    position: relative;
    display: inline-block;
    padding: 10px 20px;
    font-size: 16px;
    text-align: center;
    cursor: pointer;
    text-decoration: none;
    color: black;
    background-color: white;
    border-radius: 7.5px;
    z-index: 1;
  }
  
  .new_sign_up_btn::before {
    content: "";
    position: absolute;
    top: -1.5px;
    left: -1.5px;
    right: -1.75px;
    bottom: -1.5px;
    background: linear-gradient(
      to right, 
      rgba(255, 226, 0, 1), 
      rgba(38, 69, 224, 1), 
      rgba(7, 30, 237, 1)
    );
    border-radius: inherit;
    z-index: -1;
  }
  
  .new_sign_up_btn::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: white;
    border-radius: inherit;
    z-index: -1;
  }
  .border_red{
    transition: ease-in-out .3s;
    border: 1.5px solid #DD4C4C;
  }

  .button {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 20px;
    font-size: 16px;
    text-align: center;
    cursor: pointer;
    text-decoration: none;
    background-color: white;
    border-radius: 7.5px;
    z-index: 1;
    color: transparent;
    width: 100%;
    transition: all 0.3s ease-in-out;
    height: 55px;
  }
  
  .button::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
      to right, 
      rgba(255, 226, 0, 1), 
      rgba(38, 69, 224, 1), 
      rgba(7, 30, 237, 1)
    );
    border-radius: inherit;
    z-index: -1;
  }
  
  .button::after {
    content: "";
    position: absolute;
    top: 1.75px;
    left: 1.75px;
    right: 1.75px;
    bottom: 1.75px;
    background: white;
    border-radius: inherit;
    z-index: -1;
  }
  
  .button span {
    display: inline-block;
    background: var(--logo-gradient, linear-gradient(117deg, #FFE200 2.71%, #2645E0 39.73%, #071EED 76.47%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-fill-color: transparent;
    position: relative;
    z-index: 1;
    line-height: 1; /* Ensure line height does not affect vertical centering */
    text-align: center;
    font-family: 'Inter';
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  
  .button:hover {
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
  }
  
  .button:active {
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transform: translateY(1px);
  }
  
  .spinner {
    width: 40px;
    height: 40px;
    border: 4px solid transparent;
    border-top: 4px solid rgba(255, 226, 0, 1);
    border-right: 4px solid rgba(38, 69, 224, 1);
    border-bottom: 4px solid rgba(7, 30, 237, 1);
    border-radius: 50%;
    animation: spin 1s linear infinite;
    margin: auto;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  .hide {
    display: none;
    margin: auto;
  }
  
  .loading {
    width: 50px;
    height: 50px;
    padding: 0;
    border-radius: 50%;
    margin: auto;
    margin-top: 30px;
  }
  .acc_type_h{
    font-family: 'Inter';
    font-size: 24px;
    font-weight: 500;
    line-height: 29.05px;
    text-align: left;
    color: rgba(38, 69, 224, 1);
  }

  .acc_type_h2{
    font-family: 'Inter';
    font-size: 24px;
    font-weight: 300;
    line-height: 29.05px;
    text-align: left;
    color: rgba(0, 5, 26, 1);
  }
  .new_acc_type_card{
    background: rgba(255, 255, 255, 1);
    box-shadow: 0px 0px 10.7px 0px rgba(38, 69, 224, 0.1);
    padding: 1.5rem;
    border-radius: 18px;
  }

  .new_acc_type_card h1{
    font-family: 'Inter';
    font-size: 20px;
    font-weight: 500;
    line-height: 24.2px;
    text-align: left;
    color: rgba(38, 69, 224, 1);
  }

  .new_acc_type_card p{
    font-family: 'Inter';
    font-size: 16px;
    font-weight: 300;
    line-height: 21.12px;
    text-align: left;
    color: rgba(0, 5, 26, 1);
    height: 80px;
  }
  
  
  .newHeadingss {
    background-image: linear-gradient( #FFE200 2.71%, #2645E0 39.73%, #071EED 76.47%) !important;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-fill-color: transparent;
    text-align: center;
    font-family: 'Inter';
    font-size: 26px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .applicationHeading {
    background-image: linear-gradient(117.26deg, #FFE200 2.71%, #2645E0 39.73%, #071EED 76.47%) !important;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-fill-color: transparent;
    text-align: center;
    font-family: 'Inter';
    font-size: 40px;
    font-weight: 500;
    line-height: 48.41px;
    text-align: center;
  }
  .applicationText {
    text-align: center;
    font-family: 'Inter';
    font-size: 24px;
    font-weight: 300;
    line-height: 29.05px;
    text-align: left;
  }
.fixedHeight{
  height: calc(100vh - 90px) !important;
}

.fixedHeight001{
  height: calc(100vh - 137px) !important;
}
.fixedHeight001 {
  overflow: auto;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.fixedHeight001::-webkit-scrollbar {
  display: none;  /* Chrome, Safari, and Opera */
}


.agrreOnTermsNewText{
  font-family: 'Inter';
  font-size: 16px;
  font-weight: 400;
  line-height: 19.36px;
  text-align: left;
  color: rgba(31, 31, 31, 1);
}
.stepsRow{
  width: 16.6% !important;
}
.zIndex{
  z-index: 3;
}
.h100{
  height: 100vh !important;
  overflow-y: scroll;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}