@font-face {
    font-family: 'sf-ui-display';   
    src: url('../fonts/Inter-VariableFont_slnt\,wght.ttf');
    font-style: normal;
  }
.navbarContainer{
    height: 75px;
    background-color: #FFFFFF !important;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    padding: 0 2rem;
}
.btnShow{
  display: none;
}
.navbar_titles {
  font-family: 'sf-ui-display' !important;
  font-size: 16px !important;
  font-weight: 300 !important;
  line-height: 24px !important;
  letter-spacing: 0em !important;
  text-align: left !important;
  color: #00051A !important;
  padding: 0 3rem 0 3rem;
  cursor: pointer;
}
.navbar_titles:hover {
  font-weight: 300 !important;
  color: #071EED !important;
}
.navbar_titles:hover svg{
  fill: #071EED !important;
}
.navbar_titles.active {
  font-weight: 300 !important;
  color: #071EED !important;
}
.navSvg.active{
  fill: #071EED !important;
}
.navbar_titles_rightSide{
    font-family: 'sf-ui-display' !important;
    font-size: 16px !important;
    font-weight: 400 !important;
    line-height: 24px !important;
    letter-spacing: 0em !important;
    text-align: left !important;
    color: #071EED !important;
    padding: 0 1rem 0 0;
}
.navbarLogo{
    width: 190px;
    height: 37px;
    max-width: 100%;
    padding-left: 5rem;

}
.navbarLogo00{
  width: 190px;
  height: 37px;
  max-width: 100%;
  position: relative;
  right: 1%;
}
.custom-dropdown .dropdown-toggle {
    font-weight: normal; /* Adjust the font weight as needed */
}
.custom-dropdown{
  position: relative;
  top: .5rem;
}

.transparent-gradient-border-btn {
    Width : 190px ;
    Height : 44px ;
    position: relative;
    /* display: inline-block; */
    padding: 0;
    font-size: 16px;
    cursor: pointer;
    background: linear-gradient(to bottom right, rgba(255, 226, 0, 1), rgba(38, 69, 224, 1), rgba(7, 30, 237, 1));
    z-index: 1;
    overflow: hidden;
    border-radius: 10px;
  }
  
  .transparent-gradient-border-btn .button-content {
    Width : 190px ;
    Height : 44px ;
    border: 2px solid transparent;
    border-image: linear-gradient(to bottom right, rgba(255, 226, 0, 1), rgba(38, 69, 224, 1), rgba(7, 30, 237, 1));
    border-image-slice: 1;
    border-radius: 10px;
    background-clip: padding-box, border-box;
    transition: all 0.3s ease;
    background-color: #FFFFFF;
  }
  .gradient-text {
    font-size: 22px;
    background-image: linear-gradient(to bottom right, rgba(255, 226, 0, 1), rgba(38, 69, 224, 1), rgba(7, 30, 237, 1));
    -webkit-background-clip: text; /* Apply gradient to text */
    color: transparent; /* Hide the original text */
    font-family: 'sf-ui-display';
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
  }











  .transparent-gradient-border-btn-nav {
    Width : 180px ;
    Height : 44px ;
    position: relative;
    /* display: inline-block; */
    padding: 0;
    font-size: 16px;
    cursor: pointer;
    background: linear-gradient(to bottom right, rgba(255, 226, 0, 1), rgba(38, 69, 224, 1), rgba(7, 30, 237, 1));
    z-index: 1;
    overflow: hidden;
    border-radius: 10px;
  }
  
  .transparent-gradient-border-btn-nav .button-content {
    Width : 180px ;
    Height : 44px ;
    border: 2px solid transparent;
    border-image: linear-gradient(to bottom right, rgba(255, 226, 0, 1), rgba(38, 69, 224, 1), rgba(7, 30, 237, 1));
    border-image-slice: 1;
    border-radius: 10px;
    background-clip: padding-box, border-box;
    transition: all 0.3s ease;
    background-color: #FFFFFF;
  }
  .gradient-text {
    font-size: 24px;
    background-image: linear-gradient(to bottom right, rgba(255, 226, 0, 1), rgba(38, 69, 224, 1), rgba(7, 30, 237, 1));
    -webkit-background-clip: text; /* Apply gradient to text */
    color: transparent; /* Hide the original text */
    font-family: 'sf-ui-display';
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
  }

  
  .button-content  .gradient-text::before {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: 'CHANGE';
    transition: all .3s;
  }
  .button-content  .gradient-text::after {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 60px;
    left: 0;
    width: 100%;
    height: 100%;
    content: 'TEXT';
    transition: all .3s;
  }
  .button-content :hover::before {
    top: -60px;
  }
  .button-content :hover::after {
    top: 0;
  }
.navBarCenterdLinks{
  margin-left: auto !important;
}
.navbarContainer{
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 5;
}
.modal-backdrop {
  position: unset !important;
}



.navbar-toggler{
  border: none;
  position: absolute;
  left: 1rem;
}
.navbar-toggler:focus{
  border: none;
  box-shadow:none !important;
}
.navMb{
  display: none;
}

.navBarDropDowm{
  outline: none !important;
  border:1px solid #e0e0e0 !important;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  position: absolute;
  top: 80%;
  left: 5%;
}

.navBarDropDowm a{
  font-family: 'sf-ui-display' !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 24px !important;
  letter-spacing: 0em !important;
  text-align: left !important;
  color: #00051A !important;
}
.navBarDropDowm a:hover{
  transition: ease-in-out .3s;
  background-color: #00051A !important;
  color: #FFFFFF !important;
  border-radius: 13.5px;
  /* padding: .3rem; */
}
