@font-face {
    font-family: 'sf-ui-display';
    src: url('../fonts/Inter-VariableFont_slnt\,wght.ttf');
    font-style: normal;
  }

.sidebar {
    width: 263px;
    height: 100vh;
    /* position: fixed; */
    box-shadow: 0px 3px 32.25px 0px #00000040;
    overflow: hidden;
}

.sideBarInnerContentDiv{
    background-image: url('../../../public/images/Component 9 (2).svg');
    background-position:bottom;
    background-repeat:no-repeat;
    background-size:cover;
    height: 100vh;
}

.user-section {
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    /* gap: 15px; */
    padding-top: 2rem;
}

.user-image {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    margin-right: 12px;
}

.user-name {
    font-family: 'sf-ui-display' !important;
    font-size: 15px !important;
    font-weight: 500 !important;
    line-height: 18px !important;
    letter-spacing: 0em !important;
    text-align: left !important;
    color: #00051A !important;
    cursor: pointer;
}

.button-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    height: 62vh;
    margin-top: 1.5rem;
    border-bottom: 1px solid #D9D9D980 !important;
    margin: 0 .5rem;
    padding: .75rem 0 ;
}
.sidebarBtn{
    display: flex;
    align-items: center;
    max-width: 180px;
    width: 105%;
    border-radius: 7.5px;
    background-color: transparent;
    color: #2645E0;
    padding: .5rem .5rem .4rem .8rem;
    outline: none !important;
    border: none;
    text-align: left;
    display: flex;
    /* margin-bottom: .75rem; */
}
.sidebarBtn svg{
    max-width: 15px;
    width: 100%;
    /* height: 12.5px; */
}
.sidebarBtns{
    display: flex;
    align-items: center;
    max-width: 180px;
    width: 105%;
    border-radius: 7.5px;
    background-color: transparent;
    color: #2645E0;
    padding: .5rem .5rem .4rem .8rem;
    outline: none !important;
    border: none;
    text-align: left;
    display: flex;
    /* margin-bottom: .75rem; */
}
.sidebarBtns svg{
    max-width: 15px;
    width: 100%;
    /* height: 12.5px; */
}
.sidebarBtn span{
    font-family: 'sf-ui-display' !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 14px !important;
    letter-spacing: 0em !important;
    padding-left: .5rem;
    color: #000000;
}
img.arrow {
    /* Ensures the arrow is its original color when not hovered */
    filter: none;
    transition: filter 0.3s ease; /* Smooth transition for the filter effect */
}

img.arrow:hover {
    /* Inverts the colors, turning black to white */
    filter: invert(100%);
}
.sidebarBtn.active{
    background-color: #2645E0;
}
.sidebarBtn.active span{
    color: #FFFFFF;
}
.sidebarBtn.active svg{
    fill: #FFFFFF;
}

.sidebarBtns span{
    font-family: 'sf-ui-display' !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 14px !important;
    letter-spacing: 0em !important;
    padding-left: .5rem;
    color: #000000;
}
.sidebarBtns.active{
    background-color: #2645E0;
}
.sidebarBtns.active span{
    color: #FFFFFF;
}
.sidebarBtns.active svg{
    fill: none;
}

.sidebarBtns.active svg path{
    stroke: white;
}
.sidebarBtns:hover svg path{
    stroke: white;
}
.sidebarBtns:hover svg g{
    fill: white;
}
.sidebarBtns.active svg g{
    fill: white;
}
.sidebarBtns:hover svg{
    fill: none !important;
    transition: ease-out .3s;
}
.sidebarBtns:hover {
    background-color: #2645E0;
    transition: ease-out .3s;
}

.sidebarBtns:hover span{
    color: #FFFFFF;
    transition: ease-out .3s;
}


.sidebarBtn:hover {
    background-color: #2645E0;
    transition: ease-out .3s;
}
.sidebarBtn:hover svg{
    fill: #FFFFFF !important;
    transition: ease-out .3s;
}
.sidebarBtn:hover span{
    color: #FFFFFF;
    transition: ease-out .3s;
}

.sidebarBtn:hover .svg{
    fill: none !important;
}
.sidebarBtn:hover .svg path{
    transition: ease-in-out .3s;
    stroke: white !important;
}

.sidebarBtn.active .svg{
    fill: none !important;
}
.sidebarBtn.active .svg path{
    transition: ease-in-out .3s;
    stroke: white !important;
}
.logo-section {
    text-align: center;
}

.sideBar-logo-image{
    width: 99px;
    /* height: 31px; */
    /* margin-bottom: 2rem; */
}


.sidebar-transparent-gradient-border-btn {
    Width : 78px ;
    Height : 18px ;
    position: relative;
    /* display: inline-block; */
    padding: 0;
    font-size: 9px;
    cursor: pointer;
    background: linear-gradient(to bottom right, rgba(255, 226, 0, 1), rgba(38, 69, 224, 1), rgba(7, 30, 237, 1));
    z-index: 1;
    overflow: hidden;
    border-radius: 6px;
  }
  
  .sidebar-transparent-gradient-border-btn .button-content {
    Width : 78px ;
    Height : 18px ;
    border: 1px solid transparent;
    border-image: linear-gradient(to bottom right, rgba(255, 226, 0, 1), rgba(38, 69, 224, 1), rgba(7, 30, 237, 1));
    border-image-slice: 1;
    border-radius: 6px;
    background-clip: padding-box, border-box;
    transition: all 0.3s ease;
    background-color: #FFFFFF;
  }
  .sidebar-gradient-text {
    font-size: 9px;
    background-image: linear-gradient(to bottom right, rgba(255, 226, 0, 1), rgba(38, 69, 224, 1), rgba(7, 30, 237, 1));
    -webkit-background-clip: text; /* Apply gradient to text */
    color: transparent; /* Hide the original text */
    font-family: 'sf-ui-display';
    font-size: 9px;
    font-weight: 400;
    line-height: 13px;
    letter-spacing: 0em;
    text-align: center;
  }

  .search-container {
    position: relative;
    margin: 1.5rem;
}

.search-input {
    padding: .5rem 1.25rem .5rem .5rem;
    font-family: 'sf-ui-display';
    font-size: 12px;
    font-weight: 500;
    line-height: 14px;
    letter-spacing: 0em;
    background-color: transparent;
    color: #C0C0C0 !important;
    border: none;
}
.search-input::placeholder {
    padding-right: 20px; /* To make sure text doesn't overlap the icon */
    font-family: 'sf-ui-display';
    font-size: 12px;
    font-weight: 500;
    line-height: 13px;
    letter-spacing: 0em;
    background-color: transparent;
    color: #0000 !important;
}
.search-input:focus {
    outline: 0 !important;
}
.search-input:focus {
   color: #C0C0C0 !important;
   background-color: transparent !important;
    border-color: #C0C0C0 !important;
    outline: 0 !important;
    box-shadow: none !important;
}

.search-inputBills {
    padding: 1rem 1.75rem 1rem 1rem;
    font-family: 'sf-ui-display';
    font-size: 12px;
    font-weight: 500;
    line-height: 14px;
    letter-spacing: 0em;
    background-color: #F2F9FF;
    color: #D9D9D9 !important;
    border: none;
    border-radius: 11.25px !important;
}
.search-inputBills::placeholder {
    padding-right: 20px; /* To make sure text doesn't overlap the icon */
    font-family: 'sf-ui-display';
    font-size: 12px;
    font-weight: 500;
    line-height: 14px;
    letter-spacing: 0em;
    background-color: #F2F9FF;
    color: #D9D9D9 !important;
}
.search-inputBills:focus {
    outline: #C0C0C0;
}
.search-inputBills:focus {
   color: #C0C0C0 !important;
   background-color: #F2F9FF;
    border-color: #C0C0C0;
    outline: 0;
    box-shadow: 0 0 0 0.05rem #C0C0C0;
}
.search-icon {
    width: 13.5px;
    height: 13.5px;
    position: absolute;
    right: 9px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none; /* Makes sure the icon doesn't interfere with the input's functionality */
}

.payementsDD{
    padding: .25rem;
}
.payementsDD a{
    font-family: 'sf-ui-display';
    font-size: 12.7px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: center;
    text-decoration: none;
    color: #000000;
}
.payementsDD a.active{
    color: #2645E0;
}
.payementsDD a:hover{
    color: #2645E0;
}
.ddIcon{
    visibility: hidden;
}
.ddIcon.active{
    visibility: unset;
}
.payementsDD a:hover .ddIcon{
    visibility: unset;
}

/* Hide the scrollbar but allow scrolling */
.button-section {
    overflow-y: auto;
    scrollbar-width: none; /* For Firefox */
    -ms-overflow-style: none;  /* For Internet Explorer and Edge */
  }
  
  /* For WebKit-based browsers */
  .button-section::-webkit-scrollbar {
    display: none; /* Hide scrollbar */
  }
  
  /* Custom scrollbar (for WebKit-based browsers) */
  .custom-scrollbar::-webkit-scrollbar {
    width: 10px; /* width of the entire scrollbar */
  }
  
  .custom-scrollbar::-webkit-scrollbar-track {
    background: #f1f1f1; /* color of the tracking area */
  }
  
  .custom-scrollbar::-webkit-scrollbar-thumb {
    background-color: darkgrey; /* color of the scroll thumb */
    border-radius: 10px; /* roundness of the scroll thumb */
    border: 3px solid orange; /* creates padding around scroll thumb */
  }