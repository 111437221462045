@font-face {
  font-family: "sf-ui-display";
  src: url("../fonts/Inter-VariableFont_slnt\,wght.ttf");
  font-style: normal;
}

.ftrLogo {
  width: 58px;
  height: 58px;
  max-width: 100%;
  margin-top: 5rem;
  margin-bottom: 4rem;
}
.ftrTitle {
  font-family: "sf-ui-display";
  font-size: 18px;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 29px;
  color: rgba(0, 0, 0, 1);
}
.ftrSubTitle {
  font-family: "sf-ui-display";
  font-size: 15px;
  font-weight: 300;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(0, 0, 0, 1);
  margin-bottom: 29px;
  text-decoration: none;
}
.ftrSubTitle a {
  text-decoration: none;
  color: rgba(0, 0, 0, 1);
}
.ftrSubTitle.active {
  font-family: "sf-ui-display";
  font-size: 18px;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(38, 69, 224, 1);
  margin-bottom: 1rem;
}
.FotterBg {
  background-image: url("../../../public/images/Footer.svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: unset;
  /* // height: '1060px', // You can adjust the value as needed */
  padding-left: 7.25rem;
  padding-right: 7.25rem;
}
/* .firstStephr{
    padding: 3.5rem 0;
} */

.secondStephr {
  padding: 3.5rem 0 2rem 0;
}

.copyRightText {
  font-family: "sf-ui-display";
  font-size: 18px;
  font-weight: 300;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(0, 5, 26, 1);
}
.socialIcons img {
  padding-right: 4rem;
  width: auto;
  height: 20px;
  cursor: pointer;
}
.playLogos {
  width: 100%;
  max-width: 155.25px;
  height: 51.81px;
}
.footerTxtDiv {
  width: 100%;
  max-width: 320px;
  display: flex;
  flex-direction: column;
  gap: 25px;
}
.footerTxtDiv h4 {
  color: #000;
  font-family: "sf-ui-display";
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 0;
}
.footerTxtDiv p {
  color: #000;
  font-family: "sf-ui-display";
  font-size: 15px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  margin-bottom: 0;
}
.ftrLinkHide {
  display: flex;
  justify-content: space-between;
  /* flex-wrap: wrap; */
}
.ftrMbIcon {
  align-items: center;
  justify-content: end !important;
  gap: 15px;
}
.bottomIcon {
  display: flex;
  gap: 5px;
  align-items: center;
}
.bottomIcon img {
  width: 14px;
  height: 14px;
  border-radius: 50%;
}
.bottomIcon p {
  color: #000;
  font-family: "sf-ui-display";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  letter-spacing: 0.24px;
  margin-bottom: 0;
}
.bottomFooterDiv {
  display: flex;
  gap: 30px;
}
.bottomIcon p:hover {
  color: #071eed !important;
}
.bottomTxt p {
  margin-top: 42px;
  padding-bottom: 28px;
  color: #606060;
  font-family: "sf-ui-display";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  letter-spacing: 0.24px;
  margin-bottom: 0 !important;
}

.bottomTxt01 p {
  margin-top: 0;
}
.bottomTxt a {
  text-decoration: none;
  color: #606060;
}

.bottomMoneyDiv{
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
  margin-top: 19px;
  
}
.bottomMoneyDiv a{
  cursor: pointer;
}
.bottomMoneyDiv h6 {
  margin-bottom: 0;
  color: #00051a;
  font-family: "sf-ui-display";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.24px;
  text-decoration-line: underline;
}
.bottomMoneyDiv a {
  margin-bottom: 0;
  color: #00051a;
  font-family: "sf-ui-display";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.24px;
  letter-spacing: 0.24px !important;
  text-decoration-line: underline !important;
}
.bottomMoneyDiv a:hover {
  color: #071eed !important;
}
.bottomMoneyDiv span {
  margin-bottom: 0;
  color: #00051a;
  font-family: "sf-ui-display";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.24px;
  
}
.footerMain {
  padding-bottom: 60px;
  padding-top: 30px;
}
.accordionButton{
  background: none !important;
}
.accordionButton:focus{
  box-shadow: none !important;
}
.accordionItem{
  background: none !important;

}
.ftrTitle01{
  margin-bottom: 0 !important;
}
.showFooter{
  display: none;
}